.first-page {
  padding: 0 1.5rem;
  padding-top: 5.1rem;
  > .header {
    position: relative;
    height: 24.3rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/背景@3x.png');
    background-size: 100% 100%;

    > .nickname {
      font-size: 1.2rem;
      color: #fff;
      position: absolute;
      left: 0;
      right: 0;
      text-align: center;
      bottom: 1rem;
    }

    &::after {
      position: absolute;
      content: '';
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/doge@2x.png');
      height: 14rem;
      width: 14rem;
      background-size: 100% 100%;
      bottom: 14.2rem;
      left: calc(50% - 7rem);
    }

    .other {
      position: absolute;
      height: 4rem;
      width: 1rem;
      border-radius: 0.5rem;
      background-color: #8792ff;
      border: 1px solid #000;
      bottom: -1.9rem;
      z-index: 999;

      &:first-child::after,
      &:last-child::after {
        content: '';
        position: absolute;
        height: 0.5rem;
        width: 0.5rem;
        border: 1px solid #000;
        border-radius: 50%;
        background-color: #fff;
        left: 50%;
        transform: translateX(-50%);
      }

      &:first-child {
        left: 2rem;
        &::after {
          bottom: 0.1rem;
        }
      }

      &:last-child {
        right: 2rem;
        &::after {
          top: 0.1rem;
        }
      }
    }
  }

  > .body {
    position: relative;
    margin-top: 0.45rem;
    border: 2px solid #000;
    border-radius: 0.5rem;
    min-height: 10rem;
    background-color: #c66dff;
    padding-bottom: 4.2rem;

    > .title {
      position: relative;
      font-size: 1.7rem;
      font-weight: bold;
      text-align: center;
      z-index: 99;
      margin-top: 4rem;
      &::before {
        position: absolute;
        content: '请选择你的属性';
        z-index: 999;
      }
      &::after {
        position: absolute;
        content: '';
        height: 0.6rem;
        width: 13rem;
        bottom: 0;
        left: calc(50% - 6.5rem);
        background-color: #ffea3e;
      }
    }

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      background-color: #fff;
      border-radius: 0.5rem;
      border: 2px solid #000;
      top: 0.75rem;
      left: -2px;
      bottom: -2px;
    }

    > .select-area {
      position: relative;
      padding: 0 3.75rem;
      padding-top: 2.6rem;
      padding-bottom: 3.25rem;
      display: flex;
      justify-content: space-between;
      position: relative;
      z-index: 999;

      &::after {
        position: absolute;
        content: '';
        width: 25rem;
        border-bottom: 2px dotted #ebe5f9;
        bottom: 0;
        left: calc(50% - 12.5rem);
      }

      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        > img {
          height: 10rem;
          width: 12.5rem;
        }

        > .checkbox {
          display: flex;
          align-items: center;
          font-size: 1.4rem;
          font-weight: bold;

          &.select {
            > .icon {
              background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/选择@2x.png');
            }
          }

          > .icon {
            height: 2.2rem;
            width: 2.2rem;
            margin-right: 0.7rem;
            background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/未选择@2x.png');
            background-size: 100% 100%;
          }
        }
      }
    }

    > .next-btn {
      box-sizing: content-box;
      position: relative;
      z-index: 999;
      height: 4rem;
      line-height: 4rem;
      width: 25rem;
      margin: 0 auto;
      text-align: center;
      border-radius: 0.7rem;
      background-color: #ffea3e;
      border: 2px solid #000;
      font-size: 1.6rem;
      font-weight: bold;
      margin-top: 2.2rem;
      border-bottom-width: 0.4rem;

      &::after,
      &::before {
        content: '';
        position: absolute;
        height: 0.9rem;
        width: 3.7rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/圆角矩形\ 4\ 拷贝\ 6@2x.png');
        background-size: 100% 100%;
        top: calc(50% - 0.45rem);
      }

      &::after {
        left: 4.4rem;
      }

      &::before {
        right: 4.4rem;
      }
    }
  }
}
