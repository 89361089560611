



















































.the-sign-tab-panel {
  padding: 2rem 1.2rem;
  padding-bottom: 3rem;
  background-color: rgba(109, 8, 6, 0.5);
  border-radius: 1rem;
  margin: 0 1rem;
  margin-bottom: 1rem;
  > ul {
    display: flex;
    flex-wrap: wrap;
    > li {
      padding: 0.9rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      position: relative;
      height: 8rem;
      width: 7.75rem;
      background-color: #ffebd9;
      border-radius: 0.5rem;
      margin-right: 0.7rem;
      margin-bottom: 0.7rem;
      &:nth-child(4n) {
        margin: 0;
      }

      &.active {
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          content: '';
          background-color: rgba(109, 8, 6, 0.5);
        }

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 99;
          height: 1.5rem;
          width: 1.5rem;
          background-image: url('../../../../../assets/activity/spring-festival/checked.png');
          background-repeat: no-repeat;
          background-size: 100% 100%;
        }
      }

      > .title {
        margin: 0;
        text-align: center;
        font-size: 1.1rem;
      }

      > .tag {
        position: absolute;
        left: 0;
        top: 0;
        color: #ffe8c9;
        font-optical-sizing: 0.9rem;
        background-color: #fc6327;
        display: inline-block;
        height: 1.4rem;
        line-height: 1.4rem;
        width: 2.5rem;
        text-align: center;
        border-radius: 0.5rem 0 0.5rem;
      }

      > .img {
        // position: absolute;
        width: 4.55rem;
        height: 4.55rem;
      }
    }
  }

  > .sign-btn {
    color: #ffe8c9;
    font-size: 1.9rem;
    font-weight: bold;
    text-align: center;
    height: 5.7rem;
    line-height: 5.7rem;
    width: 25rem;
    margin: 0 auto;
    margin-top: 3.5rem;
    background-image: url('../../../../../assets/activity/spring-festival/sign-btn.png');
    background-size: 100% 100%;

    &.disabled {
      background-image: url('../../../../../assets/activity/spring-festival/btn-disbaled.png');
      color: rgba(248, 186, 123, 0.5);
    }
  }
}
