























.the-header {
  padding: 3.5rem 1.5rem;
  background-color: #FAFAFA;
  display: flex;
  align-items: center;
  >.avatar {
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    margin-right: 2rem;
  }

  >.nickname {
    font-size: 1.5rem;
    font-weight: bold;
  }
}
