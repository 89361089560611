





































































.container {
  .body {
    padding: 0 1.5rem;
    display: flex;
    flex-wrap: wrap;
  }

  .change-btn {
    font-size: 1.3rem;
    color: #1a1a1a;
  }
}
