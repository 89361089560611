













































































































.cancel-account-page {
  padding: 0 1.5rem;
  padding-top: 3.5rem;
  > .title {
    font-size: 1.7rem;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  > .row {
    font-size: 1.4rem;
    display: flex;
    margin-bottom: 1rem;

    > span:first-child {
      margin-right: 0.2rem;
    }
  }

  > .btn-group {
    display: flex;
    justify-content: space-between;
    font-size: 1.7rem;
    font-weight: bold;
    padding: 0 2.5rem;
    position: fixed;
    bottom: 3.2rem;
    left: 0;
    right: 0;
    .cancel,
    .confirm {
      height: 4.5rem;
      line-height: 4.5rem;
      text-align: center;
      border-radius: 3.03rem;
    }
    .cancel {
      border: 0.25rem solid #f3f3f3;
      background-color: #fafafa;
      width: 11rem;
      line-height: 4rem;
    }

    .confirm {
      background-color: #ff2424;
      color: #fff;
      width: 20rem;
      &:active {
        background-color: rgba(255, 36, 36, 0.8);
      }
    }
  }

  .modal-body {
    text-align: center;
    padding: 1.4rem 0;
    > .title {
      font-size: 1.4rem;
      color: #999;
      margin-bottom: 1rem;
      text-align: center;
    }

    .input {
      border: 1px solid #ddd;
      border-radius: 2px;
    }
  }
}
