





































































.the-privilege {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
  > .box {
    flex: 50%;
    display: flex;
    margin-bottom: 2.6rem;
    &:last-child {
      margin-bottom: 0;
    }
    // &:nth-child(1),
    // &:nth-child(2) {
    //   margin-bottom: 2.6rem;
    // }
    > img {
      height: 5.5rem;
      width: 5.5rem;
      margin-right: 0.5rem;
    }

    .title {
      color: #fefffe;
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    .sub-title {
      color: rgba(254, 255, 254, 0.3);
      font-size: 1.2rem;
    }
  }
}
