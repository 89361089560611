

















































.rank-desc-container {
  padding: 1.5rem;
  padding-top: 3rem;
  .section {
    margin-bottom: 2.5rem;
    > .title {
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }

    > .body {
      font-size: 1.5rem;
      > div {
        position: relative;
        padding-left: 2.5rem;
        margin-bottom: 1.5rem;
        &::before {
          content: '';
          position: absolute;
          left: 0.5rem;
          top: 0.5rem;
          height: 0.5rem;
          width: 0.5rem;
          background-color: black;
          border-radius: 50%;
        }
      }
    }
  }
}
