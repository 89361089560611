









































.the-tab-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  color: #00563c;
  font-weight: bold;
  padding: 0 1.45rem;
  margin-bottom: 1.5rem;

  > .item {
    flex: 1;
    height: 2.9rem;
    line-height: 2.9rem;
    text-align: center;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      height: 1rem;
      width: 2px;
      background-color: #00563c;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:last-child {
      text-align: right;
      &::after {
        display: none;
      }
    }

    &:first-child {
      text-align: left;
    }

    > span {
      display: inline-block;
      text-align: center;
      width: 8.85rem;
    }

    &.active {
      > span {
        background: linear-gradient(90deg, #fceb43, #fdca24);
        box-shadow: 0px 2px 2px 0px #52ca79;
        border-radius: 1.43rem;
      }
    }
  }
}
