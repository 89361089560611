

























































































































































.the-daily-task-panel {
  /deep/ {
    .task-list-section {
      padding-top: 1rem;
      margin-bottom: 1rem;
    }
  }

  .receive-reward-section {
    padding-top: 1.95rem;
    padding-bottom: 0.6rem;

    .luck-num {
      padding-top: 1rem;
      color: #ffe8c9;
      font-size: 1.6rem;
      text-align: center;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .sub-title {
      color: #ffe8c9;
      font-size: 1.4rem;
      text-align: center;
    }
  }

  .task-rule {
    color: #ffe8c9;
    margin-bottom: 3rem;
    padding-left: 1rem;
    .title {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }
  }
}
