.theArticleList {
  margin-top: 2rem;
  > .title {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  > .body {
    padding-bottom: 9rem;
    > .theArticleListItem {
      margin-bottom: 2rem;
      display: flex;
      > .avatar {
        height: 8rem;
        width: 8rem;
        border-radius: 0.5rem;
        margin-right: 1.5rem;
      }

      > .content {
        > .title {
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 2rem;
        }

        > .subtitle {
          display: flex;
          align-items: center;
          justify-content: space-between;
          color: rgba(125, 125, 125, 1);

          > .count {
            > span:first-child {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }
}
