





















.gift-record-container {
  > .tabs {
    padding-top: 2.2rem;
    font-size: 1.5rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    height: 4.7rem;
    background-color: #fafafa;

    > li:first-child {
      margin-right: 7.5rem;
    }

    > .active {
      position: relative;
      &::before {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        content: '';
        height: 0.3rem;
        width: 2rem;
        background-color: #ffd415;
      }
    }
  }
}
