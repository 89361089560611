.page {
  position: relative;
  .tabBar {
    position: absolute;
    z-index: 999;
    padding: 2.5rem;
    display: flex;
    align-items: center;
    font-size: 1.5rem;

    > li {
      margin-right: 2rem;
    }

    > li.active {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }
}
