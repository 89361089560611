









































































.work-list-item {
  position: relative;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.4rem;
  font-weight: bold;
  border-top: 1px solid rgba(0, 86, 60, 0.2);
  padding: 1.5rem 0.5rem;
  > .left {
    display: flex;
    align-items: center;
    > .icon {
      height: 2.7rem;
      width: 2.7rem;
      margin-right: 1rem;
      border-radius: 0.5rem;
    }

    > .name {
      width: 15rem;
    }
  }

  > .right {
    display: flex;
    align-items: center;

    > .icon {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.4rem;
      background-size: 100% 100%;
      &.correct {
        background-image: url('../../../../assets/activity/spring-voice/通过@3x.png');
      }

      &.mistake {
        background-image: url('../../../../assets/activity/spring-voice/未通过@3x.png');
      }
    }

    > .text {
      margin-right: 1rem;
    }

    .play-btn {
      background: linear-gradient(90deg, #ff9e33, #fc7000);
      height: 2.6rem;
      width: 5rem;
      font-size: 1.3rem;
      color: #fcffe2;
      text-align: center;
      line-height: 2.7rem;
      border-radius: 1.3rem;
    }
  }
}
