








































.nameplate-modal {
  padding: 2.5em 1.5rem;
  .header {
    font-size: 1.8em;
    font-weight: bold;
    text-align: center;
  }

  table {
    border: 1px solid #e8e7e7;
    border-bottom: none;
    margin-top: 2em;
    > thead {
      th {
        font-size: 1.4em;
        font-weight: 400;
        border-right: 1px solid #e8e7e7;
        border-bottom: 1px solid #e8e7e7;
        padding: 0.6em 0;
        &:last-child {
          border-right: none;
        }

        &.level {
          width: 7.8em;
        }

        &.icon {
          width: 19.15em;
        }
      }
    }

    > tbody {
      > tr {
        > td {
          text-align: center;
          border-right: 1px solid #e8e7e7;
          border-bottom: 1px solid #e8e7e7;
          padding: 0.6em 0;
          &:last-child {
            border-right: none;
          }
          &.level {
            font-size: 1.5em;
          }

          &.icon {
            > img {
              height: 2.2em;
              width: 8em;
              vertical-align: middle;
            }
          }
        }
      }
    }
  }
}
