.list-item {
  background-color: #fff;

  margin-bottom: 0.5rem;
  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    font-weight: bold;
    border-bottom: 1px solid #f3f3f3;
    padding: 1.5rem 0;
    margin: 0 1.5rem;

    > .title {
      display: flex;
      align-items: center;
      &.new::before {
        content: "";
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.7rem;
        background-size: 100% 100%;
        background-image: url("https://static.aipiaxi.com/app/web/images/article-list/%E5%89%A7%E6%9C%AC%E6%96%B0-%E9%80%89%E6%8B%A9@2x.png");
      }
    }

    > .icon {
      height: 1rem;
      width: 1.3rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/article-list/编辑@3x.png');
      background-size: 100% 0.3rem;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  > .reason {
    background-color: #fafafa;
    font-size: 1.3rem;
    color: #ff7b52;
    min-height: 0;
    max-height: 0;
    transition: max-height 0.5s;
    overflow: hidden;
    &.open {
      max-height: 500px;
    }

    > .content {
      padding: 1.5rem;

      > .date {
        color: #c0bdbd;
        font-size: 1.2rem;
        margin-top: 0.5rem;
      }
    }
  }

  > .body {
    display: flex;
    justify-content: space-between;
    padding: 1.5rem;
    align-items: flex-end;
    > .left {
      display: flex;
      align-items: center;
      > img {
        height: 6.5rem;
        width: 6.5rem;
        border-radius: 0.3rem;
        margin-right: 1.5rem;
      }
      > .info {
        font-size: 1.1rem;
        color: #7d7d7d;
      }
    }

    > .right {
      > .right-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: #c0bdbd;
        font-size: 1.2rem;
        margin-bottom: 1rem;
        > .icon {
          background-size: 100% 100%;
          height: 0.7rem;
          width: 0.7rem;
          background-image: url('/venus-ssr/img/article-list/向下@3x.png');
          margin-left: 0.5rem;

          &.open {
            background-image: url('/venus-ssr/img/article-list/向上@3x.png');
          }
        }
      }

      > .btn-group {
        display: flex;
        align-items: center;
        > .btn {
          height: 2.5rem;
          line-height: 2.5rem;
          width: 5rem;
          border-radius: 0.3rem;
          font-size: 1.2rem;
          text-align: center;
          background-color: #f3f3f3;
          margin-left: 0.5rem;
        }
      }
    }
  }
}
