





















.section {
  margin-top: 3.5rem;
  >.header {
    padding: 0 1.5rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title {
    font-size: 1.8rem;
    font-weight: bold;
  }
}
