



















































































































.the-tool-bar-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  > .role-field {
    padding-right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: -4.5rem;
    width: 100%;
    left: 0;
    height: 4.5rem;
    font-size: 1.3rem;
    background-color: #f3f3f3;

    > .setting-icon {
      height: 3rem;
      width: 3rem;
    }

    > .role-list {
      display: flex;
      flex-wrap: nowrap;
      flex: 1;
      overflow: scroll;
      padding-left: 1.5rem;
      margin-right: 1rem;
      &::-webkit-scrollbar {
        display: none;
      }
      > li {
        word-break: keep-all;
        background-color: #fff;
        height: 3rem;
        line-height: 3.2rem;
        border-radius: 1.5rem;
        padding: 0 1rem;
        margin-right: 1rem;
      }
    }
  }
}

.the-tool-bar {
  background-color: #fff;
  display: flex;
  align-items: center;

  height: 4.8rem;
  box-shadow: 0px -2px 10px 0px rgba(0, 0, 0, 0.05);
  padding-left: 2.5rem;

  > .item {
    position: relative;
    margin-right: 3.5rem;

    &.active.has-popover {
      &::before {
        content: '';
        position: absolute;
        top: -2.35rem;
        left: 50%;
        transform: translateX(-50%);
        border-top: 0.6rem solid #1a1a1a;
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
      }

      > .popover {
        display: flex;
      }

      > .bgm-popover {
        display: block;
      }
    }

    > .role-active-icon {
      border-radius: 0.3rem;
    }

    > .bgm-popover {
      display: none;
      position: absolute;
      background-color: #1a1a1a;
      font-size: 1.3rem;
      color: #fff;
      bottom: 4.9rem;
      width: 15rem;
      transform: translateX(-35%);
      border-radius: 0.3rem;
      padding: 0 1rem;

      > li {
        padding: 1rem 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);

        > .add-btn {
          height: 1.4rem;
          width: 1.4rem;
          vertical-align: middle;
        }

        &:last-child {
          border-bottom: none;
        }
      }
    }

    > .popover {
      padding: 0 1.5rem;
      display: none;
      align-items: center;
      background-color: #1a1a1a;
      height: 4rem;
      border-radius: 0.3rem;
      position: absolute;
      top: -6.35rem;
      transform: translateX(-35%);
      > img {
        height: 1.8rem;
        width: 1.8rem;
        margin-right: 2rem;
      }
    }
    > img {
      position: relative;
      height: 2.3rem;
      width: 2.3rem;
    }
  }
}
