






























































.rank-item {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;
  > .left {
    margin-right: 1.5rem;
    font-size: 0;
    > .avatar {
      height: 4.8rem;
      width: 4.8rem;
      border-radius: 50%;
    }
  }
  > .right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    flex: 1;
    &::after {
      position: absolute;
      bottom: -2.15rem;
      content: '';
      width: 100%;
      height: 1px;
      background-color: rgba(243, 243, 243, 0.9);
    }
    .title {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .sub-title {
      color: #7d7d7d;
      font-size: 1.2rem;
    }

    .btn {
      font-size: 1.1rem;
      background-color: #f3f3f3;
      border-radius: 1.3rem;
      height: 2.6rem;
      width: 7rem;
      line-height: 2.6rem;
      text-align: center;
    }
  }
}
