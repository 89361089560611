
















































































.container {
  padding: 2.5rem 1.5rem;

  .upload-title {
    color: #7D7D7D;
    font-size: 1.5rem;
    margin: 0;
    margin-bottom: 2.5rem;
  }

  .icon {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    &::after {
      content: '';
      position: absolute;
      height: 2px;
      width: 3rem;
      background-color: #D8D8D8;
    }

    &::before {
      content: '';
      position: absolute;
      height: 3rem;
      width: 2px;
      background-color: #D8D8D8;
    }
  }

  /deep/ {
    .van-uploader__preview-image {
      height: 8rem;
      width: 8rem;
      border-radius: 0.5rem;
    }
    .van-uploader__input-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 8rem;
      width: 8rem;
      border-radius: .5rem;
      border: 1px solid #E8E7E7;
    }
  }
}
.btn {
  background-color: #FFD415;
  font-size: 1.7rem;
  font-weight: bold;
  height: 4.5rem;
  border-radius: 3.03rem;
  text-align: center;
  line-height: 4.5rem;
  margin-top: 6rem;
}
textarea {
  padding: 1.5rem;
  height: 15rem;
  width: 100%;
  border: 1px solid #E8E7E7;
  border-radius: 0.5rem;
  resize: none;
  font-size: 1.5rem;
  margin-bottom: 5rem;

  &::placeholder {
    color: #C0BDBD;
  }
}

.input-wrapper {
  margin-top: 5rem;
  font-size: 1.5rem;
  border-bottom: 1px solid #E8E7E7;
  padding-bottom: 1.5rem;
  padding-left: 1rem;

  input {
    border: none;
    width: 100%;

    &::placeholder {
      color: #C0BDBD;
    }
  }
}
