





















.the-content {
  display: flex;
  flex-wrap: wrap;
  padding: 0 2rem;
  padding-top: 2rem;
  padding-bottom: 2.5rem;

  > .item {
    width: 6rem;
    text-align: center;
    margin-right: 1.5rem;
    margin-bottom: 1.45rem;
    &:nth-child(4n) {
      margin-right: 0;
    }
    > .header {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 6rem;
      border-radius: 0.5rem;
      border: 1px solid #f1f0f0;
      background-color: #fafafa;
      margin-bottom: 0.5rem;
      > img {
        height: 4.7rem;
        width: 4.7rem;
        object-fit: contain;
      }
    }

    > .name {
      color: #200085;
      font-size: 1.3rem;
    }

    > .price {
      color: rgba(32, 0, 133, 0.7);
      font-size: 1rem;
    }
  }
}
