














.list-container {
  height: 10.8rem;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  background-image: url('https://static.aipiaxi.com/image/2024/03/FuzTnxxezn1TBjmO-eKAtvZPYCVK.png');
}
