





































































































.wealth-level-desc {
  /deep/ {
    .list-swiper {
      height: calc(100vh - 2.5rem - 3.7rem);
    }
  }
  .list {
    padding: 0 1.5rem;
    padding-bottom: 5rem;
  }
  .section {
    margin-bottom: 2.5rem;
    > .title {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }

    > .desc {
      font-size: 1.4rem;
    }
  }

  .level-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #e8e7e7;
    text-align: center;
    th {
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 1.2rem;
      background-color: #ffd415;
      border-right: 1px solid #e8e7e7;
      &:last-child {
        border-right: none;
      }
    }

    td {
      height: 4rem;
      border-bottom: 1px solid #e8e7e7;
      border-right: 1px solid #e8e7e7;
    }
  }
}
