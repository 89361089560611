.section {
  .body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank/素材@3x.png');
    background-size: 25.25rem 100%;
    height: 20rem;
    background-repeat: no-repeat;
    background-position: top center;
    color: #fff;

    > .avatarWrapper {
      width: 10.7rem;
      height: 10.7rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank/头像框@3x.png');
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      > img {
        height: 8.5rem;
        width: 8.5rem;
        border-radius: 50%;
      }
    }

    > .name {
      font-size: 1.5rem;
      font-weight: bold;
    }

    > .subtitle {
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.7);
    }
  }
}
