











































.the-card-section-container {
  padding-bottom: 1rem;
  > ul {
    display: flex;
    justify-content: space-between;
    > li {
      position: relative;
      height: 9rem;
      width: 8rem;
      background-size: 100% 100%;

      &.jin {
        background-image: url('../../../../assets/activity/spring-festival/金@3x.png');
      }

      &.niu {
        background-image: url('../../../../assets/activity/spring-festival/牛@3x(1).png');
      }

      &.he {
        background-image: url('../../../../assets/activity/spring-festival/贺@3x.png');
      }

      &.sui {
        background-image: url('../../../../assets/activity/spring-festival/岁@3x.png');
      }

      > .flag {
        position: absolute;
        top: 0.4rem;
        right: 0.4rem;
        color: #ffe8c9;
        font-size: 1rem;
        font-weight: bold;
        background-color: #fc6327;
        border: 0.2rem solid #ffe8c9;
        display: inline-block;
        height: 1.7rem;
        width: 2.4rem;
        text-align: center;
        border-radius: 0.85rem;
      }
    }
  }

  > .extra {
    font-size: 1.2rem;
    color: #ffe8c9;
    margin-bottom: 0.5rem;
    padding-left: 0.7rem;

    &.first {
      margin-top: 1.5rem;
    }

    &.last {
      margin-bottom: 3rem;
    }
  }
}
