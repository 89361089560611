.theFooter {
  position: relative;
  background-color: #E98AFF;
  border: 0.15rem solid #000;
  border-radius: 1.5rem;
  padding: 0 1.7rem;
  padding-top: 3rem;
  padding-bottom: 2.5rem;
  margin: 0 1.5rem;
  z-index: 999;
  >.header {
    position: relative;
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.7rem;
    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      z-index: 0;
      background: linear-gradient(90deg,  #FFF391, #FFC843);
      height: 0.9rem;
      width: 8.85rem;
      border-radius: 0.45rem;
    }
  
    >span {
      position: relative;
      z-index: 9;
    }

    >.right {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 1.5rem;
      font-weight: normal;
    }
  }

  >.body {
    position: relative;
    padding: 2.5rem 2.3rem;
    border: 0.15rem solid #000;
    background-color: #fff;
    text-align: center;
    border-radius: 1rem;
    &::after {
      content: '';
      position: absolute;
      left: -3.5rem;
      top: 4.5rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/workers-day/爱心@2x.png');
      height: 7rem;
      width: 4.75rem;
      background-size: 140% 100%;
      background-position-x: -2rem;
      background-repeat: no-repeat;
    }

    >.btn {
      font-size: 2.4rem;
      font-weight: bold;
      height: 10rem;
      width: 10rem;
      line-height: 10rem;
      margin: 0 auto;
      margin-bottom: 1.5rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/workers-day/抽奖.png');
      background-size: 100% 100%;
    }

    >.text {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 2rem;
    }

    >.imglist {
      display: flex;
      >img {
        height: 3rem;
        width: 3rem;
        margin-right: 0.3rem;
        border-radius: 0.1rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
