






































































































































.submit-review-container {
  .desc {
    color: #7d7d7d;
    font-size: 1.2rem;

    .qq {
      display: inline-block;
      margin: 0 0.5rem;
      color: #1a1a1a;
    }
  }
}
