.list {
  > .listItem {
    padding: 1.5rem;
    background-color: #ffffff;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    > .top {
      display: flex;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid rgba(243, 243, 243, 0.5);

      > .avatar {
        height: 7.5rem;
        width: 7.5rem;
        border-radius: 0.3rem;
        margin-right: 1.5rem;
      }

      > .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > .info {
          > .title {
            font-size: 1.6rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
          }

          > .subtitle {
            display: flex;
            align-items: center;
            font-size: 1.1rem;
            color: #7d7d7d;
            > span {
              margin-right: 1rem;
            }
          }
        }

        > .extra {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > .autor {
            flex: 1;
            display: flex;
            align-items: center;
            font-size: 1.2rem;
            > .avatar {
              height: 1.9rem;
              width: 1.9rem;
              border-radius: 50%;
              margin-right: 0.7rem;
            }
          }

          > .btn {
            border-radius: 0.3rem;
            font-size: 1.1rem;
            width: 4.7rem;
            height: 1.9rem;
            line-height: 1.9rem;
            text-align: center;
            background: linear-gradient(135deg, #ffeb6f 0%, #ffd416 100%);

            > .icon {
              display: inline-block;
              height: 0.9rem;
              width: 0.9rem;
              background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/honor-palace/add@3x(1).png');
              background-size: 100% 100%;
              margin-right: 0.2rem;
            }
          }

          > .naming {
            position: relative;
            font-size: 1.1rem;
            display: flex;
            align-items: center;
            z-index: 0;
            font-weight: normal;
            width: 7rem;
      
            &::after {
              position: absolute;
              content: "";
              height: 0.6rem;
              left: -0.3rem;
              right: -0.3rem;
              bottom: 0.5rem;
              background-color: var(--bg-color);
              z-index: -1;
            }
      
            > .icon {
              background-size: 100%;
              height: 1.4rem;
              width: 1.4rem;
            }
          }
        }
      }
    }

    > .middle {
      padding: 1.5rem 0;
      border-bottom: 1px solid rgba(243, 243, 243, 0.5);

      > .desc {
        font-size: 1.2rem;
        margin-bottom: 2rem;
      }

      > .tag {
        display: flex;
        align-items: center;
        color: #7d7d7d;
        font-size: 1.1rem;

        > span {
          height: 2rem;
          width: 4rem;
          text-align: center;
          line-height: 2rem;
          margin-right: 0.5rem;
          border-radius: 1rem;
          border: 1px solid #e8e7e7;
        }
      }
    }

    > .bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #7d7d7d;
      padding-top: 1.6rem;

      > .left {
        font-size: 1.1rem;
        > span {
          margin-right: 1rem;
        }
      }

      > .right {
        display: flex;
        > .item {
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          margin-right: 1.5rem;

          > .icon {
            height: 1.2rem;
            width: 1.2rem;
            background-size: 100% 100%;
            margin-right: 0.5rem;

            &.heart {
              background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/honor-palace/喜欢@3x.png');
            }

            &.star {
              background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/honor-palace/收藏@3x.png');
            }
          }
        }
      }
    }
  }
}

.years {
  padding: 2.5rem 0;
  font-size: 1.3rem;
  color: #c0bdbd;

  > .swiperContainer {
    :global(.swiper-wrapper) {
      align-items: center;
    }
  }

  .swiperSlide {
    width: 3rem;
    margin-right: 1.5rem;
    &:first-child {
      margin-left: 1.5rem;
    }

    &.active {
      color: #1a1a1a;
      font-size: 1.5rem;
      font-weight: bold;
    }
  }
}

.honorList {
  :global(.tab-list) {
    margin-top: 0 !important;
    background-color: #e8e7e7 !important;
  }

  > .empty {
    position: relative;
  }
}
