.section {
  position: relative;
  margin-bottom: 4.6rem;
  > .header {
    position: absolute;
    text-align: center;
    line-height: 3.3rem;
    height: 3.7rem;
    width: 17.4rem;
    left: 50%;
    transform: translateX(-50%);
    top: -2rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank/标题@3x.png');
    background-size: 100% 100%;
    font-size: 1.4rem;
    font-weight: bold;
    color: #ff6500;
  }

  > .body {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 1.5rem;
    min-height: 10rem;
  }
}
