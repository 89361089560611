
































































.the-card-collection-panel {
  /deep/ {
    .section-container {
      &:first-child {
        margin-bottom: 1rem;
      }
    }

    .avatar-modal-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .avatar-form {
    height: 11.35rem;
    width: 11.35rem;
  }
}
