.recommend-form {
  padding: 0 1.5rem;
  > .title {
    font-size: 1.4rem;
    font-weight: bold;
    padding: 2rem 0;
    border-bottom: 1px solid #f3f3f3;
  }

  > .form {
    padding-top: 2rem;
    > .row {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;

      &.flex-start {
        align-items: flex-start;
      }

      > .label {
        color: #c0bdbd;
        font-size: 1.4rem;
        margin-right: 1.5rem;
        width: 5.9rem;
        text-align: left;
      }

      > .content {
        font-size: 1.3rem;
      }

      > .input {
        display: flex;
        align-items: center;
        border: 1px solid #f3f3f3;
        background-color: #fafafa;
        border-radius: 0.5rem;
        overflow: hidden;

        > input {
          background-color: #fafafa;
          height: 4rem;
          width: 18rem;
        }

        > .btn {
          background-color: #fff;
          height: 4rem;
          width: 4.5rem;
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/author-center/find_search@3x.png');
          background-size: 1.5rem 1.5rem;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}
