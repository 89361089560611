































.entries {
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  margin-top: 2.4rem;
  > a {
    position: relative;
    display: flex;
    flex-direction: column;
    font-size: 1.4rem;
    text-align: center;
    > .flag {
      position: absolute;

      top: -0.7rem;
      right: -0.7rem;
      width: 2.8rem;
      height: 1.7rem;
    }
    > img {
      height: 5rem;
      width: 6rem;
      margin-bottom: 1rem;
    }
  }
}
