


































.article-share-detail-container {
  position: relative;
  > .tab {
    font-size: 1.5rem;
    position: absolute;
    color: rgba(26, 26, 26, 0.5);
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    padding-top: 1rem;
    > a {
      margin-right: 2rem;

      &.router-link-exact-active {
        color: #1a1a1a;
        font-size: 1.7rem;
        font-weight: bold;
      }
    }
  }

  /deep/ {
    .container {
      padding-top: 5.1rem;
    }
  }
}
