

























































.the-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.4rem 1rem;
  background-image: url('../../../../assets/activity/spring-voice/格纹@3x.png');
  background-size: 100% 100%;
  > .right {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.3rem;
    height: 2.9rem;
    width: 6.5rem;
    border-radius: 1.43rem;
    background-color: #fcffe2;
    color: #2e4024;

    > img {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.2rem;
    }
  }

  > .input-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    margin: 0 1rem;
    height: 2.9rem;
    /* width: 20.5rem; */
    border-radius: 1.43rem;
    background-color: #96dfa3;
    overflow: hidden;
    justify-content: space-between;
    padding: 0 1.5rem;
    > .input {
      /* flex: 1; */
      width: 12rem;
      font-size: 1.3rem;
      color: #00563c;
      font-weight: bold;
      background-color: #96dfa3;
      &::placeholder {
        color: #00563c;
        font-weight: bold;
      }
    }

    > img {
      height: 1.3rem;
      width: 1.3rem;
    }
  }

  > .btn {
    background: linear-gradient(90deg, #ff9e33, #fc7000);
    box-shadow: 0px 2px 2px 0px #52ca79;
    width: 6.5rem;
    height: 2.9rem;
    line-height: 2.9rem;
    color: #fff;
    font-size: 1.4rem;
    font-weight: bold;
    border-radius: 1.43rem;
    text-align: center;
  }

  .ticket-modal {
    padding: 0 2.5rem;
    padding-top: 3.9rem;
    padding-bottom: 3.5rem;
    background-color: #ffffe3;
    font-size: 1.4rem;
    color: #2e4024;

    > .btn {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      font-size: 1.6rem;
      font-weight: bold;
      color: #00563c;
      background: linear-gradient(90deg, #fceb43, #fdca24);
      height: 4rem;
      width: 20rem;
      border-radius: 2rem;
      text-align: center;
      line-height: 4rem;
      margin-top: 3rem;
    }

    > .row {
      margin-bottom: 1.4rem;
    }
  }
}
