.taskList {
  background-color: #2b79f0;
  border-radius: 0.8rem;
  padding: 0 2rem;
  padding-bottom: 3rem;

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.6rem;
    color: #fff;
    font-weight: bold;
    padding: 2rem 0;
    border-bottom: 1px solid rgba(252, 248, 234, 0.1);

    > .icon {
      height: 2rem;
      width: 2rem;
    }
  }
}

.taskListItem {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;
  border-bottom: 1px solid rgba(252, 248, 234, 0.1);
  > .content {
    > .title {
      font-size: 1.5rem;
      color: #fff;
      margin-bottom: 1rem;
    }

    > .subtitle {
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .btn {
    display: inline-block;
    background-color: rgba(255, 167, 106, 1);
    height: 2.6rem;
    width: 6rem;
    border-radius: 1.3rem;
    color: #fff;
    font-size: 1.3rem;
    line-height: 2.6rem;
    text-align: center;

    &.complete {
      background-color: #3386f2;
    }
  }
}
