



















.tag-list-container {
  padding-top: 3.5rem;
  > .title {
    font-size: 1.8rem;
    font-weight: bold;
  }

  > ul {
    padding: 2.5rem 0;
    font-size: 0;
    > li {
      font-size: 1.4rem;
      border: 1px solid rgba(232, 231, 231, 0.5);
      border-radius: 1.6rem;
      padding: 0.6rem 2rem;
      display: inline-block;
      margin-right: 1rem;
      margin-bottom: 1rem;
    }
  }
}
