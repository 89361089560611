































































































































.group-container {
  padding: 0 1.5rem;
  padding-bottom: 10rem;
  .header-img {
    position: absolute;
    top: 0;
    left: 0;
    height: 27.5rem;
    width: 100%;
    object-fit: cover;
  }

  .header-filter-mask {
    position: absolute;
    height: 27.5rem;
    left: 0;
    width: 100%;
    // background-color: rgba(0, 0, 0, 0.8);
    -webkit-filter: blur(10px);
    filter: blur(10px);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(10px);
  }

  > .header {
    padding-top: 4.8rem;
    position: relative;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 3.4rem;
    border-bottom: 1px solid rgba(232, 231, 231, 0.5);

    > .logo {
      height: 2.8rem;
      width: 8.1rem;
      margin-bottom: 2.9rem;
    }

    > .user-avatar {
      height: 5.5rem;
      width: 5.5rem;
      border-radius: 50%;
      margin-bottom: 1.5rem;
    }

    > .user-nickname {
      font-size: 1.5rem;
      color: #fff;
    }

    > .group-avatar {
      height: 11rem;
      width: 11rem;
      border-radius: 50%;
      margin-top: 3rem;
      margin-bottom: 1.5rem;
      object-fit: cover;
    }

    > .text {
      font-size: 1.4rem;
      margin-bottom: 1.2rem;
    }

    > .group-name {
      position: relative;
      font-size: 1.8rem;
      font-weight: bold;
      &::after {
        position: absolute;
        content: '';
        background-color: #ffeb6f;
        height: 0.4rem;
        width: 100%;
        bottom: -5px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  > .section {
    margin-top: 2.5rem;
    border-bottom: 1px solid rgba(232, 231, 231, 0.5);
    &:last-child {
      border-bottom: none;
    }
    > .title {
      font-size: 1.7rem;
      font-weight: bold;
    }

    > .content {
      font-size: 1.4rem;
      padding-top: 2rem;
      padding-bottom: 2rem;
      color: #7d7d7d;
      p {
        margin: 0;
      }
    }

    > .body {
      display: flex;
      align-items: center;

      .creator-nickname {
        color: #000;
      }

      > .creator-avatar {
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        margin-right: 1.5rem;
      }
    }
  }
  .btn-wrapper {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 9rem;
    width: 100%;
    background-color: #fff;
    padding: 0 2.5rem;
    padding-top: 1rem;
    box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.05);

    > .open_app_btn {
      display: inline-block;
      background-color: #ffd415;
      width: 100%;
      text-align: center;
      padding: 1.4rem 0;
      font-size: 1.7rem;
      font-weight: bold;
      border-radius: 3.03rem;

      &:active {
        background-color: rgba(255, 212, 21, 0.5);
      }
    }
  }
}
