


































.the-calendar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 2.15rem;
  height: 10rem;
  background: #b543ff linear-gradient(135deg, #bf55fe 0%, #7d60f1 100%);
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 3rem;
    width: 1.5rem;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    top: 8.5rem;
  }

  &::before {
    background-image: url('../../../../assets/webview/calendar/左边@2x.png');
    left: 2.4rem;
  }

  &::after {
    background-image: url('../../../../assets/webview/calendar/右边@2x.png');
    right: 2.4rem;
  }

  > .title {
    font-size: 2rem;
    color: #fff;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  > .subtitle {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.3rem;
  }

  > .btn {
    position: absolute;
    height: 2rem;
    width: 2rem;
    top: 50%;
    transform: translateY(-50%);

    &:nth-child(1) {
      left: 2.15rem;
    }

    &:nth-child(2) {
      right: 2.15rem;
    }
  }
}
