


















































































































.relationship-page {
  /deep/ {
    .app-layout-body {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

.control-container {
  > .control-item {
    font-size: 1.4rem;
    text-align: center;
    padding: 1.5rem;
  }

  > .cancel {
    border-top: 1px solid #ddd;
    margin-top: 1rem;
  }
}

.about-icon {
  height: 2.5rem;
  width: 2.5rem;
}
