

























































































































































.the-calendar-body {
  padding: 0 0.7rem;
  padding-top: 4rem;
  height: 33.1rem;
  position: relative;
  background-color: #fff;
  &::after {
    position: absolute;
    height: 9rem;
    width: 9rem;
    content: '';
    // background-image: url('../../../../assets/webview/calendar/calendar_bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    right: 0;
  }

  > .week-header {
    display: flex;
    font-size: 1.5rem;
    color: #c0bdbd;
    font-weight: bold;
    margin-bottom: 2rem;

    > li {
      flex: 1;
      text-align: center;
    }
  }

  .month {
    > .row {
      display: flex;
      font-size: 1.5rem;
      font-weight: bold;
      height: 3.1rem;
      line-height: 3.1rem;
      margin-bottom: 0.6rem;
      > li {
        position: relative;
        width: 14.285%;
        text-align: center;
        color: #7d7d7d;
        > span {
          display: inline-block;
          position: relative;
          z-index: 9;
          height: 3.1rem;
          width: 3.1rem;
          border-radius: 50%;
        }

        &.current {
          &::after {
            content: '';
            box-sizing: border-box;
            position: absolute;
            z-index: 8;
            height: 3.5rem;
            width: 3.5rem;
            background-color: #ffd415;
            border: 0.2rem solid #fff;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          > span {
            color: #1a1a1a;
            background-color: transparent !important;
          }
        }

        &.fill-radius {
          background-color: #e9f5ff;
        }

        &.left-radius {
          background-color: #e9f5ff;
          border-radius: 1.55rem 0 0 1.55rem;
        }

        &.right-radius {
          background-color: #e9f5ff;
          border-radius: 0 1.55rem 1.55rem 0;
        }

        &.height-light {
          border-radius: 1.55rem;
          color: #1a1a1a;
          > span {
            // display: inline-block;
            // height: 3.1rem;
            // width: 3.1rem;
            background-color: #e9f5ff;
            border-radius: 50%;
          }
        }
      }
    }
  }
}
