





























































































































































































































































































































































































































































































































@import './TheList.scss';

.action-row {
  font-size: 1.4rem;
  padding: 2rem;
  text-align: center;
  border-bottom: 1px solid #f3f3f3;
  background-color: #fff;
  &:last-child {
    border: none;
    margin-top: 0.5rem;
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.1);
  }

  &.track-row {
    display: flex;
    align-items: center;
  }

  > .checkbox {
    height: 1.5rem;
    width: 1.5rem;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0.5rem;
    // background-image: url('../../../../assets/login/1@3x.png');
    background-size: 100%;
    background-repeat: no-repeat;
    margin-right: 0.5rem;
    &.active {
      background-image: url('../../../../assets/login/2@3x.png');
    }
  }
}

.action-popup {
  border-radius: 2rem 2rem 0 0;
  background-color: #fafafa;

  .title {
    font-weight: bold;
    font-size: 1.4rem;
    padding: 2rem;
    text-align: center;
    border-bottom: 1px solid #f3f3f3;
    background-color: #fff;
  }

  .control-container {
    display: flex;
    padding: 0 3rem;
    padding-top: 2rem;
    padding-bottom: 5rem;
    .control-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      color: #7d7d7d;
      margin-right: 3rem;

      > .icon {
        height: 5rem;
        width: 5rem;
        background-size: 100% 100%;
      }
    }
  }
}

.submit-container {
  padding: 4rem 0;
  font-size: 1.2rem;
  text-align: center;

  > .notice {
    margin-top: 1rem;
    color: blue;
  }
}
