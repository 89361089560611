































































































































































































































































.my-article-container {
  .list {
    padding: 0 1.5rem;
    padding-bottom: 10rem;
    overflow-y: scroll;
  }
}
