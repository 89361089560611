



















































.the-effect-list-item-container {
  padding: 0 1.5rem;
  &:active {
    background-color: #e8e7e7;
  }

  .the-effect-list-item-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 1.9rem 0;
    border-bottom: 1px solid rgba(232, 231, 231, 0.8);

    .title {
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      margin-bottom: 0.5rem;
      > img {
        height: 1.3rem;
        width: 1.3rem;
        margin-right: 1rem;
      }
    }

    .sub-title {
      font-size: 1.2rem;
      color: #7d7d7d;
    }

    > .add-icon {
      height: 1.6rem;
      width: 1.6rem;
    }

    > .label {
      font-size: 1.2rem;
      color: #7d7d7d;
    }
  }
}
