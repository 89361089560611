


















































































































/deep/ {
  .app-layout-body {
    > .top-img-wrapper {
      font-size: 0;
      > .top-img {
        width: 100%;
        height: 38.3rem;
        overflow: hidden;
      }
    }
  }
}

.spring-festival-page {
  background-color: #6d0806;

  .rule {
    > .time {
      font-size: 1.4rem;
      text-align: center;
      font-weight: bold;
    }

    > ul {
      font-size: 1.4rem;
      padding: 1rem 1rem;
      line-height: 2rem;
    }
  }

  /deep/ {
    .header {
      color: #ffe8c9;

      > .left {
        > .back-icon {
          height: 1.5rem;
          width: 1.5rem;
        }
      }
    }
  }
}
