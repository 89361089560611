.rank {
  position: relative;
  z-index: 9;
  > .header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 0rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin: 0 1.5rem;
    > .item {
      color: #fff;
      font-size: 1.4rem;
      background-color: #5b8cd2;
      height: 3.5rem;
      width: 12rem;
      line-height: 3.5rem;
      border-radius: 2.35rem;
      text-align: center;
      font-weight: bold;
      margin-right: 1.5rem;
      &.active {
        background: #c0bdbd linear-gradient(135deg, #ffeb6f 0%, #ffd416 100%);
        color: #000;
      }
    }
  }

  > .list {
    padding: 0 2.5rem;
    padding-bottom: 3rem;
    > .item {
      display: flex;
      align-items: center;
      color: #fff;
      > .icon {
        display: inline-block;
        height: 2.5rem;
        width: 2.5rem;
        margin-right: 1.5rem;
        background-size: 100% 100%;
        text-align: center;
        font-size: 1.3rem;
        &.level0 {
          background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/1@3x.png);
        }

        &.level1 {
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/1@3x(1).png');
        }

        &.level2 {
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/1@3x(2).png');
        }
      }

      > .content {
        display: flex;
        flex: 1;
        align-items: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding: 1rem 0;
        > .avatar {
          height: 4.5rem;
          width: 4.5rem;
          border-radius: 50%;
          margin-right: 2rem;
        }
        > .info {
          > .nickname {
            font-size: 1.3rem;
            margin-bottom: 1rem;
          }

          > .diamone {
            display: flex;
            align-items: center;
            > .icon {
              display: inline-block;
              width: 1.1rem;
              height: 1.1rem;
              background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/钻石@3x.png);
              background-size: 100% 100%;
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }
}
