.page {
  background-color: #3b31c8;
  // background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/背景1@3x.png');
  // background-size: 100% 36.2rem;
  // background-repeat: no-repeat;

  .bg {
    width: 100%;
    height: 36.3rem;
    position: absolute;
    left: 0;
    top: 0;
  }

  .top-bar {
    position: relative;
    display: flex;
    align-items: center;
    height: 3rem;
    width: 34.25rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/提示条@3x.png');
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 1rem;
    color: #fff;
    font-size: 1.2rem;
    margin-bottom: 2.85rem;

    .right {
      height: 3rem;
      line-height: 3rem;
      flex: 1;
      width: 28rem;
      > .notice {
        height: 3rem;
      }
    }

    > .trumpet {
      display: inline-block;
      height: 2rem;
      width: 2rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/喇叭@3x.png');
      background-size: 100% 100%;
      margin: 0 1rem;
    }
  }

  .title {
    position: relative;
    height: 12rem;
    width: 27rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/一起pia戏.png');
    background-size: 100% 100%;
    margin: 0 auto;
    margin-bottom: 1.4rem;
  }
}

.rightIcon {
  height: 2rem;
  width: 2rem;
}
