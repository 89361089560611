.page {
  &.blue {
    background-color: rgba(44, 150, 255, 1);
  }

  &.purple {
    background-color: #b045ff;
  }

  &.pink {
    background: linear-gradient(135deg, #51a8ff 0%, #ff58c7 46%, #ff8c5a 100%);
  }

  > .footer {
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.7);
    font-size: 1.2rem;
    text-align: center;
    padding: 1.9rem 2.7rem;
  }
}

.body {
  > .list {
    display: flex;
    justify-content: center;
    padding: 0 5rem;
    padding-top: 2.75rem;
    padding-bottom: 2.9rem;
    > .item {
      &:nth-child(2) {
        margin-left: 2.5rem;
      }
      > .content {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room-chest/avatar-wrapper.png');
        height: 10.75rem;
        width: 10rem;
        background-size: 100% 100%;
        margin-bottom: 1rem;

        > .flag {
          position: absolute;
          top: 0.3rem;
          left: 0.65rem;
          line-height: 1.1rem;
          font-size: 1.1rem;
          color: #fff;
        }

        > img {
          width: 6rem;
          height: 6rem;
        }
      }

      > .name {
        text-align: center;
        color: #fff;
        font-size: 1.4rem;
      }

      > .price {
        text-align: center;
        color: rgba(255, 255, 255, 0.5);
        font-size: 0.9rem;
      }
    }
  }

  > .text {
    font-size: 1.2rem;
    text-align: center;
    color: #fff;
    padding: 0 1.6rem;
    padding-bottom: 2rem;
    > span {
      font-size: 1.6rem;
      color: #ffeb6f;
      font-weight: bold;
    }
  }
}
