.the-list {
  height: calc(100% - 3.3rem - 2px - 2rem);

  > .filter-container {
    overflow: hidden;
    > .filter-row {
      padding: 0 1.5rem;
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;
      > .label {
        font-size: 1.3rem;
        margin-right: 1.5rem;
      }

      > .list {
        display: flex;
        align-items: center;
        flex: 1;

        .swiper-slide {
          width: auto;
        }

        > .tag,
        .swiper-slide {
          font-size: 1.1rem;
          text-align: center;
          line-height: 2.2rem;
          height: 2.3rem;
          padding: 0 1.2rem;
          border-radius: 1.15rem;
          border: 1px solid #e8e7e7;
          color: #7d7d7d;
          margin-right: 0.5rem;
          &.active {
            background: linear-gradient(135deg, #ffeb6f 0%, #ffd415 100%);
            color: #000;
            border-color: transparent;
          }
        }
      }
    }
  }

  > .list {
    position: relative;
    height: calc(100% - 7.6rem);
    padding-bottom: 10rem;
    overflow-y: scroll;
  }
}

.input-wrapper {
  border: 1px solid #f3f3f3;
  background-color: #fafafa;
  margin: 1rem 2rem;
  border: 0.5rem;
  > input {
    background-color: #fafafa;
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
  }
}

.create-btn {
  position: fixed;
  z-index: 999;
  background: #ffd415 linear-gradient(135deg, #ffeb6f 0%, #ffd416 100%);
  left: 2.5rem;
  right: 2.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  bottom: 3.5rem;
  text-align: center;
  padding: 1.45rem 0;
  border-radius: 3rem;

  &:active {
    background-color: rgba(255, 212, 21, 0.8);
  }
}

.secrect-note {
  font-size: 1.2rem;
  color: red;
  padding: 0 2rem;
}
