.page {
  background-color: #fafafa;
  min-height: 100vh;
  > .header {
    padding-top: 11.9rem;
    padding-left: 2.5rem;
    height: 22.5rem;
    width: 100%;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/honor-palace/荣誉殿堂-背景@3x.png');
    background-size: 100% 100%;

    > .name {
      font-size: 3rem;
      font-weight: bold;
      background: linear-gradient(180deg, #ffffff 0%, #fff5ad 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    > .title {
      font-size: 1.4rem;
      font-weight: bold;
      color: #fff;
      letter-spacing: 0.8rem;
    }
  }
}

.rightIcon {
  height: 2.5rem;
  width: 2.5rem;
}
