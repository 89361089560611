



































































.the-info {
  span {
    margin-right: 1.5rem;
  }
  .info {
    font-size: 1.4rem;
    color: #1a1a1a;
    margin-bottom: 2rem;
  }

  .extra {
    font-size: 1.2rem;
    color: #7D7D7D;
  }
}
