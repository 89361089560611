


















.form-item-container {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(232, 231, 231, 0.5);
  padding: 1.5rem 0;
  &:last-child {
    border-bottom: none;
  }
  .label {
    font-size: 1.4rem;
  }

  .content {
    flex: 1;
    margin-left: 2rem;
  }
}
