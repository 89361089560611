






























































































.second {
  .the-card {
    margin: 0;
    &:first-child {
      margin-right: 0.5rem;
    }
    &:nth-child(2) {
      margin-left: 0.5rem;
      margin-right: 1rem;
    }

    &:nth-child(3) {
      margin-right: 0.5rem;
      margin-left: 1rem;
    }

    &:nth-child(4) {
      margin-left: 0.5rem;
    }
  }
}
