.theTabBar {
  .tabs {
    display: flex;
    padding: 2.5rem 2rem;
    justify-content: space-between;
    > .tabItem {
      background-color: #2b79f0;
      height: 3.9rem;
      width: 14rem;
      border-radius: 1.95rem;
      color: #fff;
      font-size: 1.6rem;
      text-align: center;
      line-height: 3.9rem;

      &.active {
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/summer/导航@2x.png');
        background-size: 100% 100%;
        height: 4.5rem;
        background-color: transparent;
        text-shadow: 1px 2px 2px rgba(238, 109, 83, 0.5);
      }
    }
  }

  > .nomore {
    font-size: 1.4rem;
    color: rgba(255, 255, 255, 0.6);
    padding: 3rem 0;
    text-align: center;
  }
}
