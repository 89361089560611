












.the-effect-list-container {
  // padding: 0 1.5rem;
  padding-top: 0.6rem;
  height: calc(100%);
  overflow: scroll;
}
