




























.the-header {
  // background-color: #181818;
  padding-top: 13.5rem;
  height: 21.65rem;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('https://static.aipiaxi.com/image/2024/03/Fner6g8YPNs9m6ImSvokW4RHDG4o.png');
  > .info {
    padding-bottom: 3rem;
    padding-left: 4rem;
    height: 10.15rem;
    background-size: 100% 100%;
    display: flex;

    > .avatar {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
      margin-right: 2rem;
    }

    > .right {
      > .top {
        font-size: 1.8rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
        > img {
          height: 1.6rem;
          width: 1.6rem;
        }
      }

      > .subtitle {
        color: rgba(26, 26, 26, 0.7);
        font-size: 1.3rem;
      }
    }
  }
}
