































































































































.the-intimate-request {
  padding: 0 1.5rem;
  padding-bottom: 2rem;
  > .header {
    padding: 2rem 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(243, 243, 243, 1);
    > .left {
      font-size: 1.5rem;
      font-weight: bold;
    }

    > .right {
      position: relative;
      font-size: 1.4rem;
      padding-right: 1.5rem;

      &::after {
        position: absolute;
        content: '';
        right: 0rem;
        height: 1rem;
        width: 1rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/intimate/更多@3x.png');
        background-size: 100% 100%;
        background-repeat: no-repeat;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  > .list {
    > .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 0;
      > .icon {
        height: 4.8rem;
        width: 4.8rem;
        margin-right: 1.5rem;
      }

      > .right {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        &::after {
          position: absolute;
          content: '';
          width: 100%;
          border-bottom: 1px solid rgba(243, 243, 243, 1);
          bottom: -2.15rem;
        }
        .title {
          font-size: 1.5rem;
          font-weight: bold;
        }

        .sub-title {
          color: #7d7d7d;
          font-size: 1.2rem;
        }
        > .btn {
          height: 2.3rem;
          width: 5rem;
          text-align: center;
          line-height: 2.3rem;
          border: 1px solid #f3f3f3;
          background-color: #fafafa;
          border-radius: 1.15rem;
          font-size: 1.3rem;

          &.disabled {
            color: #f3f3f3;
            background-color: #fff;
          }
        }
      }
    }
  }

  /deep/ {
    .van-popup {
      border-radius: 1rem 1rem 0 0;
      padding-bottom: 3.5rem;
      > .header {
        text-align: center;
        font-size: 1.5rem;
        padding: 2rem 0;
      }

      > .prop-list {
        display: flex;
        justify-content: space-between;
        padding: 0 1.5rem;
        > .item {
          text-align: center;
          > .wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 7.5rem;
            width: 7.5rem;
            background-color: #fafafa;
            border: 1px solid #f3f3f3;
            border-radius: 0.5rem;
            margin-bottom: 1rem;
            &.active {
              border: 1px solid #ffd415;
            }
            > .img {
              height: 4.5rem;
              width: 4.5rem;
            }
          }

          > .title {
            font-size: 1.2rem;
            font-weight: bold;
          }

          > .sub-title {
            color: #7d7d7d;
            font-size: 1.1rem;
          }
        }
      }

      > .request-btn {
        font-size: 1.5rem;
        font-weight: bold;
        background-color: #ffd415;
        border-radius: 3.03rem;
        width: 27.5rem;
        height: 4rem;
        line-height: 4rem;
        text-align: center;
        margin: 0 auto;
        margin-top: 3rem;
      }
    }
  }
}
