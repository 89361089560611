























































.loading-wrapper {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.empty-container {
  display: flex;
  flex-direction: column;
  height: 500px;
  align-items: center;
  justify-content: center;

  .note {
    margin-top: 2rem;
    color: #bfbdbd;
    font-size: 1.4rem;
  }
}

.list {
  position: relative;
  padding: 0 1.5rem;
  padding-top: 8.5rem;
  background-color: #fff;
  /* height: 100vh; */
  /* overflow: scroll; */

  .item {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 2.5rem 0;
    border-bottom: 1px solid #e8e7e7;

    > .left {
      flex: 1;
    }

    > .avatar {
      height: 5rem;
      width: 5rem;
      object-fit: cover;
      margin-left: 1.5rem;
      border-radius: 0.5rem;
    }

    &:last-child {
      border: none;
    }

    p {
      margin: 0;
    }

    .title {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }

    .desc {
      font-size: 1.3rem;
      color: #7d7d7d;
      margin-bottom: 1rem;
    }

    .extra {
      color: #1a1a1a;
      font-size: 1.3rem;

      span {
        display: inline-block;
        margin-right: 0.8rem;
      }
    }
  }
}
