

























@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  // 80% {
  //     transform: scale3d(0.97, 0.97, 0.97);
  // }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.5);
}
.modal {
  position: fixed;
  z-index: 100;
  width: 30rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > .modal-content {
    border-radius: 1rem;
    animation: bounceIn 1000ms 100ms both;
  }

  > .close {
    position: relative;
    top: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
    height: 3rem;
    width: 3rem;
  }
}
