







































.workflow {
  padding-top: 1.8rem;
  background-color: #ffffe3;
  > .title {
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    z-index: 99;
    margin-bottom: 1.2rem;
    color: #2e4024;

    &::after {
      position: absolute;
      content: '';
      background: linear-gradient(90deg, #fceb43, #fdca24);
      height: 0.5rem;
      width: 18rem;
      bottom: 2px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  > .body {
    padding: 0 2rem;
    padding-bottom: 1.4rem;
    > .row {
      display: flex;
      font-size: 1.3rem;
      color: #2e4024;
      font-weight: bold;
      margin-bottom: 1.9rem;
    }
  }
}
