




















.search {
  position: relative;
  border: 1px solid #e8e7e7;
  border-radius: 1.75rem;
  font-size: 1.3rem;
  overflow: hidden;
  margin: 0 1.5rem;
  margin-bottom: 2rem;

  &.fill::after {
    display: none;
  }

  &::after {
    content: '';
    position: absolute;
    height: 1.4rem;
    width: 1.4rem;
    background-image: url('../../../../assets/webview/article-list/find_search@3x.png');
    background-size: 100% 100%;
    left: 8rem;
    top: calc(50% - 0.7rem);
  }

  > input {
    background-color: #f3f3f3;
    height: 3.3rem;
    width: 100%;
    text-align: center;
    padding: 0 1rem;
    &::placeholder {
      color: #7d7d7d;
    }
  }
}
