

























































































.the-activity-desc {
  /deep/ {
    .tab-panel {
      > .title {
        position: relative;
        font-size: 1.5rem;
        font-weight: bold;
        text-align: center;
        z-index: 99;
        margin-bottom: 2rem;
        color: #2e4024;

        &::after {
          position: absolute;
          content: '';
          background: linear-gradient(90deg, #fceb43, #fdca24);
          height: 0.5rem;
          width: 18rem;
          bottom: 2px;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }
      }

      > .row {
        display: flex;
        font-size: 1.3rem;
        color: #2e4024;
        font-weight: bold;
        margin-bottom: 1.95rem;
        > span:first-child {
          margin-right: 0.5rem;
        }
      }
    }
  }
}
