






















.gray {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);

  filter: grayscale(100%);

  filter: gray;
}

.the-gift-list-item {
  display: inline-block;
  width: calc((100% - 2.1rem) / 3);
  margin-right: 1.05rem;
  border-radius: 0.3rem;
  overflow: hidden;
  text-align: center;
  background-color: #fff;
  padding-bottom: 1.3rem;
  margin-bottom: 1.05rem;
  &:nth-child(3n) {
    margin-right: 0;
  }
  > .header {
    background-color: #1a1a1a;
    height: 10.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    > .image {
      height: 8rem;
      width: 8rem;
    }
  }

  > .name {
    margin-top: 0.8rem;
    font-size: 1.2rem;
    color: #7d7d7d;
  }

  > .count {
    margin-top: 1.2rem;
    font-size: 1.2rem;
  }
}
