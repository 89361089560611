























.the-title-input-container {
  /deep/ {
    .content {
      display: flex;
      align-items: center;
    }
  }
  .type-name {
    font-size: 1.4rem;
    padding-right: 1rem;
    color: rgba(192, 189, 189, 1);
  }
  .title-input {
    font-size: 1.4rem;
    width: 100%;
    padding: 0;
    flex: 1;

    &::placeholder {
      color: rgba(192, 189, 189, 1);
    }
  }
}
