



































.the-color-board {
  border-radius: 1.5rem 1.5rem 0 0;
  .color-picker-title {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
  .color-list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4rem;
    > li {
      position: relative;
      height: 4rem;
      width: 4rem;
      border-radius: 50%;
      margin-bottom: 2.5rem;
      margin-right: 2.3rem;

      &:nth-child(5n) {
        margin-right: 0;
      }

      &.active {
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          height: 1.5rem;
          width: 1.5rem;
          background-image: url('../../../../../assets/webview/editor/role-edit/Fill@3x.png');
          background-size: 100% 100%;
        }
      }
    }
  }
}
