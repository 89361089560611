























































































































































.container {
  position: relative;
}
.swiper-slide {
  height: auto !important;
}

.swiper-wrapper {
  height: auto;
}

p {
  padding: 0;
  margin: 0;
}

.hide {
  display: none;
}

.header-bg,
.header-bg2 {
  width: 100vw;
  position: absolute;
  top: 0;
}

.bg-wrapper {
  font-size: 0;
}

.header-bg1 {
  width: 100vw;
  height: 12.5rem;
  object-fit: cover;

  position: fixed;
  top: 0;
  z-index: 100;
  opacity: 0;
}

.header-bg2 {
  opacity: 0.8;
}

.fixed-bar {
  position: relative;
  /* position: absolute; */
  /* z-index: 200; */
  /* top: 9.35rem; */
  /* top: 13.2rem; */
  height: 2.5rem;
  line-height: 2.5rem;
}

.tab-bar {
  position: relative;
  z-index: 2000;
  font-size: 1.5rem;
  padding: 0 2.5rem;
  margin-bottom: 2rem;

  &.fixed {
    position: fixed;
    top: 8.5rem;
    visibility: hidden;
    /* display: none; */
  }

  .tab-item {
    margin-right: 3.5rem;

    &.active {
      font-size: 1.8rem;
      font-weight: bold;
    }
  }
}

.body {
  position: relative;
  z-index: 99;

  /* padding-top: 17rem; */
  padding-top: 13.2rem;

  .list {
    background-color: #ffffff;
    border-radius: 2rem 2rem 0 0;
    margin-bottom: 40px;
    padding: 0 2rem;
    padding-top: 2.5rem;
  }
}
