

























































































.btn-wrapper {
  position: fixed;
  z-index: 999;
  left: 0;
  bottom: 0;
  height: 9rem;
  width: 100%;
  background-color: #fff;
  padding: 0 2.5rem;
  padding-top: 1rem;
  box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.05);

  > .open_app_btn {
    display: inline-block;
    background-color: #ffd415;
    width: 100%;
    text-align: center;
    padding: 1.4rem 0;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 3.03rem;

    &:active {
      background-color: rgba(255, 212, 21, 0.5);
    }
  }
}
