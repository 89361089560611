





















































.medal-list {
  padding: 0 2rem;
  padding-top: 1rem;
  font-size: 0;
  > li {
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    display: inline-block;
    color: #fff;
    font-size: 1.3rem;
    width: calc((100% - 1.5rem) / 2);
    padding-top: 2rem;
    padding-bottom: 2.8rem;
    margin-bottom: 1.5rem;
    vertical-align: top;
    &:nth-child(odd) {
      margin-right: 1.5rem;
    }

    > .image {
      height: 10rem;
      width: 10rem;
      margin-bottom: 1.5rem;
    }

    > .progress-bar {
      width: 10rem;
      margin: 0 auto;
      margin-top: 1.5rem;
      height: 0.4rem;
      background-color: rgba(255, 255, 255, 0.2);
      border-radius: 0.2rem;
      overflow: hidden;

      > .progress {
        width: 0%;
        background-color: #ffd416;
        height: 100%;
        border-radius: 0.2rem;
      }
    }
  }
}
