



























































.relationship-entry-item {
  color: #fff;
  padding-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 10.8rem;
  height: 13.5rem;
  background-size: 100% 100%;
  margin-right: 1.05rem;
  margin-bottom: 1.05rem;

  &.bg_1 {
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/intimate/cp@3x.png');
  }

  &.bg_2 {
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/intimate/戏搭@3x(1).png');
  }

  &.bg_3 {
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/intimate/闺蜜@3x.png');
  }

  &.bg_4 {
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/intimate/基友@3x.png');
  }

  &:nth-child(3n) {
    margin-right: 0;
  }

  > .avatar {
    height: 4.5rem;
    width: 4.5rem;
    border-radius: 50%;
    margin-bottom: 1rem;
  }

  > .title {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bold;

    > img {
      width: 2.2rem;
      height: 2.2rem;
      margin-left: 0.1rem;
    }
  }

  > .sub-title {
    font-size: 1.2rem;
  }
}
