





































































































.burn-page {
  background-color: #2c0c02;
  .bg {
    width: 100%;
    height: 36.3rem;
    position: absolute;
    left: 0;
    top: 0;
  }

  .question-icon {
    width: 2rem;
    height: 2rem;
  }
}
