.page {
  > .header {
    box-sizing: content-box;
    padding: 0 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3.6rem;
    background-color: #fff;

    > .back-icon {
      height: 2.5rem;
      width: 2.5rem;
    }
    > .bar {
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: bold;
      color: #7d7d7d;

      > span {
        margin-right: 2rem;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          color: #000;
        }
      }
    }
  }

  .list {
    padding: 0 1.5rem;
    .item {
      padding: 2rem 0;
      border-bottom: 1px solid rgba(232, 231, 231, 0.5);
      > .title {
        display: flex;
        justify-content: space-between;
        font-size: 1.5rem;
        align-items: center;
        margin-bottom: 0.5rem;

        > .num {
          font-size: 1.8rem;
          font-weight: bold;
        }
      }

      > .time {
        color: #c0bdbd;
        font-size: 1.3rem;
      }
    }
  }
}
