
























































































































































































































































.edit-page-container {
  position: relative;
  background-color: #fafafa;
  height: 100vh;
  .comming-soon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.5rem;
  }
  .input-wrapper {
    margin: 0 1.5rem;
    .title-input {
      position: relative;
      padding-top: 3.5rem;
      padding-bottom: 2rem;
      font-size: 2.1rem;
      font-weight: bold;
      background-color: #fafafa;
      width: 100%;
      border-bottom: 1px dotted #c0bdbd;
    }
  }
}
