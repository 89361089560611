













































































.check-in-page-container {
  /deep/ {
    > .app-layout-body {
      padding: 0 1.5rem;
      padding-bottom: 4rem;
    }
  }
}
