








































































.home {
  padding-top: 1.6rem;
}

.header {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  padding-left: 1.5rem;
  padding-right: 2.5rem;
  background-color: #fff;

  .avatar {
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    object-fit: cover;
  }

  > .search-field {
    display: flex;
    flex: 1;
    align-items: center;
    height: 3.7rem;
    border-radius: 1.85rem;
    margin: 0 1.5rem;
    overflow: hidden;
    background-color: #f3f3f3;
    padding: 0 2rem;

    > input {
      background-color: #f3f3f3;
      border: none;
      outline: none;
      flex: 1;
      padding-left: 1.5rem;
      font-size: 1.4rem;
      color: #1a1a1a;
    }
  }

  .search-icon {
    height: 1.7rem;
    width: 1.7rem;
  }
  .edit-icon {
    height: 2.5rem;
    width: 2.5rem;
  }
}
