.rechargeVip {
  padding: 1.5rem;
  // height: 10.5rem;
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/recharge/vip-recharge.png');
  background-size: 100% 100%;
  margin-bottom: 1rem;

  &.diamond {
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/recharge/钻石会员.png');
  }

  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    padding-bottom: 1.4rem;
    > .left {
      display: flex;
      align-items: center;
      > .icon {
        height: 1.6rem;
        width: 1.6rem;
        margin-right: 0.5rem;
      }

      > .price {
        font-size: 1.7rem;
        font-weight: bold;
        background-image: linear-gradient(127deg, #fecbaa 0%, #f6ac7c 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-right: 1rem;
      }

      > .actual {
        font-size: 1.1rem;
        color: rgba(255, 255, 255, 0.5);
        text-decoration: line-through;
      }
    }
    > .btn {
      font-size: 1.3rem;
      font-weight: bold;
      height: 2.5rem;
      line-height: 2.5rem;
      width: 5rem;
      text-align: center;
      border-radius: 1.5rem;
      background: linear-gradient(127deg, #fecbaa 0%, #f6ac7c 100%);
    }
  }

  > .body {
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.1rem;
    padding-top: 1.3rem;
  }
}

.tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
  > div {
    border: 1px solid #ffd313;
    font-size: 1.2rem;
    background-color: rgba(255, 211, 19, 0.1);
    width: 8rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    border-radius: 1.5rem;
    margin-right: 1rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    &.active {
      height: 3.4rem;
      align-items: flex-start;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/recharge/选择@2x.png');
      background-size: 100% 100%;
      background-color: transparent;
      border: none;
    }

    &:last-child {
      margin-right: 0;
    }

    > .unit {
      color: #ff2424;
      font-size: 1rem;
      margin-left: 0.35rem;
    }
  }
}
