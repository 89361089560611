





























































.the-author {
  // padding-bottom: 10rem;
  .avatar {
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    object-fit: cover;
  }

  .right {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 1rem;
    > .info {
      > .name {
        font-size: 1.5rem;
        color: #1a1a1a;
      }

      > .extra {
        font-size: 1.1rem;
        color: #7d7d7d;

        > span {
          margin-right: 1rem;
        }
      }
    }

    .follow {
      font-size: 1.3rem;
      font-weight: bold;
      height: 2.6rem;
      line-height: 2.6rem;
      width: 6.7rem;
      border-radius: 1.5rem;
      background-color: #ffd415;
      text-align: center;

      > img {
        height: 1.1rem;
        width: 1.1rem;
        margin-right: 0.5rem;
      }
    }
  }
}
