.page {
  height: 100vh;
  overflow-y: scroll;
  padding-bottom: 9rem;
}

.theCommentList {
  padding: 0 1.5rem;

  > .title {
    font-size: 1.6rem;
    font-weight: bold;
    padding: 1.7rem 0;
    border-bottom: 1px solid rgba(243, 243, 243, 0.5);
  }

  > .body {
    > .item {
      display: flex;
      padding-top: 2rem;
      > .avatar {
        height: 3.5rem;
        width: 3.5rem;
        border-radius: 50%;
        margin-right: 1rem;
      }

      > .right {
        flex: 1;
        border-bottom: 1px solid rgba(243, 243, 243, 0.5);
        padding-bottom: 2rem;
        > .header {
          display: flex;
          align-items: center;
          font-size: 1.3rem;
          margin-bottom: 2rem;
          > .icon {
            display: inline-block;
            height: 1.6rem;
            width: 1.6rem;
            border-radius: 50%;
            background-size: 1.1rem 1.1rem;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 0.6rem;
            &.female {
              background-color: #ff89e9;
              background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/player-sheet/news_woman@3x.png');
            }

            &.male {
              background-color: #63c1ff;
              background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/player-sheet/news_man@3x.png');
            }
          }
        }

        > .content {
          font-size: 1.5rem;
          margin-bottom: 1rem;
        }

        > .bottom {
          display: flex;
          align-items: center;
          > span {
            font-size: 1.1rem;
            color: #979797;
            margin-right: 1rem;
          }

          > .count {
            background-color: #f3f3f3;
            border-radius: 1rem;
            font-size: 1.11rem;
            padding: 0.2rem 1rem;
          }
        }
      }
    }
  }
}
