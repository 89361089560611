









































.swiper-container {
  height: 15.2rem;
}
