































.the-left-menu-container {
  width: 10rem;
  padding: 1.5rem 1rem;
  background-color: #f3f3f3;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  > li {
    padding: 1rem 0.8rem;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    background-color: #fff;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    min-height: 3.5rem;
    text-align: center;
    border-radius: 0.3rem;

    &.active {
      background-color: #ffd415;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}
