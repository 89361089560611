



















.the-section {
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  padding-top: 3rem;
  margin-bottom: 3rem;
  > .title {
    color: #fefffe;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;

    > .sub-title {
      font-size: 1.3rem;
      color: rgba(254, 255, 254, 0.3);
      margin-left: 0.5rem;
    }
  }
}
