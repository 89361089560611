


















































.work-list {
  position: relative;
  min-height: 12.6rem;
  margin: 0rem 1rem;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  padding-top: 2.5rem;
  background-color: #fcffe2;
  border-radius: 1rem;
  &::before,
  &::after {
    content: '';
    position: absolute;
    height: 4.5rem;
    width: 17.2rem;
  }

  &::before {
    top: 0;
    left: 0;
    background-image: url('../../../../assets/activity/spring-voice/波纹1@3x.png');
    background-size: 100% 100%;
  }

  &::after {
    bottom: 0;
    right: 0;
    background-image: url('../../../../assets/activity/spring-voice/形状 14@3x.png');
    background-size: 100% 100%;
  }

  > .header {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 14.6rem;
    text-align: center;
    font-size: 1.7rem;
    font-weight: bold;
    color: #2e4024;
    background-image: url('../../../../assets/activity/spring-voice/音波@3x.png');
    background-size: 100% 1.3rem;
    background-repeat: no-repeat;
    background-position-y: 50%;
    margin-bottom: 1.6rem;
  }

  > .body {
    position: relative;
    z-index: 9;
    padding: 0 0.5rem;
    > .join-btn {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 17.6rem;
      height: 4.3rem;
      background-image: url('../../../../assets/activity/spring-voice/按钮@3x.png');
      background-size: 100% 100%;
      color: #fff;
      font-size: 1.7rem;
      font-weight: bold;
      padding-top: 0.5rem;
      text-align: center;
    }

    > .btn-link {
      font-size: 1.5rem;
      color: #e84600;
      text-decoration: underline;
      font-weight: bold;
      text-align: center;
      padding: 1.5rem 0;
      border-top: 1px solid rgba(0, 86, 60, 0.2);
    }
  }
}
