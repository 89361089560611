


























.tag-display-field {
  > .title {
    font-size: 1.8rem;
    font-weight: bold;
  }

  > .tag-list {
    font-size: 0;
    padding: 2rem 0;
    border-bottom: 1px solid rgba(232, 231, 231, 1);

    > .tag-item {
      position: relative;
      font-size: 1.4rem;
      display: inline-block;
      padding: 0.6rem 2rem;
      border-radius: 1.6rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      &.active {
        background-color: #ffeb6f;
      }

      > .close-btn {
        position: absolute;
        height: 1.5rem;
        width: 1.5rem;
        right: -0.4rem;
        top: -0.4rem;
      }
    }
  }
}
