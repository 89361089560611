.theHeader {
  position: relative;
  padding-top: 24.6rem;
  margin-bottom: 2.5rem;
  z-index: 99;
  &::before {
    position: absolute;
    left: 50%;
    top: 3.75rem;
    transform: translateX(-50%);
    content: '';
    height: 11.9rem;
    width: 28.7rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/workers-day/文案@2x.png');
    background-size: 100% 100%;
  }

  &::after {
    position: absolute;
    content: '';
    left: 0;
    right: 0;
    top: 11.15rem;
    height: 18.5rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/workers-day/组 38@2x.png');
    background-size: 100% 100%;
  }

  >.content {
    background-color: #E98AFF;
    border-radius: 1.5rem;
    border: 0.15rem solid #000;
    margin: 0 1.5rem;
    >.title {
      position: relative;
      padding-top: 3rem;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: 0;
        background: linear-gradient(90deg,  #FFF391, #FFC843);
        height: 0.9rem;
        width: 8.85rem;
        border-radius: 0.45rem;
      }

      >span {
        position: relative;
        z-index: 9;
      }
    }

    >.body {
      margin: 0 1.5rem;
      margin-top: 1.45rem;
      margin-bottom: 2.5rem;
      padding: 0 1rem;
      padding-top: 1.5rem;
      padding-bottom: 2.5rem;
      background-color: #fff;
      border-radius: 1rem;
      border: 0.15rem solid #000;

      >.list {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 2.5rem;
        >li {
          position: relative;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 9.4rem;
          height: 8rem;
          background-color: #9ED9FF;
          border-radius: 0.5rem;
          margin-bottom: 0.5rem;
          overflow: hidden;

          >img {
            width: 5rem;
          }
          
          &.active {
            &::after {
              content: '';
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.5);
            }

            &::before {
              content: '';
              position: absolute;
              height: 1.5rem;
              width: 1.5rem;
              background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/workers-day/Fill@3x.png');
              background-size: 100% 100%;
              z-index: 99;
            }
          }

          &:first-child {
            >img {
              width: 7rem;
              margin-bottom: 1.5rem;
            }
          }

          &:nth-child(4),
          &:nth-child(5) {
            width: 14.3rem;
          }

          &:last-child {
            background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/workers-day/放射.png');
            background-size: 100% 100%;
          }
        }
      }

      >.btn{
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;
        height: 4.5rem;
        line-height: 4.5rem;
        width: 24rem;
        margin: 0 2.55rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/workers-day/立即领取.png');
        background-size: 100% 100%;
      }
    }
  }
}