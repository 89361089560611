.the-desc {
  position: relative;
  z-index: 9;
  padding-top: 2.5rem;
  padding-bottom: 2.7rem;
  &::before {
    content: '';
    position: absolute;
    height: 7.5rem;
    width: 7.5rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/钻石@3x(1).png');
    background-size: 100% 100%;
    right: -1.5rem;
    top: -3.5rem;
  }

  &::after {
    content: '';
    position: absolute;
    height: 8.5rem;
    width: 8.5rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/2@3x.png');
    background-size: 100% 100%;
    left: -1.5rem;
    bottom: -4.3rem;
  }

  .title {
    text-align: center;
    font-size: 1.6rem;
    font-weight: bold;
    color: #fff;
    margin-bottom: 1.45rem;
  }

  > .price {
    width: 12.5rem;
    height: 2.45rem;
    background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/价值@3x.png);
    background-size: 100% 100%;
    font-size: 1.3rem;
    color: #fff;
    text-align: center;
    line-height: 2.45rem;
    font-weight: bold;
    margin: 0 auto;
    margin-bottom: 3.6rem;
  }

  > .list {
    display: flex;
    justify-content: center;
    margin-bottom: 3rem;
    > .item {
      position: relative;
      color: #212280;
      background-color: #fff;
      border-radius: 0.5rem;
      padding: 0 1rem;
      width: 10rem;
      text-align: center;
      margin-right: 1rem;

      &::before {
        content: '必得';
        position: absolute;
        top: -1.1rem;
        left: 0;
        height: 2.2rem;
        width: 4.8rem;
        background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/必得@3x.png);
        background-size: 100% 100%;
        line-height: 2.2rem;
        font-size: 1.2rem;
        font-weight: bold;
      }

      &:last-child {
        margin-right: 0;
      }

      > .top {
        padding-top: 2.7rem;
        padding-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.6rem;
        font-weight: bold;
        border-bottom: 1px solid rgba(33, 34, 128, 0.1);

        > .icon {
          display: inline-block;
          height: 2.3rem;
          width: 2.3rem;
          background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/钻石@3x.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin-right: 0.5rem;
        }
      }

      > .bottom {
        font-size: 1.3rem;
        padding: 1.6rem 0;
      }
    }
  }

  > .btn {
    position: relative;
    border-radius: 2.35rem;
    width: 26.75rem;
    height: 4.5rem;
    text-align: center;
    line-height: 4.5rem;
    margin: 0 auto;
    margin-bottom: 2.3rem;

    &::before {
      content: '立即呼唤好朋友';
      font-size: 1.4rem;
      font-weight: bold;
      position: absolute;
      left: 0;
      height: 100%;
      width: 100%;
      border-radius: 2.35rem;
      background: #c0bdbd linear-gradient(135deg, #ffeb6f 0%, #ffd416 100%);
      z-index: 99;
    }

    &::after {
      content: '';
      left: 0;
      top: 3px;
      position: absolute;
      border-radius: 2.35rem;
      width: 26.75rem;
      height: 4.5rem;
      background-color: #ff8a1c;
      z-index: 10;
    }
  }

  > .achievement {
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
  }

  > .achievement-list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    height: 4.5rem;
    border-radius: 0.5rem;
    background: linear-gradient(180deg, rgba(24, 155, 252, 0.5) 1%, rgba(50, 255, 144, 0.5) 100%);
    margin: 0 1.5rem;
    padding: 0 1.5rem;
    color: #fff;
    margin-top: 1rem;
    > .right {
      display: flex;
      align-items: center;
    }
    .icon {
      display: inline-block;
      height: 1.6rem;
      width: 1.6rem;
      background-size: 100% 100%;
      background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/钻石@3x.png);
      margin-right: 0.5rem;
    }
  }
}
