






















































.the-avatar-upload-container {
  /deep/ {
    .form-item-container {
      align-items: flex-start;
    }
    .van-uploader__upload {
      border-radius: 0.5rem;
    }

    .form-item-container {
      border-bottom: none;
    }
  }

  > .extra {
    font-size: 1.1rem;
    color: #ff7b52;

    > p {
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
}
