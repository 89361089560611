



















.page-section {
  position: relative;
  margin: 0 1rem;
  padding-top: 3rem;
  margin-bottom: 2.4rem;

  > .header {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 5rem;
    font-size: 1.7rem;
    font-weight: bold;
    color: #ffffe3;
    background-image: url('../../../assets/activity/spring-voice/1111@3x.png');
    background-size: 100% 100%;
    text-align: center;
    padding-top: 0.5rem;
  }

  .body {
    position: relative;
    // z-index: 1;
    border-radius: 1rem;
    overflow: hidden;
    background-image: url('../../../assets/activity/spring-voice/格纹@3x.png');
    background-size: 100% 5rem;
  }
}
