































































.the-relationship-entries {
  padding-top: 2rem;
  > .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 2rem;

    > .about {
      display: flex;
      align-items: center;
      font-size: 1.3rem;
      color: #7d7d7d;

      > .icon {
        display: inline-block;
        height: 1.1rem;
        width: 1.1rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/intimate/grzx_go@3x.png');
        background-size: 100% 100%;
        margin-right: 2px;
      }
    }
  }

  > .list {
    display: flex;
    flex-wrap: wrap;

    > .empty {
      padding-top: 1.5rem;
      width: 10.8rem;
      height: 13.5rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/intimate/cp@3x.png');
      background-size: 100% 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      font-size: 1.3rem;
      > .dot {
        margin-bottom: 0.65rem;
      }
      > img {
        height: 4.5rem;
        width: 4.5rem;
        margin-bottom: 1.2rem;
      }
    }

    > .buy-item-btn {
      text-align: center;
      width: 10.8rem;
      height: 13.5rem;
      background-color: #fafafa;
      border: 1px solid #f3f3f3;
      border-radius: 0.5rem;
      padding-top: 1.5rem;
      > img {
        height: 4.5rem;
        width: 4.5rem;
        margin-bottom: 1rem;
      }

      > .title {
        font-size: 1.2rem;
        color: #7d7d7d;
      }
    }
  }
}
