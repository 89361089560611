
































































































































































.group-desc-page {
  padding: 1.5rem;
  .pd-05 {
    padding: 0.5rem 0;
  }

  .li {
    position: relative;
    padding-left: 1rem;
    &::before {
      position: absolute;
      top: 0.8rem;
      left: 0;
      content: '';
      height: 0.4rem;
      width: 0.4rem;
      background-color: #1a1a1a;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .section {
    margin-bottom: 2.5rem;
    > .title {
      font-size: 1.6rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }

    > .desc {
      font-size: 1.4rem;

      > .subtitle {
        font-weight: bold;
        margin: 0.5rem 0;
      }

      .red {
        color: red;
      }

      > .content {
        padding: 1rem 0;
      }
    }
  }

  .level-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #e8e7e7;
    text-align: center;
    margin: 1rem 0;
    th {
      height: 2.5rem;
      line-height: 2.5rem;
      font-size: 1.2rem;
      background-color: #ffd415;
      border-right: 1px solid #e8e7e7;
      &:last-child {
        border-right: none;
      }
    }

    td {
      height: 4rem;
      border-bottom: 1px solid #e8e7e7;
      border-right: 1px solid #e8e7e7;
    }
  }
}
