













.the-main-container {
  height: 100%;
  width: calc(100vw - 10rem);
}
