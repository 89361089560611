.theMiddle {
  margin-bottom: 1.5rem;
  >.header {
    padding: 0 2.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    >.item {
      position: relative;
      font-size: 1.5rem;
      font-weight: bold;
      height: 4rem;
      width: 15.35rem;
      background-color: #fff;
      border-radius: 2rem;
      border: 0.15rem solid #000;
      line-height: 4rem;
      text-align: center;
      &.active {
        background-color: #FEDB60;
        &::after {
          content: '';
          position: absolute;
          border-top: #000 solid 0.7rem;
          border-left: transparent solid 0.6rem;
          border-right: transparent solid 0.6rem;
          bottom: -0.8rem;
          left: 50%;
          transform: translateX(-50%);
        }

        &::before {
          content: '';
          position: absolute;
          z-index: 9;
          border-top: #FEDB60 solid 0.55rem;
          border-left: transparent solid 0.45rem;
          border-right: transparent solid 0.45rem;
          bottom: -0.6rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  >.panel {
    background-color: #E98AFF;
    border: 0.15rem solid #000;
    border-radius: 1.5rem;
    padding: 0 1.7rem;
    padding-top: 3rem;
    padding-bottom: 2.5rem;
    margin: 0 1.5rem;

    >.header {
      position: relative;
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      margin-bottom: 1.7rem;
      &::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 0;
        z-index: 0;
        background: linear-gradient(90deg,  #FFF391, #FFC843);
        height: 0.9rem;
        width: 8.85rem;
        border-radius: 0.45rem;
      }

      >span {
        position: relative;
        z-index: 9;
      }
    }

    >.top {
      position: relative;
      font-size: 1.4rem;
      font-weight: bold;
      text-align: center;
      padding: 3rem 0;
      border: 0.15rem solid #000;
      border-radius: 1rem;
      background-color: #fff;
      margin-bottom: 1.5rem;

      &::before {
        content: '';
        position: absolute;
        height: 6.85rem;
        width: 7.25rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/workers-day/星球2@2x.png');
        background-size: 100% 100%;
        top: -2rem;
        left: 26.75rem;
      }

      >.row {
        margin-bottom: 1.4rem;
      }

      >.date {
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/workers-day/矩形 2@2x.png');
        height: 3.2rem;
        background-size: 100% 100%;
        line-height: 3.2rem;
      }
    }

    >.bottom {
      background-color: #fff;
      border: 0.15rem solid #000;
      border-radius: 1rem;
      padding: 3.05rem 0;
      >.swiper-container {
        height: 16rem;
        >.swiper-wrapper.center {
          justify-content: center;
        }
        .swiper-slide {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          position: relative;
          width: 24rem;
          background-color: #E98AFF;
          border-radius: 1rem;
      
          > img {
            height: 10rem;
            border-radius: 1rem;
            margin-bottom: 0.5rem;
            // height: 100%;
            // width: 100%;
          }
      
          > .title {
            font-size: 1.3rem;
            color: #ffffe3;
            // position: absolute;
            bottom: 3rem;
            width: 100%;
            text-align: center;
            font-weight: bold;
          }
        }
      }
    }
  }
}