.web-view-layout {
  > .header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1999;
    box-sizing: content-box;
    padding: 0 1.5rem;
    min-height: 3.6rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    > .left {
      flex: 1;
      > .back-icon {
        height: 2.5rem;
        width: 2.5rem;
      }
    }

    > .middle {
      // flex: 1;
      text-align: center;
      font-size: 1.7rem;
      font-weight: bold;
    }

    > .right {
      display: flex;
      justify-content: flex-end;
      flex: 1;
      font-size: 1.4rem;
      font-weight: 500;
      text-align: right;
    }
  }

  > .app-layout-body {
    position: relative;
    height: 100vh;
    overflow: scroll;
  }
}
