









































































.the-tag-select-container {
  align-items: flex-start;
  /deep/ {
    .label {
      padding-top: 0.5rem;
    }
    .content {
      display: flex;
      flex-wrap: wrap;

      > .tag {
        width: 8rem;
        height: 3.3rem;
        line-height: 3.3rem;
        text-align: center;
        background-color: #fafafa;
        font-size: 1.3rem;
        border-radius: 1.75rem;
        margin-right: 0.7rem;
        margin-bottom: 1rem;

        &.active {
          background-color: #ffeb6f;
        }
      }
    }
  }

  .add-tag-btn {
    width: 3.2rem;
    height: 3.2rem;
  }
}
