































.the-desc {
  color: #fff;
  > .header {
    font-size: 1.6rem;
    font-weight: bold;
    text-align: center;
    background-image: url('../../../../assets/webview/treasure-chest-desc/装饰@3x.png');
    background-size: 17.3rem 0.4rem;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-bottom: 2.4rem;
  }
  > div {
    font-size: 1.2rem;
    margin-bottom: 1.3rem;
  }
}
