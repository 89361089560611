.thePlayList {
  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    border-bottom: 1px solid #f3f3f3;
    margin-bottom: 2.5rem;
    > .left {
      display: flex;
      align-items: center;
      > span:first-child {
        font-size: 1.6rem;
        font-weight: bold;
        margin-right: 2rem;
      }

      > span:last-child {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }

    > .right {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      > img {
        width: 1.3rem;
        height: 1.3rem;
        margin-left: 0.5rem;
      }
    }
  }

  > .listItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2.5rem;
    &:last-child {
      padding-bottom: 9rem;
    }
    > .left {
      display: flex;
      align-items: center;
      > .content {
        width: 16.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        > .title {
          font-size: 1.5rem;
          font-weight: bold;
        }

        > .subtitle {
          font-size: 1.1rem;
          color: #7d7d7d;

          > span {
            margin-right: 0.5rem;
          }
        }
      }
      > .avatar {
        height: 6rem;
        width: 6rem;
        border-radius: 0.5rem;
        margin-right: 1.6rem;
      }
    }

    > .playBtn {
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}
