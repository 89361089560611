
































































































































.rank-list-item {
  width: 11.2rem;

  margin-right: 1rem;
  box-shadow: 0px 2px 14px 0px rgba(0, 161, 109, 0.5);
  margin-bottom: 1rem;

  &:nth-child(3n) {
    margin-right: 0;
  }
  > .header {
    position: relative;
    height: 11.2rem;

    > .avatar {
      height: 100%;
      width: 100%;
      border-radius: 1rem 1rem 0 0;
    }

    > .icon {
      position: absolute;
      z-index: 99;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 4rem;
      width: 4rem;
    }

    > .flag {
      position: absolute;
      z-index: 99;
      right: 0;
      bottom: 0;
      font-size: 1rem;
      color: #fcffe2;
      height: 1.7rem;
      width: 3.5rem;
      text-align: center;
      line-height: 1.7rem;
      background-color: rgba(252, 255, 226, 0.3);
      border-radius: 0.78rem 0 0 0;
    }

    > .follow-btn {
      position: absolute;
      z-index: 999;
      top: 1.1rem;
      right: -0.5rem;
      height: 2.2rem;
      width: 4.6rem;
      background-image: url('../../../../assets/activity/spring-voice/关注@3x.png');
      background-size: 100% 100%;
      color: #fff;
      font-size: 1.1rem;
      text-align: center;
      line-height: 2.5rem;
      font-weight: bold;
    }

    &::after {
      content: '';
      position: absolute;
      background-color: rgba(0, 0, 0, 0.4);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 1rem 1rem 0 0;
    }
  }

  > .body {
    background-color: #fcffe2;
    padding-bottom: 1.2rem;
    border-radius: 0 0 1rem 1rem;
    > .title {
      display: flex;
      justify-content: space-between;
      padding: 0 0.5rem;
      padding-top: 0.75rem;
      margin-bottom: 0.6rem;
      font-size: 1.1rem;
      font-weight: bold;

      &.rank {
        margin-bottom: 1.45rem;
      }

      .name {
        margin: 0;
        flex: 1;
        padding-right: 1rem;
        color: #2e4024;
      }

      .ticket {
        color: #e84600;
      }
    }

    .subtitle {
      font-size: 1rem;
      color: rgba(46, 64, 36, 0.8);
      padding-left: 0.5rem;
      margin-bottom: 0.9rem;
    }

    .set-btn {
      color: #00563c;
      font-size: 1.1rem;
      font-weight: bold;
      height: 2.5rem;
      line-height: 2.5rem;
      text-align: center;
      width: 9rem;
      border-radius: 1.25rem;
      margin: 0 1.1rem;
      background: linear-gradient(90deg, #fdca24, #fceb43);
    }

    > .btn {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      background: linear-gradient(90deg, #ff9e33, #fc7000);
      height: 2.5rem;
      width: 9rem;
      line-height: 2.5rem;
      color: #fff;
      font-weight: bold;
      font-size: 1.1rem;
      text-align: center;
      border-radius: 1.25rem;
      margin-bottom: 1.1rem;
    }

    > .link {
      font-size: 1rem;
      font-weight: bold;
      color: #00563c;
      text-align: center;
      text-decoration: underline;
    }
  }
}
