.content /deep/ .ql-size-small {
  font-size: 12px;
}
.content /deep/ .ql-size-large {
  font-size: 16px;
}
.content /deep/ .ql-size-huge {
  font-size: 32px;
}

/* 对齐方式 */

.content /deep/ .ql-align-center {
  text-align: center;
}
.content /deep/ .ql-align-justify {
  text-align: justify;
}
.content /deep/ .ql-align-right {
  text-align: right;
}

/* 缩进 */
.content /deep/ .ql-indent-1 {
  padding-left: 3em;
}
.content /deep/ .ql-indent-2 {
  padding-left: 6em;
}
.content /deep/ .ql-indent-3 {
  padding-left: 9em;
}
.content /deep/ .ql-indent-4 {
  padding-left: 12em;
}
.content /deep/ .ql-indent-5 {
  padding-left: 15em;
}
.content /deep/ .ql-indent-6 {
  padding-left: 18em;
}

.content /deep/ .color16_0 {
  color: black;
}
.content /deep/ .color16_1 {
  color: #f08245;
}
.content /deep/ .color16_2 {
  color: #b68900;
}
.content /deep/ .color16_3 {
  color: silver;
}
.content /deep/ .color16_4 {
  color: #009900;
}
.content /deep/ .color16_5 {
  color: #bc1092;
}
.content /deep/ .color16_6 {
  color: olive;
}
.content /deep/ .color16_7 {
  color: gray;
}
.content /deep/ .color16_8 {
  color: #f01645;
}
.content /deep/ .color16_9 {
  color: maroon;
}
.content /deep/ .color16_10 {
  color: navy;
}
.content /deep/ .color16_11 {
  color: #f05050;
}
.content /deep/ .color16_12 {
  color: #0033cc;
}
.content /deep/ .color16_13 {
  color: purple;
}
.content /deep/ .color16_14 {
  color: teal;
}
.content /deep/ .color16_15 {
  color: #ff66cc;
}
.content /deep/ .color16_16 {
  color: aqua;
}
/*新的颜色体系*/
.content /deep/ .c1 {
  color: #cc0033;
} /*红色*/
.content /deep/ .c2 {
  color: #ff9933;
} /*橙色*/
.content /deep/ .c3 {
  color: #99cc33;
} /*黄绿*/
.content /deep/ .c4 {
  color: #006633;
} /*绿色*/
.content /deep/ .c5 {
  color: #339999;
} /*青绿*/
.content /deep/ .c6 {
  color: #006699;
} /*蓝色*/
.content /deep/ .c7 {
  color: #333366;
} /*蓝紫*/
.content /deep/ .c8 {
  color: #663366;
} /*紫色*/
.content /deep/ .c9 {
  color: #990033;
} /*紫红*/
.content /deep/ .c0 {
  color: #333;
} /*灰色*/


.content.article-content {
  font-size: 1.5rem !important;
  line-height: 2.75;
  hr {
    border: none;
    height: 1px;
    background-color: #f3f6f9;
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: 1.5rem !important;
  }

  h1 {
    * {
      font-size: 2.8rem !important;
    }
  }

  h2 {
    * {
      font-size: 2rem !important;
    }
  }

  p {
    font-size: 1.5rem !important;
    line-height: 28px;

    span {
      font-size: 1.5rem !important;
    }
  }

  table {
    max-width: calc(100vw - 3rem);
  }

  td {
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    span {
      font-size: 1.5rem !important;
    }
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }
}