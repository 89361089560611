























































.swiper-wrapper {
  height: auto;
  > .swiper-slide {
    height: auto;
  }
}
.the-role-list {
  .role-list {
    // height: 16rem;
    padding: 1rem 0;
  }
  .role-item {
    background-color: #fff;
    box-shadow: 0px 0.1rem 0.8rem 0px rgba(0, 0, 0, 0.05);
    border-radius: 0.5rem;
    padding: 1rem;
    max-height: 15rem;

    .desc {
      font-size: 1.1rem;
      color: #7d7d7d;
    }

    > .top {
      display: flex;
      > .avatar {
        height: 5rem;
        width: 5rem;
        margin-right: 1.1rem;

        > img {
          height: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }

      .name {
        font-size: 1.3rem;
        font-weight: bold;
      }

      .extra {
        > span {
          margin-right: 1rem;
        }
      }
    }
  }
}
