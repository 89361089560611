.list {
  padding: 0 1.5rem;
  .item {
    padding: 2rem 0;
    border-bottom: 1px solid rgba(232, 231, 231, 0.5);
    > .title {
      display: flex;
      justify-content: space-between;
      font-size: 1.5rem;
      align-items: center;
      margin-bottom: 0.5rem;

      > .num {
        font-size: 1.8rem;
        font-weight: bold;
      }
    }

    > .time {
      color: #c0bdbd;
      font-size: 1.3rem;
    }
  }
}
