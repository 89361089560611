





















.editor-item {
  width: 7.5rem;
  margin-right: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;

  &:nth-child(4n) {
    margin-right: 0;
  }

  > .name {
    font-size: 1.5rem;
  }

  > .tags {
    font-size: 1.1rem;
    color: #7d7d7d;
    margin-bottom: 1rem;
  }

  > .group {
    font-size: 1.2rem;
  }

  > .avatar {
    height: 7.5rem;
    width: 7.5rem;
    border-radius: 50%;
    margin-bottom: 1rem;
    object-fit: cover;
  }
}
