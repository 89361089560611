.page {
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank/底图@3x.png');
  background-size: 100% 39.45rem;
  padding: 0 1.5rem;
  padding-top: 26rem;
  background-repeat: no-repeat;
  background-color: #7740fd;
  padding-bottom: 9.5rem;

  .section {
    .body {
      padding-left: 1.5rem;
      padding-top: 4rem;
      padding-bottom: 2.5rem;
      padding-right: 3rem;
      color: #fff;
      font-size: 1.2rem;
      font-weight: bold;

      > .row {
        display: flex;
        margin-bottom: 1.5rem;

        > div:last-child {
          margin-left: 0.7rem;
        }
      }
    }
  }

  > .userInfo {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #976dfc;
    border-radius: 1.5rem 1.5rem 0 0;
    height: 9.5rem;
    color: #fff;
    display: flex;
    align-items: center;
    padding-left: 2.5rem;

    > .rank {
      font-size: 1.3rem;
      width: 4rem;
    }
    > img {
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 50%;
      margin-right: 1.5rem;
      margin-left: 1.5rem;
    }

    > .right {
      > .nickname {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 1rem;
      }

      > .subtitle {
        font-size: 1.2rem;
        > span {
          margin-right: 1.5rem;
        }
      }
    }
  }
}
