










































.form {
  display: flex;
  flex-direction: column;
  margin-top: 5rem;
  margin-bottom: 1.5rem;

  >input {
    font-size: 1.4rem;
    padding: 0 3rem;
    border: 1px solid #DCDFE3;
    height: 4.5rem;
    line-height: 4.5rem;
    outline: none;
    background-color: #FAFAFA;
    border-radius: 2.25rem;
    margin-bottom: 2rem;
  }
}
