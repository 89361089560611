






























.the-probability {
  color: #200085;
  font-size: 1.2rem;
  padding: 0 1rem;
  padding-top: 1.3rem;
  padding-bottom: 2rem;

  table {
    width: 100%;
    border: 1px solid rgba(118, 61, 255, 0.5);
    border-radius: 0.5rem;
    tr {
      > td:last-child,
      > th:last-child {
        border-right: 0;
        padding: 0 1rem;
      }
    }
    > tbody {
      > tr:last-child {
        > td {
          border-bottom: none;
        }
      }
    }
  }

  th,
  td {
    padding: 1rem 0;
    text-align: center;
    border-right: 1px solid rgba(118, 61, 255, 0.5);
    border-bottom: 1px solid rgba(118, 61, 255, 0.5);
  }
}
