.page {
  min-height: 100vh;
  background: linear-gradient(135deg, #567aff 0%, #994fff 100%);
  // padding: 0 1.5rem;
  > .header {
    position: relative;
    height: 8rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room-chest/标题@3x.png');
    background-size: 24.2rem 5.3rem;
    background-position: center;
    background-repeat: no-repeat;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    margin: 0 1.5rem;

    > .close {
      position: absolute;
      right: 1rem;
      top: 2.2rem;
      height: 20px;
      width: 20px;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room-chest/sz_delete@3x.png');
      background-size: 12px 12px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  >.body {
    height: calc(100vh - 8rem);
    overflow-y: scroll;

    >.rankGroup {
      
      >.title {
        font-size: 1.5rem;
        text-align: center;
        padding: 2rem 0;
        color: #fff;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }

      > .rankList {
        
        > .rankItem {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin: 0 1.5rem;
    
          > .number {
            width: 1.9rem;
            text-align: center;
            font-size: 1.4rem;
            color: #fff;
            > img {
              height: 1.9rem;
              width: 1.9rem;
            }
          }
    
          > .content {
            display: flex;
            flex: 1;
            // width: 31.8rem;
            justify-content: space-between;
            align-items: center;
            padding: 1.5rem 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
            > .left {
              display: flex;
              > .avatar {
                height: 4.5rem;
                width: 4.5rem;
                border-radius: 50%;
                margin-right: 1.8rem;
              }
    
              > .info {
                > .name {
                  font-size: 1.4rem;
                  color: #fff;
                  margin-bottom: 0.9rem !important;
                }
    
                > .extra {
                  display: flex;
                  align-items: center;
    
                  > .vip {
                    display: inline-block;
                    height: 1.6rem;
                    width: 1.6rem;
                    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room-chest/vip.png');
                    background-size: 100% 100%;
                  }
    
                  > .genderIcon {
                    display: inline-block;
                    height: 1.4rem;
                    width: 1.4rem;
                    border-radius: 50%;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 0.9rem 0.9rem;
                    margin-right: 0.5rem;
    
                    &.male {
                      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room-chest/news_man@3x(1).png');
                      background-color: #63c1ff;
                    }
    
                    &.female {
                      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room-chest/news_woman@3x(1).png');
                      background-color: #fe89e9;
                    }
                  }
                }
              }
            }
    
            > .right {
              display: flex;
              > .item {
                position: relative;
                height: 4rem;
                width: 4rem;
                border-radius: 0.5rem;
    
                &:last-child {
                  margin-left: 1.5rem;
                }
    
                > .count {
                  // position: absolute;
                  // left: 3.2rem;
                  // bottom: 2.75rem;
                  height: 1.5rem;
                  min-width: 1.5rem;
                  line-height: 1.5rem;
                  text-align: center;
                  border-radius: 50%;
                  background-color: #89a3ff;
                  color: #fff;
                  font-size: 0.9rem;
                }
    
                > .avatar {
                  height: 100%;
                  width: 100%;
                  border-radius: 0.5rem;
                }
              }
            }
  
            >.date {
              font-size: 1.3rem;
              color: #fff;
            }
          }
        }
      }
    }
  } 
}
