.page {
  position: relative;
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/author-center/love-rank/背景@3x.png');
  background-size: 100% 30rem;
  background-repeat: no-repeat;

  :global {
    .van-popup {
      background-color: transparent;
    }
  }

  .swiperContainer {
    .swiperSlide {
      position: relative;
      height: 100vh;
      overflow-y: scroll;
    }
  }

  .theHeader {
    position: absolute;
    top: 0;
    left: 5rem;
    right: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
    // padding: 0 5rem;
    padding-top: 11.1rem;
    > .left {
      position: relative;
      > .avatarWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 10rem;
        width: 10rem;
        border-radius: 50%;
        background: linear-gradient(
          180deg,
          rgba(247, 168, 255, 1),
          rgba(246, 212, 255, 1),
          rgba(248, 178, 255, 1)
        );

        > img {
          height: 9.7rem;
          width: 9.7rem;
          border-radius: 50%;
        }
      }

      > .icon {
        position: absolute;
        top: 8.2rem;
        left: 50%;
        transform: translateX(-50%);
        height: 2.9rem;
        width: 4.3rem;
      }
    }

    > .right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > .nickname {
        font-size: 1.6rem;
        margin-bottom: 0.8rem;
      }

      > .desc {
        font-size: 1.2rem;
        color: rgba(255, 255, 255, 0.7);
        margin-bottom: 1.5rem !important;
      }

      > .count {
        background-color: #fff;
        border-radius: 1.25rem;
        color: #af8aff;
        width: 6.6rem;
        height: 2.5rem;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          height: 1.5rem;
          width: 1.5rem;
          margin-right: 0.5rem;
        }
      }
    }
  }

  .rankList {
    padding-bottom: 9rem;
    margin-top: 28.5rem;
    min-height: calc(100vh - 28.5rem);
    background-color: #fff;
    border-radius: 1.5rem 1.5rem 0 0;
    padding-right: 1.5rem;

    .rankItem {
      display: flex;
      align-items: center;

      > .rank {
        width: 5.2rem;
        text-align: center;
        font-size: 1.6rem;
        font-weight: bold;
      }

      > .right {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        border-bottom: 1px solid rgba(243, 243, 243, 0.5);
        padding: 2.5rem 0;

        > .info {
          display: flex;
          align-items: center;

          > .avatar {
            height: 3.7rem;
            width: 3.7rem;
            border-radius: 50%;
            margin-right: 1.5rem;
          }

          .nickname {
            font-size: 1.5rem;
            font-weight: bold;
            width: 10rem;
          }

          .desc {
            color: #c0bdbd;
            font-size: 1.2rem;
            width: 10rem;
          }
        }

        > .count {
          display: flex;
          align-items: center;
          color: #af8aff;
          font-size: 1.1rem;
          > img {
            height: 1.2rem;
            width: 1.2rem;
            margin-left: 0.5rem;
          }
        }
      }
    }
  }

  .userinfo {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
    height: 9rem;
    padding-right: 1.5rem;
    border-radius: 1.5rem 1.5rem 0px 0px;

    > .left {
      width: 5rem;
      text-align: center;
      font-size: 1.6rem;
      font-weight: bold;

      > span {
        font-size: 1.3rem;
      }
    }

    > .right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      > .info {
        display: flex;
        align-items: center;
        > .avatar {
          height: 3.7rem;
          width: 3.7rem;
          border-radius: 50%;
          margin-right: 1.5rem;
        }

        .nickname {
          font-size: 1.5rem;
        }

        .count {
          color: #7d7d7d;
          font-size: 1.2rem;
        }
      }
      > .btn {
        height: 2.6rem;
        width: 6.7rem;
        line-height: 2.6rem;
        text-align: center;
        border-radius: 1.5rem;
        font-size: 1.3rem;
        background-color: #ffd415;
        > img {
          height: 1.1rem;
          width: 1.1rem;
          margin-right: 0.5rem;
        }
      }
    }
  }
}

.middle {
  font-size: 1.3rem;
  color: rgba(255, 255, 255, 0.7);

  > span {
    margin-right: 2rem;

    &.active {
      color: #fff;
      font-size: 1.6rem;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}

.rightIcon {
  height: 2.5rem;
  width: 2.5rem;
}
