


















.normal-reward {
  background-color: #ffffe3;
  height: 29.2rem;
  padding-top: 2rem;

  > .title {
    position: relative;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    z-index: 99;
    margin-bottom: 1.2rem;
    color: #2e4024;

    &::after {
      position: absolute;
      content: '';
      background: linear-gradient(90deg, #fceb43, #fdca24);
      height: 0.5rem;
      width: 12rem;
      bottom: 2px;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
    }
  }

  > .sub-title {
    font-size: 1.3rem;
    color: #2e4024;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.8rem;
  }

  > .content {
    position: relative;
    margin: 0 2.5rem;
    height: 17.5rem;
    background-color: #009e6d;
    border-radius: 1rem;
    text-align: center;
    > img {
      height: 100%;
    }

    > .title {
      font-size: 1.3rem;
      color: #ffffe3;
      position: absolute;
      bottom: 3rem;
      width: 100%;
      text-align: center;
      font-weight: bold;
    }
  }
}
