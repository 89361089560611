


































































































































































.ost-edit-page-container {
  /deep/ {
    .app-layout-body {
      padding: 0 1.5rem;
    }
  }
}
