






























































.sign-out-btn {
  height: 4.5rem;
  line-height: 4.5rem;
  text-align: center;
  border-radius: 2.1rem;
  color: #1a1a1a;
  font-size: 1.6rem;
  background-color: #ffd300;
  margin: 0 1.5rem;
  margin-bottom: 2rem;
  margin-top: 10rem;
  &:active {
    opacity: 0.9;
  }
}

.body {
  padding-top: 1.5rem;
}
