

























































































































.the-role-eidtor-container {
  padding: 0 1.5rem;
  border-radius: 1.5rem 1.5rem 0 0;
  .header {
    font-size: 1.4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.15rem 0;
    border-bottom: 1px solid rgba(232, 231, 231, 0.8);
    > .cancel-btn {
      color: #7d7d7d;
    }

    > .title {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  > .sex-row {
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    padding: 3rem 0;
    border-bottom: 1px solid rgba(232, 231, 231, 0.8);

    > .icon-wrapper {
      font-size: 0;
      > img {
        height: 2.2rem;
        width: 2.2rem;
        margin-right: 1.5rem;
      }
    }

    > span {
      margin-right: 3.5rem;
    }
  }

  > .input-row {
    padding: 2rem 0;
    border-bottom: 1px solid rgba(232, 231, 231, 0.8);
    > input {
      font-size: 1.4rem;
      width: 100%;
      // color: #c0bdbd;

      &::placeholder {
        color: #c0bdbd;
      }
    }
  }

  > .upload-field {
    display: flex;
    align-items: center;
    padding-top: 1.5rem;
    .avatar-uploader {
      margin-right: 1.5rem;
      /deep/ {
        .van-uploader__upload {
          border-radius: 50%;
          margin: 0;
        }

        .van-uploader__preview-image {
          border-radius: 50%;
        }
      }
    }

    .title {
      font-size: 1.4rem;
      font-weight: bold;
      margin-bottom: 1.2rem;
    }

    .desc {
      font-size: 1.1rem;
      color: #c0bdbd;
    }
  }
}
