.the-welfare {
  > .chest {
    position: relative;
    height: 15.8rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/光束@3x.png');
    background-size: 15.8rem 100%;
    background-position: center;
    background-repeat: no-repeat;
    &::after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      content: '';
      height: 10rem;
      width: 10rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/燃将宝箱@3x.png');
      background-size: 100% 100%;
    }
  }

  > .btn-group {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-bottom: 2.5rem;
    > .left {
      margin-right: 1.5rem;
    }

    >.right {
      >.title {
        >img {
          width: 1.8rem !important;
        }
      }
    }
    > .left,
    > .right {
      > .title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(253, 249, 238, 0.7);
        font-size: 1.2rem;
        margin-bottom: 1rem;
        > img {
          height: 1.7rem;
          width: 1.3rem;
          margin-right: 0.55rem;
          font-size: 0;
        }
      }

      > .btn {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fdf9ee;
        background-color: #b05424;
        height: 2.6rem;
        line-height: 2.6rem;
        width: 8rem;
        border-radius: 1.4rem;
        font-size: 1.3rem;

        > img {
          height: 1.1rem;
          width: 1.1rem;
          margin-left: 0.4rem;
          font-size: 0;
        }
      }
    }
  }

  > .gift-list {
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid rgba(252, 248, 234, 0.1);
    padding: 0 1.25rem;
    padding-top: 2.5rem;

    > .gift-list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 4rem;
      margin-bottom: 2.5rem;
      &:nth-child(2n) {
        margin-right: 0;
      }

      > .wrapper {
        padding-bottom: 1.3rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: flex-end;
        background-color: #3e1304;
        border-radius: 0.5rem;
        width: 12rem;
        height: 12.5rem;
        margin-bottom: 1rem;
        > .name {
          font-size: 1.2rem;
          color: rgba(253, 249, 238, 0.7);
        }
        > img {
          height: 7rem;
          width: 7rem;
          margin-bottom: 1.2rem;
          object-fit: contain;
        }
      }

      > .title {
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(253, 249, 238, 0.7);
        font-size: 1.2rem;
        margin-bottom: 0.85rem;
        > img {
          width: 1.3rem;
          height: 1.7rem;
          margin-right: 0.55rem;
        }
      }

      > .btn {
        background-color: #b05424;
        color: #fdf9ee;
        font-size: 1.3rem;
        height: 2.6rem;
        width: 10rem;
        border-radius: 1.3rem;
        text-align: center;
        line-height: 2.6rem;
      }
    }
  }
}