





































.change-btn {
  font-size: 1.3rem;
  color: #1a1a1a;
}

.body {
  padding: 0 1.5rem;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2.5rem;
}
