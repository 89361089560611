.container {
  display: flex;
  align-items: flex-start;
  > .left {
    width: 8rem;
    > .left-menu {
      font-size: 1.3rem;
      width: 8rem;
      text-align: center;

      &.fixed {
        position: fixed;
        left: 0;
      }

      > .item {
        background-color: #fafafa;
        padding: 1.8rem 0;
        margin-bottom: 1px;

        &.active {
          background-color: #ffffff;
        }
      }
    }
  }

  > .right {
    background-color: #fff;
    flex: 1;
    min-height: calc(100vh - 24rem);
    border-left: 1px solid rgba(232, 231, 231, 0.8);
    overflow: hidden;

    > .header {
      height: 5.4rem;
      > .header-content {
        padding: 1.9rem 1.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.1rem;
        background-color: #fff;

        &.fixed {
          position: fixed;
          left: 8rem;
          right: 0;
          z-index: 999;
        }

        > .last {
          display: flex;
          align-items: center;
          color: #c0bdbd;

          > .icon {
            height: 1.2rem;
            width: 1.2rem;
            margin-left: 0.5rem;
          }
        }

        > .sort {
          display: flex;
          color: #7d7d7d;
          > div {
            margin-right: 2rem;
            &.active {
              color: #ff6536;
              font-weight: bold;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    > .list {
      position: relative;
      padding: 0 1.5rem;
    }
  }
}
