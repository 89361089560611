
































.task-list-item-container {
  display: flex;
  justify-content: space-between;
  color: #ffe8c9;
  border-bottom: 1px solid rgba(255, 247, 236, 0.2);
  padding: 1.4rem 0;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }

  .title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .sub-title {
    font-size: 1.2rem;
  }

  p {
    margin: 0;
  }

  .btn {
    display: inline-block;
    height: 2.5rem;
    width: 6rem;
    line-height: 2.5rem;
    text-align: center;
    border-radius: 0.5rem;
    font-weight: bold;
    font-size: 1.4rem;
    color: #971b0c;
    &.uncomplete {
      background-color: rgba(255, 232, 201, 0.8);
    }

    &.complete {
      background-color: rgba(255, 232, 201, 0.3);
    }

    &.unreceive {
      background: linear-gradient(0deg, #f8ba7b, #fddea4);
    }
  }
}
