.page {
  background-color: #fafafa;
  .container {
    display: flex;
    align-items: center;
    margin: 0 4rem;
    justify-content: space-between;
    padding-top: 1rem;
    > .box {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #fff;
      width: 12.7rem;
      height: 15rem;
      font-size: 1.4rem;
      text-align: center;
      box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.03);
      border-radius: 1rem;
      > img {
        height: 6rem;
        width: 6rem;
        margin-bottom: 1rem;
      }
    }
  }
}
