@font-face {
  font-family: shouke;
  src: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/fonts/字魂95号-手刻宋.ttf');
}

.modal-body {
  text-align: center;
  padding: 1.4rem 0;
  > .title {
    font-size: 1.4rem;
    color: #999;
    margin-bottom: 1rem;
    text-align: center;
  }

  .input {
    border: 1px solid #ddd;
    border-radius: 2px;
  }
}

.about-icon {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  background-size: 100% 100%;

  > .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 0.5rem;
    width: 0.5rem;
    border-radius: 50%;
    background-color: #fff;
    &::after,
    &::before {
      position: absolute;
      content: '';
      background-color: #fff;
      height: 0.5rem;
      width: 0.5rem;
      border-radius: 50%;
    }

    &::after {
      right: -1rem;
    }

    &::before {
      left: -1rem;
    }
  }

  > .action-list {
    font-size: 1.4rem;
    position: absolute;
    /* height: 8.9rem; */
    width: 10rem;
    background-color: #fff;
    border-radius: 0.5rem;
    border-top-right-radius: 0;
    right: 0;
    top: 4.3rem;
    text-align: center;
    padding: 0 1rem;
    transition: all 1s;
    /* overflow: hidden; */
    &::after {
      position: absolute;
      content: '';
      border-bottom: 0.5rem solid #fff;
      border-right: 0.6rem solid #fff;
      border-left: 0.6rem solid transparent;
      border-top: 0.5rem solid transparent;
      top: -1rem;
      right: 0;
    }

    > .item {
      padding: 1.3rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(232, 231, 231, 1);
      &:last-child {
        border: none;
      }
    }
  }
}

.qixi-page {
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/qixi-day/背景.png');
  background-size: 100% 100%;
  padding-top: 17.65rem !important;
  padding-bottom: 4.8rem;
  height: 100vh;

  .info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    // padding: 0 10.7rem;

    > .middle {
      height: 2rem;
      width: 2.4rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/qixi-day/爱心@2x.png');
      background-size: 100% 100%;
      position: relative;
      // font-size: 1.7rem;
      font-weight: bold;
      color: #fff;

      &::before {
        content: '';
        position: absolute;
        width: 4.15rem;
        height: 2.45rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/qixi-day/头像中的红线@2x.png');
        background-size: 100% 100%;
        bottom: 4.4rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    > .left,
    > .right {
      display: flex;
      flex-direction: column;
      width: calc((100vw - 4.15rem) / 2);
      text-align: center;
      > .avatar-wrapper {
        border-radius: 50%;
        border: 2px solid #fff;
        width: 6rem;
        height: 6rem;
        overflow: hidden;
        margin-bottom: 1rem;
        > img {
          height: 100%;
          width: 100%;
        }
      }

      > .title {
        text-align: center;
        color: #000;
        font-size: 1.3rem;
        font-weight: bold;
      }
    }

    > .left {
      align-items: flex-end;
    }

    > .right {
      align-items: flex-start;
    }
  }

  .desc {
    padding-top: 1rem;
    text-align: center;
    > .icon {
      display: inline-block;
      height: 2.89rem;
      width: 3.26rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/qixi-day/爱心@2x.png');
      background-size: 100% 100%;
      margin-bottom: 0.5rem;
    }

    > .num,
    > .name {
      color: #c6505a;
      font-size: 1.2rem;
      font-weight: bold;
    }

    > .text {
      font-size: 1.4rem;
      margin-top: 2.15rem;
      line-height: 3rem;
      /deep/ {
        span {
          font-size: 1.4rem;
          color: #bf4c55;
          font-weight: bold;
          margin: 0 0.3rem;
        }
      }
    }
  }

  .note {
    position: relative;
    height: 20.3rem;
    width: 29.3rem;
    margin: 0 auto;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/qixi-day/卷轴@2x.png');
    background-size: 100% 100%;
    margin-bottom: 0.4rem;
    font-family: shouke;

    > .title {
      position: absolute;
      top: 3.4rem;
      left: 6.1rem;
      font-size: 1rem;
    }

    > .line1 {
      text-align: center;
      position: absolute;
      font-size: 1.65rem;
      color: #4e4d4d;
      top: 7.65rem;
      left: 50%;
      transform: translateX(-50%);
      resize: none;
      font-family: shouke;
      border: none;
      background-color: transparent;
      width: 18rem;
    }

    > .change-btn {
      position: absolute;
      bottom: 2.8rem;
      right: 3.15rem;
      width: 3.765rem;
      height: 4.89rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/qixi-day/换一换@2x.png');
      background-size: 100% 100%;
    }
  }

  .save-img {
    height: 2rem;
    width: 6.4rem;
    margin: 0 auto;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/qixi-day/save-img.png');
    background-size: 100% 100%;
  }

  .logo {
    height: 4.4rem;
    width: 8.2rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/qixi-day/戏鲸@2x.png');
    background-size: 100% 100%;
    margin: 0 auto;
    margin-top: 7.9rem;
  }
}
