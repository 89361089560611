
































































.the-fu-dai-container {
  > .bg {
    position: relative;
    height: 20.75rem;
    width: 20.75rem;
    background-image: url('../../../../../assets/activity/spring-festival/light.png');
    background-size: 100% 100%;
    margin: 0 auto;
    font-size: 1.4rem;
    color: #ffe8c9;
    > .img {
      position: relative;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 10.3rem;
      width: 10.3rem;
    }

    > .extra {
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translateX(-50%);
      margin: 0;
      white-space: nowrap;

      > .btn {
        font-weight: bold;
        margin-left: 1.5rem;
      }
    }
  }

  > .btn-group {
    display: flex;
    justify-content: space-between;
    padding-bottom: 2rem;
    border-bottom: 1px solid rgba(255, 247, 236, 0.2);
    > div {
      font-size: 1.6rem;
      font-weight: bold;
      background-image: url('../../../../../assets/activity/spring-festival/open_btn.png');
      height: 5rem;
      width: 15rem;
      line-height: 5rem;
      text-align: center;
      color: #ffe8c9;
      background-size: 100% 100%;
    }
  }

  /deep/ {
    .avatar-modal-content {
      padding-top: 3.5rem;
      > .body {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 25rem;
        max-height: 30rem;
        overflow-y: scroll;
        padding: 0 1.5rem;
        .avatar-form {
          height: 11.55rem;
          width: 11.55rem;
        }

        .single {
          text-align: center;

          .name {
            font-size: 1.2rem;
          }
        }

        > .avatar-form-list {
          display: flex;
          flex-wrap: wrap;
          > div {
            text-align: center;
            width: 30%;
            background-color: #fafafa;
            margin-bottom: 1rem;
            margin-right: 1rem;
            padding: 0.5rem 0;
            &:nth-child(3n) {
              margin-right: 0;
            }
            > img {
              width: 5.9rem;
              height: 5.9rem;
              margin-bottom: 0.5rem;
            }
          }
        }
      }

      .confirm-btn {
        background-image: url('../../../../../assets/activity/spring-festival/收下@3x.png');
        height: 4.5rem;
        line-height: 5rem;
        width: 15rem;
        background-size: 100% 100%;
        font-size: 1.6rem;
        font-weight: bold;
        color: #ffe8c9;
        text-align: center;
        margin: 0 auto;
      }
    }
  }
}
