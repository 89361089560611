


























.cell-item {
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  justify-content: space-between;
  .icon {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1.5rem;
    vertical-align: middle;
  }

  .title {
    vertical-align: middle;
  }
  >.entry-icon {
    height: 1.1rem;
    width: 1.1rem;
  }
}
