












































































































.the-check-in-button {
  margin-top: 3.5rem;
  > .check-in-btn {
    background-color: #ffd415;
    height: 4.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2.25rem;
    margin: 0 1rem;
    margin-bottom: 3rem;

    &:active {
      background-color: rgba(255, 212, 21, 0.8);
    }

    > span:first-child {
      font-size: 1.5rem;
      font-weight: bold;
    }

    > span:last-child {
      font-size: 1.4rem;
    }
  }

  .attention {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  .li {
    font-size: 1.4rem;
    color: #7d7d7d;
    margin-bottom: 0.5rem;
  }
}
