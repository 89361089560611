.list {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9;
  padding: 2rem 0;

  > .icon {
    height: 2rem;
    width: 2rem;
    display: inline-block;
    background-size: 100% 100%;
    &.left {
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/左滑@3x.png');
      margin-right: 4.5rem;
    }

    &.right {
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/右滑@3x.png');
      margin-left: 4.5rem;
    }
  }
  .swiper-container-reward {
    width: 10rem;
    height: 10rem;
    margin: 0;
  }
  .swiper-slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #5b8cd2;
    border-radius: 0.5rem;
    > .name {
      font-size: 1rem;
      color: #fff;
    }

    > img {
      width: 3rem;
      height: 3rem;
      margin-bottom: 1rem;
    }
  }
}
