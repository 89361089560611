
























.item {
  padding: 2rem 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  border-bottom: 1px solid #E8E7E7;

  .title {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .desc {
    font-size: 1.2rem;
    color: #7D7D7D;
    margin-bottom: 1rem;
  }

  .extra {
    font-size: 1.2rem;

    >span {
      margin-right: 0.8rem;
    }
  }
  >img {
    height: 5rem;
    width: 5rem;
    border-radius: 0.5rem;
    margin-left: 1.9rem;
  }
}
