
















.the-search-fields-container {
  padding: 0 2rem;
  height: 3rem;
  border-radius: 1.85rem;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
  width: 30rem;
  background-color: #f3f3f3;
  overflow: hidden;

  > .icon {
    height: 1.6rem;
    width: 1.6rem;
    margin-right: 1rem;
  }

  > input {
    background-color: #f3f3f3;
    width: 100%;
    &::placeholder {
      color: rgba(125, 125, 125, 0.7);
    }
  }
}
