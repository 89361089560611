












































































































































































































p {
  margin: 0;
}
.main {
  // height: calc(100vh - 5.7rem);
  // height: calc(100vh - 18.2rem);
  // margin-top: 12.5rem;
  overflow: scroll;
}
.filter-fields {
  position: fixed;
  top: 0;
  z-index: 999;
  margin-top: 5rem;
  font-size: 1.4rem;
  background-color: #fafafa;

  .category,
  .special-list {
    &::-webkit-scrollbar {
      display: none;
      height: 0;
    }
  }

  .category {
    white-space: nowrap;
    width: 100vw;
    overflow: scroll;
    padding-left: 1.5rem;
    background-color: #fafafa;
    z-index: 99;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    .item {
      color: #c0bdbd;
      margin-right: 2.5rem;

      &.active {
        color: #1a1a1a;
        font-weight: bold;
      }
    }
  }

  .advance {
    display: flex;
    justify-content: space-between;
    padding-left: 1.5rem;
    padding-bottom: 1.5rem;
    // padding-top: 1.5rem;
    font-size: 1.4rem;
    li {
      display: flex;
      align-items: center;
      margin-right: 1.4rem;

      &.bgm-li.selected {
        color: #ce3dff;
      }

      .bgm-icon {
        height: 1.5rem;
        width: 1.5rem;
        margin-right: 0.5rem;
      }

      .expand-icon {
        height: 0.8rem;
        width: 0.8rem;
        margin-left: 0.5rem;
      }
    }
  }

  .special-list {
    display: flex;
    width: 100vw;
    overflow: scroll;
    padding-left: 1.5rem;
    // margin-bottom: 2rem;
    padding-bottom: 2rem;
    li {
      margin-right: 1.4rem;
      padding: 0 1.1rem;
      height: 2.5rem;
      line-height: 2.5rem;
      background-color: #fff;
      border-radius: 1.2rem;
      white-space: nowrap;

      &.active {
        background-color: #ffeb6f;
      }
    }
  }

  .select-filter-mask {
    position: fixed;
    z-index: 9;
    top: 13.5rem;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .select-filter {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    z-index: 99;
    flex-wrap: wrap;
    font-size: 1.3rem;
    background-color: #fff;
    padding: 2.5rem 1.5rem;
    padding-bottom: 1rem;

    .filter-title {
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
    }

    .item {
      display: inline-block;
      height: 3.3rem;
      width: 7.5rem;
      line-height: 3.3rem;
      text-align: center;
      border-radius: 1.65rem;
      background-color: #fafafa;
      margin-bottom: 1.5rem;
      margin-right: 1.5rem;
      &:nth-child(4n) {
        margin-right: 0;
      }

      &.active {
        background-color: #ffeb6f;
      }
    }
  }
}
