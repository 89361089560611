







































.the-eidtors-container {
  margin-top: 3rem;
  padding: 2.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  background-color: #fafafa;
  border: 1px solid #e8e7e7;
  border-radius: 1.5rem;
  overflow: hidden;

  > .title {
    text-align: center;
    margin-bottom: 3rem;
  }

  > .list {
    display: flex;
    justify-content: space-between;
    > .item {
      text-align: center;
      > .avatar {
        height: 7.5rem;
        width: 7.5rem;
        border-radius: 50%;
        margin-bottom: 2.2rem;
      }
    }
  }
}
