.section {
  color: #fff;
  .body {
    padding: 0 1.5rem;
    padding-top: 2.45rem;

    > .date {
      text-align: center;
      font-size: 1.3rem;
      color: rgba(255, 255, 255, 0.8);
      margin-bottom: 2rem;
      font-weight: bold;
    }

    > .deadline {
      text-align: center;
      color: #fff;
      font-size: 1.3rem;
      font-weight: bold;
      > span {
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 0.2rem;
        height: 1.6rem;
        width: 1.6rem;
        text-align: center;
        line-height: 1.8rem;
      }
    }

    > .table {
      padding-top: 3rem;
      > .theader {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        padding: 1.5rem 0;
        display: flex;
        font-size: 1.5rem;
        font-weight: bold;
        color: #fff;
        justify-content: space-between;
      }

      > .tbody {
        > .row {
          display: flex;
          align-items: center;
          font-size: 1.3rem;

          &:last-child {
            > .right {
              border-bottom: none;
            }
          }

          > .left {
            width: 5rem;
            display: flex;
            justify-content: center;
            font-weight: bold;
            > .icon {
              display: inline-block;
              height: 2.5rem;
              width: 2.5rem;
              background-size: 100% 100%;
              &.rank1 {
                background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank/1@3x.png');
              }

              &.rank2 {
                background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank/1@3x(1).png');
              }

              &.rank3 {
                background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank/1@3x(2).png');
              }
            }
          }

          > .right {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            padding: 1rem 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);

            > .nickname {
              display: flex;
              align-items: center;
              > .text {
                width: 7.8rem;
              }

              > .avatarWrapper {
                height: 4.5rem;
                width: 4.5rem;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 2.3rem;

                > img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }

        > .more {
          font-size: 1.2rem;
          padding: 2rem 0;
          text-align: center;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
        }
      }
    }
  }
}
