









































































































.the-body {
  background-color: #fff;
  position: relative;
  left: 0;
  right: 0;
  z-index: 99;
  top: -1rem;
  padding: 0 1.5rem;
  border-radius: 1rem 1rem 0 0;
  min-height: 60vh;
  >.header {
    padding: 1.5rem 0;
    border-bottom: 1px solid rgba(232, 231, 231, 0.8);
    display: flex;
    align-items: center;
    font-size: 1.6rem;
    font-weight: bold;
    >span {
      color: #7D7D7D;
      font-size: 1.1rem;
      margin-left: 1rem;
    }
  }

  >.list {
    >.item {
      display: flex;
      justify-content: space-between;
      padding: 2rem 0;
      border-bottom: 1px solid rgba(232, 231, 231, 0.8);
      .title {
        font-size: 1.7rem;
        margin-bottom: 2rem;
      }

      .tags {
        font-size: 1.2rem;
        color: rgba(125, 125, 125, 1);
        >span {
          display: inline-block;
          border: 1px solid rgba(232, 231, 231, 1);
          padding: 0 0.75rem;
          border-radius: 0.9rem;
          margin-right: 0.6rem;
        }
      }

      .extra {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        color: #7D7D7D;
        margin-top: 1.6rem;

        >span {
          margin-right: 0.8rem;

          &.bgm, &.ost {
            font-size: 0.8rem;
            display: inline-block;
            height: 1.2rem;
            width: 2.3rem;
            text-align: center;
            border-radius: 0.2rem;
            border: 1px solid currentColor;
          }

          &.bgm {
            color: #CE3DFF;
          }

          &.ost {
            color: #FF2424;
          }
        }
      }

      >.right {
        margin-left: 1rem;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: space-between;
        .avatar {
          height: 5rem;
          width: 5rem;
          border-radius: 0.5rem;
          object-fit: cover;
        }
        .more {
          display: inline-block;
          height: 1.5rem;
          width: 1.5rem;
          background-image: url('../../../assets/my-collection/编组6@3x.png');
          background-repeat: no-repeat;
          background-size: 0.3rem 1.5rem;
        }
      }
    }
  }
}
