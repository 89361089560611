







































.desc-input-container {
  > .title {
    font-size: 1.4rem;
    padding: 1.5rem 0;
  }
  > .desc-input {
    border-radius: 0.5rem;
    overflow: hidden;
    position: relative;
    font-size: 0;
    background-color: #f3f3f3;

    > .count {
      font-size: 1.1rem;
      color: #c0bdbd;
      position: absolute;
      bottom: 1.5rem;
      right: 1.3rem;
    }

    > textarea {
      border: none;
      background-color: #f3f3f3;
      height: 12.5rem;
      width: 100%;
      resize: none;
      font-size: 1.3rem;
      padding: 1.5rem 1.3rem;

      &::placeholder {
        color: #c0bdbd;
      }
    }
  }
}
