


















































































































.header {
  display: flex;
  justify-content: center;
  padding: 1.2rem;
  > .close-btn {
    display: flex;
    height: 2rem;
    width: 4rem;
    align-items: center;
    justify-content: center;
    background-color: rgba(243, 243, 243, 1);
    border-radius: 1.08rem;
    > img {
      height: 1rem;
      width: 1rem;
    }
  }
}

.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.6rem;
  font-weight: bold;

  > .add-btn {
    font-size: 1.4rem;
    border-radius: 1.3rem;
    border: 1px solid rgba(232, 231, 231, 1);
    padding: 0.3rem 1rem;
    > img {
      height: 1rem;
      width: 1rem;
    }
  }
}

.list {
  padding: 2rem 0;
  height: calc(70vh - 7.2rem);
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }
  > .item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    &:active {
      background-color: #f3f3f3;
    }
    p {
      margin: 0;
    }
    .title {
      font-size: 1.6rem;
    }
    .extra {
      font-size: 1.3rem;
      color: rgba(125, 125, 125, 1);
    }

    > img {
      height: 5rem;
      width: 5rem;
      border-radius: 0.3rem;
      margin-right: 1rem;
      object-fit: cover;
    }
  }
}
