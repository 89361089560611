











































































































































.collection-edit {
  padding: 1.5rem;
  /deep/ {
    .tag-edit-popup {
      border-radius: 1rem 1rem 0 0;
    }
  }
  .row {
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(232, 231, 231, 0.5);
    padding: 2rem 0;

    > input {
      flex: 1;
      padding-left: 2.5rem;
    }

    > .tag-placeholder {
      color: rgba(192, 189, 189, 1);
      flex: 1;
      padding-left: 2.5rem;
    }

    > .right-allow {
      height: 1.1rem;
      width: 1.1rem;
    }

    .avatar {
      height: 3.5rem;
      width: 3.5rem;
      border-radius: 0.2rem;
    }
  }

  .desc {
    display: flex;
    flex-direction: column;

    font-size: 1.5rem;
    .title {
      font-size: 1.5rem;
      padding: 2rem 0;
    }
    > textarea {
      resize: none;
      border: 1px solid rgba(232, 231, 231, 1);
      height: 15rem;
      padding: 1.5rem;
      border-radius: 0.5rem;
    }
  }

  .submit-btn {
    margin-top: 4rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 1rem 0;
    border-radius: 5rem;
    background-color: #ffd415;

    &:active {
      background-color: rgba(255, 212, 21, 0.5);
    }
  }
}
