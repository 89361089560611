





























.app-normal-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.2rem 0;
  border-bottom: 1px solid #e8e7e7;

  .left {
    display: flex;
    align-items: center;
    margin-right: 2.4rem;
    flex: 1;
  }
  .flag {
    display: inline-block;
    height: 2rem;
    width: 3.5rem;
    text-align: center;
    line-height: 2rem;
    background-color: #ff7b52;
    font-size: 1.2rem;
    color: #fff;
    border-radius: 0.2rem 0.2rem 0.8rem 0.2rem;
    margin-right: 1rem;
  }

  > .right {
    color: #c0bdbd;
    font-size: 1.2rem;
  }

  .title {
    font-size: 1.6rem;
  }
}
