.theTop {
  position: relative;
  z-index: 9;
  padding-top: 3rem;
  >.title {
    height: 3.9rem;
  }

  >.welfareItemList {
    padding-top: 3.25rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    >.welfareItem {
      width: 14rem;
      margin-right: 3rem;
      margin-bottom: 2.5rem;
      &:nth-child(2n) {
        margin-right: 0;
      }
    
      >.content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        position: relative;
        height: 14rem;
        background: linear-gradient(-40deg, rgba(255, 191, 232, .4), rgba(255, 255, 255, .4));
        border: 1px solid rgba(255, 213, 130, 0.4);
        border-radius: 1rem;
        margin-bottom: 1.4rem;
        font-size: 1.2rem;
        text-align: center;
        color: #fff;
        padding-top: 1.6rem;
        padding-bottom: 1.3rem;

        &::before {
          content: '';
          position: absolute;
          width: 4.5rem;
          height: 1.8rem;
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/may-twenty/装饰@3x.png');
          background-size: 100% 100%;
          top: -1.05rem;
          left: 50%;
          transform: translateX(-50%);
        }

        >img {
          width: 7rem;
          height: 7rem;
          object-fit: cover;
        }
      }
    
      >.btn {
        height: 3.4rem;
        width: 10.1rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/may-twenty/底框@3x.png');
        background-size: 100% 100%;
        margin: 0 auto;
        margin-bottom: 0.5rem;
        color: #fff;
        font-size: 1.4rem;
        text-align: center;
        line-height: 3.4rem;
        text-shadow: 0px 2px 2px #E34A8D;
      }
    
      >.text {
        color: rgba(253, 249, 238, 1);
        font-size: 1.2rem;
        text-align: center;
      }
    }
  }
}