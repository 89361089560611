






































.the-card {
  position: relative;
  z-index: 9;
  background-color: #541fd9;
  border-radius: 1.5rem;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
  margin-bottom: 2.5rem;

  > .chest-list {
    display: flex;
    position: absolute;
    top: 0rem;
    left: 50%;
    transform: translateX(-50%);

    > .chest {
      position: relative;
      text-align: center;
      margin-right: 2.5rem;
      font-size: 0;

      &.active {
        > img {
          transform: translateX(-50%) scale(1.5);
        }
      }

      &:last-child {
        margin-right: 0;
        &::after {
          display: none;
        }
      }
      /* 
      &::after {
        content: '';
        position: absolute;
        background-image: url('../../../../assets/webview/treasure-chest-desc/进度@3x.png');
        background-size: 100% 100%;
        height: 1rem;
        width: 1rem;
        right: -1.75rem;
        top: 50%;
        transform: translateY(-50%);
      } */
      > img {
        position: absolute;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1.1rem;
        width: 4.2rem;
        height: 3.6rem;
      }

      > .price {
        position: relative;
        z-index: 9;
        height: 2rem;
        width: 5.5rem;
        border-radius: 1rem;
        background-color: #b76aff;
        color: #fff;
        font-size: 1.2rem;
        text-align: center;
        line-height: 2rem;
      }
    }
  }
  > .content {
    background-color: #763dff;
    min-height: 50vh;
    border-radius: 1.5rem;
    padding: 0 1rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
}
