






















































.popup-title {
  display: flex;
  justify-content: space-between;
  font-size: 1.6rem;
  >span {
    &:nth-child(2) {
      font-weight: bold;
    }

    &:last-child {
      color: #C0BDBD;
      &.active {
        color: #000;
      }
    }
  }
}

input {
  font-size: 1.6rem;
  margin-top: 4.3rem;
  width: 100%;
}
