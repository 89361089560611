

























































.swiper-container {
  height: 7.9rem;
  margin: 0 1.2rem;
  border-radius: 1rem;
  overflow: hidden;
  .swiper-slide {
    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
