












































#app {
  // height: 100vh;
  // overflow-y: scroll;
  max-width: 480px;
  margin: 0 auto;
  background-color: #fff;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
