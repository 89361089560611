





























































.the-top-menu-container {
  width: 100%;
  background-color: #fafafa;
  font-size: 1.5rem;
  height: 5rem;
  line-height: 5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-bottom: 1px solid #e8e7e7;

  > .tip {
    font-size: 1.2rem;
    white-space: nowrap;
  }

  /deep/ {
    .swiper-slide {
      width: auto;
      margin-right: 2.5rem;
      color: #7d7d7d;
      &.active {
        color: #1a1a1a;
        font-weight: bold;
      }
    }
  }
}
