.list {
  position: relative;
  z-index: 9;
  display: flex;
  justify-content: space-between;
  padding: 0 2.45rem;
  padding-top: 2.65rem;
  padding-bottom: 3rem;
  > .item {
    text-align: center;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    margin-right: 3.05rem;

    &:last-child {
      margin-right: 0;
    }

    img {
      font-size: 0;
      height: 5.1rem;
      width: 5.1rem;
    }
  }
}
