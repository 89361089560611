































































































































































/deep/ {
  .app-layout-body {
    padding: 1.5rem;
    padding-bottom: 14rem;
    > .header {
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/coin-exchange/jbdh_bg@3x.png');
      height: 11rem;
      width: 100%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .coin-num {
        font-size: 3rem;
        font-weight: 500;
      }

      > .coin-text {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        > img {
          height: 2.4rem;
          width: 2.4rem;
          margin-right: 0.2rem;
        }
      }
    }

    > .body {
      margin-top: 1.5rem;
      > .list {
        display: grid;
        grid-template-columns: repeat(3, 10.8rem);
        column-gap: 1.05rem;
        row-gap: 1rem;

        > li {
          position: relative;
          background-color: #fff;
          text-align: center;
          padding: 1.7rem 0;
          border-radius: 0.5rem;
          border: 1px solid transparent;
          &.active {
            border: 1px solid rgba(255, 212, 21, 1);
            &::after {
              position: absolute;
              content: '';
              height: 2.3rem;
              width: 2.3rem;
              background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/mine/选择@3x.png');
              background-repeat: no-repeat;
              background-size: 100% 100%;
              right: -1px;
              top: -1px;
            }
          }
          > .coin {
            height: 5.5rem;
            width: 5.5rem;
            margin-bottom: 1.4rem;
          }

          > .text {
            font-size: 1.2rem;
            margin-bottom: 1.85rem;
            font-weight: 500;
          }
          > .diamond {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1.2rem;
            > img {
              height: 1.5rem;
              width: 1.5rem;
              margin-right: 0.5rem;
            }
          }
        }
      }

      > .btn-group {
        display: flex;
        justify-content: space-between;
        padding: 0 2.5rem;
        margin-top: 3.5rem;
        > .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 3.5rem;
          width: 14rem;
          background-color: #fff;
          border-radius: 1.75rem;
          box-shadow: 0px 0px 1rem 0px rgba(0, 0, 0, 0.05);
          font-size: 1.3rem;
          &:first-child {
            border: 1px solid rgba(255, 212, 22, 0.5);
          }
          > img {
            height: 0.9rem;
            width: 0.9rem;
            margin-left: 1.05rem;
          }
        }
      }
    }

    > .footer {
      display: flex;
      justify-content: space-between;
      padding: 0 1.5rem;
      padding-top: 1.5rem;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 8.4rem;
      background-color: #fff;
      .diamond-count {
        display: flex;
        align-items: center;
        font-size: 1.42rem;
        font-weight: 500;
        > img {
          height: 2.2rem;
          width: 2.2rem;
          margin-right: 1.5rem;
        }
      }

      .note {
        font-size: 1.3rem;
        padding-left: 3.7rem;
      }
      > .exchange-btn {
        width: 14rem;
        height: 3.9rem;
        line-height: 3.9rem;
        text-align: center;
        font-size: 1.7rem;
        font-weight: 500;
        border-radius: 2rem;
        color: #fff;
        background: #ffd415 linear-gradient(180deg, #ff4c77 0%, #ff2424 100%);
      }
    }
  }
}
