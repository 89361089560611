


















.excellent-work-list {
  > .header {
    position: relative;
    height: 5rem;
    background-image: url('../../../../assets/activity/spring-voice/格纹@3x.png');
    background-size: 100% 100%;
    color: #ffffe3;
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
    line-height: 5rem;

    &::after {
      position: absolute;
      content: '';
      height: 1.3rem;
      width: 14.6rem;
      background-image: url('../../../../assets/activity/spring-voice/音波@3x.png');
      background-size: 100% 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  > .list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.95rem;
    padding-top: 1.5rem;
  }

  > .no-more {
    font-size: 1.3rem;
    font-weight: bold;
    color: #00563c;
    text-align: center;
    margin-top: 1rem;
  }
}
