











.tab-panel {
  background-color: #ffffe3;
  padding: 0 1.6rem;
  padding-top: 2.45rem;
  overflow: hidden;
}
