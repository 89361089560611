




































.the-header {
  display: flex;
  padding: 3rem 0;
  margin: 0 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  > .avatar {
    height: 6.5rem;
    width: 6.5rem;
    border-radius: 50%;
  }

  > .right {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 0;
    margin-left: 2.5rem;
    > .nickname {
      color: #fff;
      font-size: 1.6rem;
      font-weight: bold;
    }

    > .desc {
      color: rgba(255, 255, 255, 0.5);
      font-size: 1.3rem;
    }
  }
}
