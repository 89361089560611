






















.the-friend-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.5rem;

  > .left {
    display: flex;
    align-items: center;
    font-size: 0;

    .avatar {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
    }

    > .title {
      margin-left: 1.5rem;
      font-size: 1.5rem;
    }
  }

  .btn {
    width: 6.5rem;
    height: 3.3rem;
    line-height: 3.3rem;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: center;
    background-color: #ffeb6f;
    border-radius: 1.75rem;

    &:active {
      background-color: rgba(255, 235, 111, 0.8);
    }
  }
}
