

































































































































































































































.voice-container {
  position: relative;
  padding-top: 3rem;
  min-height: 100vh;
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/voice/油画色彩@3x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-bottom: 2.5rem;

  > .player-filed {
    position: relative;
    z-index: 99;
    height: calc(0.38 * 100vh);
    width: 34.5rem;
    margin: 0 auto;
    border-radius: 1rem;
    background-color: rgba(255, 255, 255, 0.2);

    &::before,
    &::after {
      position: absolute;
      height: 1.8rem;
      width: 1.8rem;
      content: '';
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    &::before {
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/voice/left@3x.png');
      top: 2.5rem;
      left: 1.5rem;
    }

    &::after {
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/voice/right@3x.png');
      right: 1.5rem;
      bottom: 2.5rem;
    }

    > .material-text-list {
      font-size: 1.8rem;
      color: #fff;

      .swiper-slide {
        padding: 0 2.85rem;
        margin: 0;
        display: flex;
        align-items: center;
      }
    }

    > .player-btn {
      position: absolute;
      z-index: 999;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 6rem;
      width: 6rem;
    }
  }

  > .dan-mu {
    padding-top: 2rem;
    overflow: hidden;
    height: calc(0.3 * 100vh);
    font-size: 1.3rem;
    color: #fff;

    > .row {
      position: relative;
      // display: flex;
      margin-bottom: 2rem;
      word-break: keep-all;
      padding-left: 100vw;
      > span {
        margin-right: 2.5rem;
      }
    }
  }

  > .info {
    position: absolute;
    bottom: 5.3rem;
    color: #fff;
    width: 27rem;
    padding-left: 1.5rem;

    p {
      margin: 0;
    }

    > .nickname {
      font-size: 1.7rem;
      margin-bottom: 1rem;
    }

    > .desc {
      font-size: 1.5rem;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 2rem;
    }

    > .title {
      font-size: 1.3rem;
      display: flex;
      // align-items: center;
      > img {
        height: 1.3rem;
        width: 1.3rem;
        margin-right: 0.5rem;
      }
    }
  }

  > .right-control {
    position: absolute;
    z-index: 99;
    right: 1.5rem;
    bottom: 9rem;
    > .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #fff;
      margin-bottom: 1.5rem;
      font-size: 1.3rem;
      > img {
        height: 2.9rem;
        width: 2.9rem;
        margin-bottom: 0.5rem;
      }
    }
  }

  > .progress-bar-wrapper {
    padding: 1.5rem 0;
    position: absolute;
    bottom: 1rem;
    width: 100%;
    > .progress-bar {
      height: 0.2rem;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.1);
      > .progress {
        height: 100%;
        width: 30%;
        background-color: #fff;
      }
    }
  }
}
