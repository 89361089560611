




























































































.the-fu-dai-cart-panel {
  margin-bottom: 1rem;
  > .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1.4rem;
    color: #ffe8c9;

    > .count {
      font-size: 4rem;
      font-weight: bold;
    }
  }

  > .fudai-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 2.5rem;

    > li {
      position: relative;
      border-radius: 0.5rem;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #ffe8c9;
      width: 10rem;
      height: 12.5rem;
      background-color: rgba(255, 255, 255, 0.1);
      margin-bottom: 1.25rem;
      margin-right: 1.25rem;
      border: 1px solid transparent;

      &:nth-child(3n) {
        margin-right: 0;
      }

      &.active {
        border: 1px solid rgba(243, 224, 169, 1);
      }

      > .flag {
        position: absolute;
        left: 0;
        top: 0;
        display: inline-block;
        font-size: 1rem;
        color: #6d0806;
        background: linear-gradient(0deg, #f8ba7b, #fddea4);
        border-radius: 0 0px 0.5rem 0px;
        width: 3.2rem;
        height: 1.5rem;
        line-height: 1.5rem;
        text-align: center;
      }

      > img {
        height: 5.75rem;
        width: 5.75rem;
        margin-bottom: 1.6rem;
      }

      > .title {
        font-size: 1.4rem;
      }
    }
  }

  > .cart-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100vw;
    background-color: #fff8f2;
    border-radius: 1rem 1rem 0 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    padding: 0.5rem 1.5rem;
    padding-bottom: 2rem;

    > .left {
      display: flex;
      align-items: center;
      font-size: 1.2rem;
      font-weight: bold;
      > .diamond {
        height: 1.9rem;
        width: 2rem;
        margin-right: 1.5rem;
      }

      > .diamon-count {
        position: relative;
        margin-right: 2rem;

        &::after {
          position: absolute;
          content: '';
          width: 1px;
          background-color: #c0bdbd;
          height: 1.3rem;
          right: -0.9rem;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    > .btn {
      color: #fff;
      background-color: #fc6327;
      width: 14rem;
      height: 3.9rem;
      border-radius: 2rem;
      font-size: 1.7rem;
      font-weight: bold;
      line-height: 3.9rem;
      text-align: center;
    }
  }
}
