



















.the-section {
  margin-top: 3.5rem;
  
  .header {
    padding-left: 1.5rem;
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1.95rem;
  }

  .body {
    padding: 0 1.5rem;
  }
}
