.test-page {
  padding: 0 1.5rem;
  padding-top: 13rem;
  > .content {
    position: relative;
    min-height: 10rem;
    background-color: #c66dff;
    border-radius: 0.5rem;
    border: 2px solid #000;
    padding-top: 3.25rem;
    padding-bottom: 4.2rem;
    animation: fadeIn 1s both;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      background-color: #fff;
      border-radius: 0.5rem;
      border: 2px solid #000;
      top: 0.75rem;
      left: -2px;
      bottom: -2px;
    }

    &::before {
      content: '';
      position: absolute;
      width: 33.5rem;
      height: 11.9rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/组\ 50@3x.png');
      background-size: 100% 100%;
      top: -10.3rem;
      z-index: 999;
      left: calc(50% - 16.75rem);
    }

    > .title {
      display: flex;
      align-items: center;
      position: absolute;
      font-size: 1.8rem;
      font-weight: bold;
      top: -5.1rem;
      left: 9.9rem;
      color: #fff;
      z-index: 999;

      > .icon {
        display: inline-block;
        height: 0.5rem;
        width: 0.5rem;
        background-color: #fff;
        border-radius: 2px;
        transform: rotate(45deg);
        margin: 0 0.5rem;
      }
    }

    > .img {
      position: relative;
      z-index: 999;
      height: 12.5rem;
      width: 22.3rem;
      border-radius: 0.5rem;
      border: 2px solid #000;
      margin: 0 auto;
      margin-bottom: 3.4rem;

      > img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    > .question {
      position: relative;
      z-index: 999;
      padding: 0 2.5rem;
      padding-bottom: 4rem;

      &::after {
        position: absolute;
        content: '';
        width: 25rem;
        border-bottom: 2px dotted #ebe5f9;
        bottom: 0;
        left: calc(50% - 12.5rem);
      }

      > .title {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 2.5rem;
      }

      > .checkbox {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 1.7rem;
        &.select {
          > .icon {
            background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/选择@2x.png');
          }
        }
        > .icon {
          height: 1.6rem;
          width: 1.6rem;
          background-size: 100% 100%;
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/未选择@2x.png');
          margin-right: 1.4rem;
        }
      }
    }

    > .next-btn {
      box-sizing: content-box;
      position: relative;
      z-index: 999;
      height: 4rem;
      line-height: 4rem;
      width: 25rem;
      margin: 0 auto;
      text-align: center;
      border-radius: 0.7rem;
      background-color: #ffea3e;
      border: 2px solid #000;
      font-size: 1.6rem;
      font-weight: bold;
      margin-top: 2.2rem;
      border-bottom-width: 0.4rem;

      &::after,
      &::before {
        content: '';
        position: absolute;
        height: 0.9rem;
        width: 3.7rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/圆角矩形\ 4\ 拷贝\ 6@2x.png');
        background-size: 100% 100%;
        top: calc(50% - 0.45rem);
      }

      &::after {
        left: 4.4rem;
      }

      &::before {
        right: 4.4rem;
      }
    }
  }
}

@keyframes fadeIn {
  from {
    min-height: 100vh;
    opacity: 0;
  }

  99% {
    opacity: 0.9;
  }
  100% {
    min-height: auto;
    position: relative;
    opacity: 1;
  }
}
