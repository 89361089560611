


































.the-role-list-item {
  position: relative;
  display: flex;
  align-items: center;
  height: 10rem;
  border-radius: 1rem;
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    height: 10rem;
    width: 5rem;
    transform: rotate(45deg);
    right: -2rem;
    bottom: -5.5rem;
    background-color: RGBA(255, 255, 255, 0.8);
  }

  &.female,
  &.male {
    background-size: 8.8rem 8.8rem;
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 0.6rem;
  }

  &.male {
    background-color: #9ad7ff;
    background-image: url('../../../../../assets/webview/editor/男@3x.png');
  }

  &.female {
    background-color: #ffb3f0;
    background-image: url('../../../../../assets/webview/editor/女@3x.png');
  }

  > .more {
    position: absolute;
    height: 1.6rem;
    width: 1.6rem;
    top: 0.9rem;
    right: 1.5rem;
  }

  > .avatar {
    height: 5.5rem;
    width: 5.5rem;
    border-radius: 50%;
    border: 1px solid #fff;
    margin-right: 1.5rem;
  }

  > .color-field {
    position: absolute;
    z-index: 99;
    bottom: 0.5rem;
    right: 0.5rem;
    width: 1.1rem;
    height: 1.1rem;
    background-color: #584b95;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      height: 0.5rem;
      width: 0.5rem;
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .desc {
    font-size: 1.4rem;
  }
}
