






























































.level-step-bar-container {
  > .title {
    text-align: center;
    color: #fff;
    font-size: 2.1rem;
    font-weight: bold;
    margin-bottom: 1rem;
    padding-top: 6rem;
  }

  > .sub-title {
    text-align: center;
    font-size: 1.3rem;
    color: rgba(255, 255, 255, 0.5);
  }
}

.level-step-bar-wrapper {
  position: relative;
  margin: 0 3.5rem;

  > .progress {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 0.2rem;
    // width: 30%;
    background-color: #ffd416;
  }
}

.level-step-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    content: '';
    width: 100%;
    height: 0.2rem;
    background-color: rgba(255, 255, 255, 0.1);
  }

  > li {
    position: relative;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);
    // height: 0.8rem;
    // width: 0.8rem;
    // &::after {
    //   content: '';
    //   box-sizing: border-box;
    //   position: absolute;
    //   height: 0.8rem;
    //   width: 0.8rem;
    //   top: 50%;
    //   left: 50%;
    //   transform: translate(-50%, -50%);
    //   border-radius: 50%;
    //   background-color: #241c34;
    //   border: 0.2rem solid #c0bdbd;
    // }

    &.light {
      > .dot {
        border: 0.2rem solid #ffd416;
        background-color: #241c34;
      }
    }

    &.active {
      > .dot {
        height: 1.5rem;
        width: 1.5rem;
        border-width: 0.3rem;
      }

      > .text {
        font-size: 1.2rem;
        top: 2.2rem;
      }
    }

    > .dot {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
      // background-color: #c0bdbd;
      background-color: #241c34;
      border: 0.2rem solid #c0bdbd;
    }

    > .text {
      position: absolute;
      color: #ffd416;
      font-size: 1rem;
      word-break: keep-all;
      top: 1.9rem;
    }
  }
}
