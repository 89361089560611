



























.tag-form-item-cotainer {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(232, 231, 231, 0.5);
  > .text {
    margin: 0;
    color: #ff7b52;
    font-size: 1.1rem;
  }

  .form-item-container {
    border-bottom: none;
  }
}
