

























































































.btn-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 9rem;
  width: 100%;
  background-color: #fff;
  padding: 0 2.5rem;
  padding-top: 1rem;
  box-shadow: 0px -1px 20px 0px rgba(0, 0, 0, 0.05);

  > .open_app_btn {
    display: inline-block;
    background-color: #ffd415;
    width: 100%;
    text-align: center;
    padding: 1.4rem 0;
    font-size: 1.7rem;
    font-weight: bold;
    border-radius: 3.03rem;

    &:active {
      background-color: rgba(255, 212, 21, 0.5);
    }
  }
}

.header {
  font-size: 0;
  width: 100%;
  position: absolute;
  .bg {
    width: 100%;
    height: 30rem;
  }

  .mask {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(40, 19, 81, 0.8);
    backdrop-filter: blur(10px);
  }
}

.open-btn {
  position: fixed;
  text-align: center;
  font-size: 1.7rem;
  font-weight: bold;
  padding: 1.4rem 0;
  border-radius: 3.03rem;
  background-color: #f3f3f3;
  bottom: 2rem;
  left: 2.5rem;
  right: 2.5rem;
}

.body {
  text-align: center;
  position: relative;
  z-index: 9999;
  font-size: 0;
  .back_home_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffd415;
    position: absolute;
    z-index: 2000;
    top: 9rem;
    font-size: 30upx;
    height: 3.5rem;
    width: 144upx;
    border-radius: 0px 2.25rem 2.25rem 0px;
    font-weight: bold;

    .img {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 10upx;
    }
  }
  .logo {
    margin-top: 4.8rem;
    width: 8.1rem;
    height: 2.8rem;
  }

  .user-info {
    .avatar {
      width: 5.5rem;
      height: 5.5rem;
      border-radius: 50%;
      margin-top: 2.5rem;
    }

    .title {
      font-size: 1.5rem;
      color: #ffffff;
      margin-top: 1.5rem;
    }
  }

  .room-avatar {
    display: flex;
    justify-content: center;
    position: relative;
    background-color: transparent;
    border-radius: 50%;
    margin-top: 3rem;
    .border_1,
    .border_2,
    .border_3 {
      border-radius: 50%;
      display: inline-block;
      display: flex;
      align-items: center;
      justify-content: center;
      /* border: 5upx solid #dddddd; */
      /* padding: 10upx; */
      /* background-clip: content-box; */
    }

    .border_3 {
      height: 13rem;
      width: 13rem;

      background: linear-gradient(rgba(255, 255, 255, 0.9), rgba(255, 67, 101, 0.9));
    }

    .border_2 {
      height: 14.5rem;
      width: 14.5rem;
      background-img: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 67, 101, 0.5));
    }

    .border_1 {
      height: 16rem;
      width: 16rem;
      background-img: linear-gradient(rgba(255, 255, 255, 0.3), rgba(255, 67, 101, 0.3));
    }

    .icon {
      width: 11rem;
      height: 11rem;
      border-radius: 50%;
    }
  }

  .room-info {
    margin-top: 2.5rem;
    .title {
      font-size: 1.8rem;
      font-weight: bold;
    }

    .desc {
      font-size: 1.4rem;
      margin-top: 1.5rem;

      .text {
        // width: 22.4rem;
      }
    }

    .room-id {
      font-size: 1.4rem;
      margin-top: 2.5rem;
      .text {
        display: inline-block;
        background-color: #f3f3f3;
        width: 15.85rem;
        height: 3.5rem;
        line-height: 3.5rem;
        border-radius: 2.25rem;
      }
    }
  }
}
