.page {
  background-color: #2b79f0;
  border-radius: 0.8rem;
  min-height: 20vh;
  padding: 0 2rem;

  > .header {
    display: flex;
    justify-content: center;
    padding: 2.5rem 0;
    border-bottom: 1px solid #3386f2;

    > .welfareItem {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  > .welfareList {
    display: flex;
    flex-wrap: wrap;
    padding: 0 1.25rem;
    padding-top: 2.5rem;
  }

  .welfareItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 12rem;
    text-align: center;
    margin-right: 4rem;
    margin-bottom: 2.5rem;

    &:nth-child(2n) {
      margin-right: 0;
    }

    > .avatarWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #3386f2;
      height: 12.5rem;
      width: 12rem;
      border-radius: 0.5rem;
      margin-bottom: 1.4rem;
      > .avatar {
        height: 7.5rem;
        width: 7.5rem;
      }

      > .nickname {
        color: #fdf9ee;
        font-size: 1.2rem;
      }
    }

    > .count {
      color: #fdf9ee;
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }

    > .btn {
      font-size: 1.4rem;
      font-weight: bold;
      color: #fdf9ee;
      background-color: #ffa76a;
      border-radius: 1.3rem;
      height: 2.6rem;
      line-height: 2.6rem;
      width: 10rem;
      text-align: center;
      text-shadow: 1px 2px 2px rgba(238, 109, 83, 0.5);
    }
  }

  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 0.8rem;
    padding: 3.75rem 4.45rem;
    .confirm {
      background-color: #ff9e5b;
      border-radius: 2rem;
      font-size: 1.5rem;
      color: #fdf9ee;
      height: 4rem;
      width: 20rem;
      line-height: 4rem;
      text-align: center;
    }

    > .avatarWrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #3386f2;
      height: 12.5rem;
      width: 12rem;
      border-radius: 0.5rem;
      margin-bottom: 2.4rem;

      > .avatar {
        height: 7.5rem;
        width: 7.5rem;
      }

      > .name {
        color: #fdf9ee;
        font-size: 1.2rem;
      }
    }
  }
}

// :global(html) {
//   background-color: #2b79f0;
// }
