





















































.level-step-bar-container {
  padding-bottom: 4rem;
}
.level-step-bar-wrapper {
  position: relative;
  margin: 0 3.5rem;
  // margin-bottom: 4rem;

  > .progress {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 0.2rem;
    // width: 30%;
    background-color: #ffe8c9;
  }
}

.level-step-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &::after {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    content: '';
    width: 100%;
    height: 0.2rem;
    background-color: rgba(255, 255, 255, 0.1);
  }

  > li {
    position: relative;
    z-index: 999;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(255, 255, 255, 0.5);

    &.light {
      > .dot {
        border: 0.2rem solid #ffe8c9;
        background-color: rgba(146, 19, 19, 1);
      }
    }

    &.active {
      > .dot {
        height: 1.5rem;
        width: 1.5rem;
        border-width: 0.3rem;
      }

      > .text {
        font-size: 1.2rem;
        top: 1.2rem;
      }
    }

    > .dot {
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 0.8rem;
      width: 0.8rem;
      border-radius: 50%;
      background-color: #241c34;
      border: 0.2rem solid #c0bdbd;
    }

    > .text {
      position: absolute;
      color: #ffe8c9;
      font-size: 1rem;
      word-break: keep-all;
      top: 1.4rem;
    }
  }
}
