













































































































.xijing-course-container {
  position: relative;
  padding-top: 7.45rem;
  padding-bottom: 7.5rem;
  .icon {
    position: absolute;
    top: 1.5rem;
    left: 50%;
    transform: translateX(-50%);
    width: 12rem;
    height: 12rem;
  }

  > .question {
    box-shadow: 0px 4px 20px 5px rgba(0, 0, 0, 0.05);
    margin: 0 1.5rem;
    padding-top: 8.05rem;
    border-radius: 1rem;
    padding-bottom: 4rem;

    > .submit-btn {
      background-color: #ffd415;
      font-size: 1.6rem;
      font-weight: bold;
      width: 20rem;
      height: 4.5rem;
      line-height: 4.5rem;
      margin: 0 auto;
      text-align: center;
      border-radius: 2.75rem;

      &:active {
        background-color: rgba(255, 212, 21, 0.5);
      }
    }
    > .title {
      display: flex;
      font-size: 1.8rem;
      font-weight: bold;
      padding: 0 3.5rem;
      margin-bottom: 3rem;
    }

    > .note {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
      padding-bottom: 1.5rem;
      border-bottom: 1px dotted #f3f3f3;
      margin: 0 1.5rem;
      &.success {
        color: #29e172;
      }
      &.error {
        color: #ff2424;
      }
    }

    > .tip {
      display: flex;
      padding-top: 1.45rem;
      margin: 0 1.5rem;
      color: #7d7d7d;
      font-size: 1.3rem;

      > span:nth-child(2) {
        flex: 1;
      }
    }

    > ul {
      margin-bottom: 3.5rem;
      > li {
        display: flex;
        align-items: center;
        min-height: 5.5rem;
        font-size: 1.6rem;
        font-weight: bold;
        padding: 1rem 2rem;
        margin: 0 1.5rem;
        border-radius: 1rem;
        background-color: #fafafa;
        margin-bottom: 1.5rem;

        > span:nth-child(2) {
          flex: 1;
        }
        > .flag {
          display: inline-block;
          height: 3rem;
          width: 3rem;
          line-height: 3rem;
          border-radius: 50%;
          text-align: center;
          background-color: #c0bdbd;
          color: #fff;
          margin-right: 1.5rem;
        }

        &.active {
          background-color: rgba(255, 212, 21, 0.1);
          &.success {
            background-color: rgba(41, 225, 114, 0.1);
            > .flag {
              background-color: #29e172;
              color: #fff;
            }
          }

          &.error {
            background-color: rgba(255, 36, 36, 0.1);
            > .flag {
              background-color: #ff2424;
              color: #fff;
            }
          }
          > .flag {
            color: #1a1a1a;
            background-color: #ffd415;
          }
        }
      }
    }
  }
}
