
























































.the-score-rank-panel {
  .section-container {
    &:first-child {
      margin-bottom: 1rem;
    }
  }
  .rule {
    padding-top: 2.5rem;
    font-size: 1.2rem;
    color: #ffe8c9;

    > li {
      margin-bottom: 0.5rem;
      > p {
        display: flex;
        margin: 0;

        > span {
          &:last-child {
            padding-left: 1rem;
          }
        }
      }
    }
  }
}
