






















.the-reward-list-container {
  color: #ffe8c9;
  display: flex;
  flex-wrap: wrap;
  padding-top: 2.5rem;

  > li {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 7.75rem;
    margin-bottom: 1.5rem;
    margin-right: 0.7rem;

    &:nth-child(4n) {
      margin-right: 0;
    }

    > .img-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffebd9;
      height: 7.75rem;
      width: 7.75rem;
      border-radius: 0.5rem;
      margin-bottom: 1rem;
      > img {
        width: 5rem;
        height: 5rem;
        // margin-bottom: 0.8rem;
      }
    }

    > .title {
      font-size: 1rem;
      margin-bottom: 0.5rem;
    }
    > .sub-title {
      font-size: 1rem;
      color: rgba(255, 232, 201, 0.6);
    }
  }
}
