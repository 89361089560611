





















































.the-rank {
  padding-top: 1.5rem;
  > .tabs {
    display: flex;
    font-size: 1.5rem;
    font-weight: bold;
    color: #7d7d7d;
    padding-bottom: 1.65rem;
    border-bottom: 1px solid rgba(243, 243, 243, 0.9);

    > .tab-item {
      margin-right: 2rem;
      position: relative;

      &.active {
        color: #1a1a1a;

        &::after {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: -1.65rem;
          content: '';
          background-color: #ffd415;
          height: 0.2rem;
          width: 2rem;
        }
      }
    }
  }
}
