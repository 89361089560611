
















































#the-tab-bar {
  height: 3.5rem;
  line-height: 3.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
  .item {
    font-weight: bold;
    font-size: 1.4rem;
    width: 8rem;
    color: #7f0605;
    background-color: #fc6327;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 0.5rem;
    margin-right: 0.4rem;

    &:first-child {
      margin-left: 1rem;
    }

    &:last-child {
      margin-right: 1rem;
    }

    &.active {
      color: #ffe8c9;
      background-image: url('../../../../assets/activity/spring-festival/选择@3x.png');
    }
  }
}
