









































.exchange-reward-container {
  padding-top: 3rem;
}
