.form {
  background-color: #fff;
  border-radius: 2rem 2rem 0 0;
  padding: 0 1.5rem;
  > .header {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    padding: 2rem 0;
  }

  > .body {
    padding: 1.5rem 2.25rem;
    border-bottom: 1px solid rgba(243, 243, 243, 0.5);
    border-top: 1px solid rgba(243, 243, 243, 0.5);
    > span {
      font-size: 1.3rem;
      width: 9rem;
      height: 3.5rem;
      line-height: 3.5rem;
      text-align: center;
      display: inline-block;
      border: 1px solid #f3f3f3;
      background-color: #fafafa;
      border-radius: 2rem;
      margin-right: 1.5rem;
      margin-bottom: 1.5rem;
      &:nth-child(3n) {
        margin-right: 0;
      }

      &.active {
        border: 1px solid #ffd218;
      }
    }
  }
}

.remain {
  color: #7d7d7d;
  font-size: 1.3rem;
  padding: 2.15rem 0;
  border-bottom: 1px solid rgba(243, 243, 243, 0.5);
}

.notice {
  text-align: center;
  color: #7d7d7d;
  padding: 1rem 0;
  font-size: 1.2rem;
}

.coinInputWrapper {
  text-align: center;
  padding: 1rem 0;

  .coinInput {
    padding: 1rem 2rem;
    background-color: #f3f3f3;
    border-radius: 1.75rem;
    font-size: 1.4rem;

    &.msg {
      width: 25rem;
    }
  }
}

.buttom {
  padding-top: 1.5rem;
  padding-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > .left {
    display: flex;
    align-items: center;
    font-size: 1.3rem;
    > img {
      height: 1.7rem;
      width: 1.7rem;
      margin-right: 1rem;
    }
  }

  > .btn {
    background: #ffd415 linear-gradient(135deg, #ffeb6f 0%, #ffd416 100%);
    border-radius: 1.3rem;
    font-size: 1.2rem;
    font-weight: bold;
    height: 2.6rem;
    width: 5.8rem;
    text-align: center;
    line-height: 2.6rem;
  }
}
