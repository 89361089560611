


























































.articleId {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  > .svgaCanvas {
    height: 2rem;
    width: 2rem;
    margin-right: 0.5rem;

    &.icon {
      background-size: 100%;
    }
  }

  > .id {
    font-size: 1.1rem;
    &.streamer {
      font-weight: bold;
      color: transparent; /* 设置文字颜色为透明 */
      background-size: 400%;
      animation: flowGlow 3s linear infinite alternate-reverse; /* 使用动画效果 */
      -webkit-background-clip: text; /* 将背景剪裁到文字 */
      -webkit-text-fill-color: transparent; /* 使文字颜色为透明 */
    }
  }
}

@keyframes flowGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}
