









































































.treasure-chest-desc {
  /deep/ {
    .app-layout-body {
      padding: 0 1.5rem;
      padding-bottom: 4rem;
      background-color: #5224e4;

      .page {
        padding-top: 28.8rem;
        > .hd-img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 44rem;
        }
      }
    }
  }
}
