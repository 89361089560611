.page {
  > .header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 52rem;
    background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/背景@3x.png);
    background-size: 100% 100%;

    > .logo {
      position: absolute;
      top: 5.9rem;
      left: 2.5rem;
      width: 16rem;
      height: 4rem;
    }

    > .content {
      position: absolute;
      left: 0;
      right: 0;
      top: 13.7rem;
      text-align: center;

      > .avatar {
        margin: 0 auto;
        height: 4.5rem;
        width: 4.5rem;
      }

      > .num {
        font-size: 4.9rem;
        font-weight: bold;
        color: #ffffff;
      }

      > .text {
        font-size: 1.4rem;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 4rem;
      }

      > .note {
        font-size: 1.4rem;
        color: #fff;
        .link {
          text-decoration: solid;
          color: #ffd416;
        }
      }

      > .phone-input {
        text-align: center;
        height: 4.5rem;
        width: 22.5rem;
        font-size: 1.3rem;
        border-radius: 2.35rem;
        margin-bottom: 1.5rem;

        &::placeholder {
          color: #7d7d7d;
        }
      }

      > .next-btn {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 auto;
        transform: rotate(180deg);
      }

      > .sms-code {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 4.5rem;
        width: 22.5rem;
        margin: 0 auto;
        border-radius: 2.35rem;
        background-color: #fff;
        overflow: hidden;
        margin-bottom: 1.5rem;
        font-size: 1.3rem;
        padding-left: 1.5rem;

        > input {
          flex: 1;
        }

        > .btn {
          background-color: rgba(255, 215, 31, 0.4);
          font-weight: bold;
          height: 100%;
          padding: 0 2.4rem;
          line-height: 4.5rem;
        }
      }

      > .btn {
        border: none;
        font-size: 1.3rem;
        font-weight: bold;
        width: 22.5rem;
        height: 4.5rem;
        line-height: 4.5rem;
        border-radius: 2.35rem;
        background: #c0bdbd linear-gradient(135deg, #ffeb6f 0%, #ffd416 100%);
        margin-bottom: 2rem;
      }

      > .back-btn {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 auto;
      }
    }
  }

  > .body {
    position: relative;
    z-index: 999;
    margin-top: 48rem;
    border-radius: 2rem 2rem 0 0;
    background-color: #fff;
    padding: 0 1.5rem;
    padding-top: 3rem;
    > .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 2rem;
      > .left {
        font-size: 1.6rem;
      }
      > .right {
        font-size: 1.2rem;
      }
    }

    > .list {
      display: flex;
      flex-wrap: wrap;
      > .item {
        width: 10.8rem;
        margin-bottom: 2rem;
        margin-right: 1.05rem;
        &:nth-child(3n) {
          margin-right: 0;
        }
        > img {
          height: 10.8rem;
          width: 10.8rem;
          border-radius: 0.5rem;
          object-fit: cover;
        }

        > .name {
          font-size: 1.4rem;
        }
      }
    }
  }
}
