.theHeader {
  position: relative;
  font-size: 0;

  > .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background-color: rgba(10, 9, 9, 0.8); */
    backdrop-filter: blur(30px);
  }

  &.fixed {
    position: fixed;
    z-index: 100;
    height: 21rem;
    width: 100%;
  }

  > .header-img {
    height: 21rem;
    width: 100%;
    object-fit: cover;
  }

  .info {
    position: absolute;
    z-index: 100;
    top: 10rem;
    left: 1.5rem;
    display: flex;
    color: #ffffff;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    > img {
      height: 8rem;
      width: 8rem;
      border-radius: 0.5rem;
      margin-right: 1.5rem;
      object-fit: cover;
    }

    .title {
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 1.05rem;
    }

    .desc {
      font-size: 1.4rem;
      margin-right: 2.4rem;
      display: flex;

      > .collection {
        font-size: #fff;
        font-size: 1.2rem;
        height: 2.4rem;
        line-height: 2.4rem;
        width: 5rem;
        text-align: center;
        border-radius: 1.5rem;
        background-color: rgba(255, 255, 255, 0.1);
        margin-right: 1rem;
      }

      > .creator {
        font-size: 1.4rem;
        > span:nth-child(2) {
          color: rgba(255, 255, 255, 0.5);
          margin-left: 0.5rem;
        }
      }
    }
  }
}
