






















































.the-check-in-button-container {
  padding-top: 3.5rem;
  > .title {
    font-size: 1.2rem;
    margin-top: 1.5rem;
    text-align: center;
  }
}
.the-check-in-button {
  position: relative;
  height: 19rem;
  width: 19rem;
  margin: 0 auto;
  border-radius: 50%;
  background-image: url('../../../../assets/webview/calendar/光束@3x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  > .display-field {
    position: absolute;
    top: 0.3rem;
    bottom: 0.3rem;
    left: 0.3rem;
    right: 0.3rem;
    border-radius: 50%;
    padding-top: 3.65rem;
    color: #fff;

    > .text {
      font-size: 1.3rem;
      text-align: center;
    }

    > .num {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 4rem;
      font-weight: bold;
    }
  }

  > .btn {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    top: 15.35rem;
    background-color: #ffd415;
    width: 17.5rem;
    height: 4rem;
    color: #1a1a1a;
    border-radius: 3.03rem;
    font-size: 1.5rem;
    text-align: center;
    line-height: 4rem;
    font-weight: bold;

    &:active {
      background-color: #ffe15a;
    }
  }
}
