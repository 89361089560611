

































.record-blank-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5rem;
  > .title {
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
    margin: 0;
    margin-bottom: 4.4rem;
  }

  > ul {
    > li {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      margin-bottom: 2rem;

      > img {
        height: 1.9rem;
        width: 1.9rem;
        margin-right: 1.65rem;
      }
    }
  }
}
