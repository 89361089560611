




























































.announcement-detail {
  position: relative;

  // * {
  //   -moz-user-select: none;
  //   -khtml-user-select: none;
  //   user-select: none;
  // }
}
.content {
  padding: 1.5rem;
  font-size: 1.5rem;
  padding-bottom: 36rem;
  word-break: break-all;
  // user-select: text !important;

  /deep/ {
    p {
      margin: 0;
    }

    img {
      object-fit: contain;
      max-width: 100%;
    }
  }
}

.feedback {
  position: absolute;
  bottom: 12rem;
  left: 0rem;
  right: 0rem;
  > .btn-group {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.5rem;
    > .btn {
      height: 4rem;
      line-height: 4rem;
      text-align: center;
      width: 11.25rem;
      border-radius: 3rem;
      background-color: #fafafa;
      font-size: 1.4rem;
      margin-right: 2.5rem;

      &.active {
        background-color: #ffd415;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  > .concat {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 4.5rem;
    border-radius: 1rem;
    background-color: #fafafa;
    font-size: 1.3rem;
    color: #7d7d7d;
    margin: 0 1.5rem;
    padding: 0 1.5rem;

    > img {
      height: 1.1rem;
      width: 1.1rem;
    }
  }
}
