


















.the-gift-list-container {
  background-color: #fafafa;
  height: 100%;
  font-size: 0;
  > .header {
    height: 2rem;
    line-height: 2rem;
    text-align: center;
    font-size: 1.2rem;
    color: #7d7d7d;
    background-color: #fff;
  }

  > .list {
    padding: 1.5rem;
    height: calc(100% - 2rem);
    overflow: scroll;
  }
}
