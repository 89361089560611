








































































































































































































.open-app-btn {
  position: fixed;
  z-index: 99;
  right: 1rem;
  bottom: 0.5rem;
  height: 4rem;
  line-height: 4rem;
  width: 11.5rem;
  text-align: center;
  background-color: #ffd300;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 40px 6px 40px 40px;
  overflow: hidden;
}
.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  height: 5rem;
  background-color: #2b2613;
  padding: 0 1.5rem;

  .avatar {
    height: 2.6rem;
    width: 2.6rem;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
  }

  .logo {
    width: 8rem;
    height: 3rem;
  }

  > .search-fields {
    display: flex;
    align-items: center;
    background-color: #403c2b;
    // padding: 0.7rem 2rem;
    height: 3rem;
    width: 16rem;
    padding: 0 2rem;
    border-radius: 1.85rem;
    overflow: hidden;
    > .search-icon {
      height: 1.6rem;
      width: 1.6rem;
      margin-right: 1rem;
    }
    > input {
      flex: 1;
      background-color: #403c2b;
      color: #fff;
      font-size: 1.4rem;
      width: 10rem;
    }
  }

  > .download-link {
    color: #fff;
    font-size: 1.3rem;
  }
}

// .app-layout-body {
//   padding-top: 5rem;
//   min-height: calc(100vh - 5rem);
// }
