









































.the-task-item {
  display: flex;
  justify-content: space-between;
  padding: 1.6rem 0;
  border-bottom: 1px solid rgba(243, 243, 243, 0.5);
  .title {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 0.8rem;
  }

  .sub-title {
    color: #7d7d7d;
    font-weight: 1.2rem;
  }

  .btn {
    display: inline-block;

    border-radius: 1.2rem;
    font-size: 1.2rem;
    font-weight: bold;
    min-width: 5rem;
    height: 2.4rem;
    line-height: 2.4rem;
    text-align: center;

    &.unreceive {
      background-color: #ffd415;
    }

    &.done {
      color: #7d7d7d;
      background-color: #fff;
    }

    &.uncomplete {
      border: 1px solid #e8e7e7;
      background-color: #fff;
    }
  }
}
