



























































































#medal-detail-swiper {
  height: 23rem;
  margin-bottom: 2.2rem;
}

/deep/ {
  .app-layout-body {
    padding-top: 4.5rem;
    background-color: #241c34;
    // visibility: hidden;

    .level-title {
      font-size: 1.3rem;
      color: #fff;
      margin-bottom: 2rem;
      text-align: center;
    }
  }
}
