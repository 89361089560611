













.the-checkin-record-container {
  min-height: 100vh;
  background-color: #fafafa;
  padding: 0 1.5rem;
  padding-top: 2.5rem;
  padding-bottom: 5rem;
}
