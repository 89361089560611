@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  // 80% {
  //     transform: scale3d(0.97, 0.97, 0.97);
  // }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
}

.chest {
  height: 100vh;
  width: 100vw;
}


.modal {
  display: none;
  position: fixed;
  height: 31.3rem;
  width: 30rem;
  top: 20rem;
  z-index: 999;
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/燃将任务-弹窗@3x.png');
  background-size: 100% 100%;
  text-align: center;
  padding-top: 9.95rem;
  

  &.show {
    display: block;
    animation: bounceIn 1000ms 100ms both;
  }

  >.content {
    padding: 0 3.5rem;
    display: flex;
    justify-content: space-between;
    >.item {
      width: 10rem;
      height: 10rem;
      background-color: #3E1304;
      border-radius: 0.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      >.img {
        height: 6rem;
        width: 6rem;
        margin-bottom: 1rem;
      }
    
      >.name {
        color: #FDF9EE;
        font-size: 1.2rem;
      }
    }
  }

 

  >.confirm {
    position: absolute;
    font-size: 1.5rem;
    color: #FDF9EE;
    background-color: #B05424;
    height: 4rem;
    width: 20rem;
    border-radius: 2rem;
    line-height: 4rem;
    text-align: center;
    bottom: 4.65rem;
    left: 50%;
    transform: translateX(-50%);
  }

  .closeIcon {
    position: absolute;
    height: 3rem;
    width: 3rem;
    bottom: -5.5rem;
    left: 50%;
    transform: translateX(-50%);
  }
  
}