
















.the-create-article-button {
  position: fixed;
  z-index: 999;
  background-color: #ffd415;
  left: 2.5rem;
  right: 2.5rem;
  font-size: 1.6rem;
  font-weight: bold;
  bottom: 3.5rem;
  text-align: center;
  padding: 1.45rem 0;
  border-radius: 3rem;

  &:active {
    background-color: rgba(255, 212, 21, 0.8);
  }
}
