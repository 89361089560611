

































































































@import './invite-game.scss';

.notice {
  font-size: 1.8rem;
  color: #fff;
  text-align: center;
  padding-top: 50%;
}
