





























.activity-modal {
  .mask {
    position: fixed;
    z-index: 3999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .modal-body {
    position: fixed;
    z-index: 4000;
    width: 32.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;

    .close {
      position: absolute;
      z-index: 999;
      height: 3rem;
      width: 3rem;
      bottom: -5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
