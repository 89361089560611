


























.the-desc {
  .desc {
    font-size: 1.5rem;
  }
}
