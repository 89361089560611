










































































.the-newest {
  position: relative;
  background-color: #ffffff;
  margin-top: 0.5rem;
  padding-top: 2.5rem;

  &::before {
    content: '';
    position: absolute;
    top: -0.5rem;
    background-color: #fafafa;
    height: 0.5rem;
    width: 100%;
  }

  .body {
    padding: 0 1.5rem;
    .item {
      display: flex;
      align-items: flex-end;
      padding: 2rem 0;
      border-top: 1px solid rgba(232, 231, 231, 0.5);
      > .left {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 1.8rem;
        max-width: 100%;
        > .title {
          font-size: 1.6rem;
          margin-bottom: 1rem;
        }

        > .desc {
          color: #7d7d7d;
          font-size: 1.2rem;
          margin-bottom: 1rem;
        }
        > .extra {
          font-size: 1.2rem;
          > span {
            margin-right: 0.8rem;
          }
        }
      }
      > .avatar {
        height: 5rem;
        width: 5rem;
        border-radius: 0.5rem;
      }
    }
  }
}
