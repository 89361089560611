























































.the-recharge-gift-panel {
  color: #ffe8c9;
  // margin: 0 1rem;
  padding: 2.4rem 3.75rem;
  border-radius: 1rem;

  > .title {
    font-size: 1.4rem;
    margin-bottom: 3rem;
  }

  > .sub-title {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 3rem;
  }

  p {
    margin: 0;
    text-align: center;
  }

  > .progress-bar {
    height: 1.5rem;
    border-radius: 7.5rem;
    background-color: rgba(165, 41, 31, 1);
    margin-bottom: 1.3rem;
    overflow: hidden;
    > .progress {
      // width: 30%;
      height: 100%;
      border-radius: 7.5rem;
      background-color: #f8ba7b;
    }
  }

  > .desc {
    font-weight: 500;
    display: flex;
    font-size: 1.3rem;
    justify-content: space-between;
    margin-bottom: 3rem;
  }

  .the-btn {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
}
