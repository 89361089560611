
















.the-task-list-container {
  margin-top: 2.5rem;
  > .title {
    font-size: 1.4rem;
    font-weight: bold;
    padding-bottom: 1.5rem;
    border-bottom: 1px solid rgba(243, 243, 243, 0.5);
    > span {
      color: red;
      font-size: 1.2rem;
      font-weight: normal;
    }
  }
}
