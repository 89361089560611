



























p {
  margin: 0;
}
.the-ablum-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(232, 231, 231, .5);
  padding: 1rem;
  border-radius: 1rem;
  box-sizing: border-box;
  margin-bottom: 1rem;
  >.left {
    display: flex;
    align-items: center;

    img {
      height: 5rem;
      width: 5rem;
      border-radius: 0.2rem;
      margin-right: 1rem;
      object-fit: cover;
    }

    .title {
      font-size: 1.6rem;
    }

    .extra {
      font-size: 1.3rem;
      color: #7D7D7D;
    }
  }
  
  .allow-right {
    height: 1.1rem;
    width: 1.1rem;
  }
}
