
















.the-section {
  background-color: #fff;
  border-radius: 1.5rem;
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
  > .header {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    background-image: url('../../../../assets/webview/treasure-chest-desc/宝箱详情@3x.png');
    height: 3.9rem;
    width: 12.5rem;
    background-size: 100% 100%;
    font-size: 1.6rem;
    color: #fff;
    font-weight: bold;
    line-height: 3.9rem;
    text-align: center;
  }
}
