

























@keyframes scrollTo {
  to{
    transform: translateX(calc(-100vw - 150px));
  }
}

.bullet-screen-item {
  // position: absolute;
  display: inline-block;
  width: 150px;
  transform: translateX(100%);
  animation: scrollTo linear 3s forwards;
}
