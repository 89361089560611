





































































































.swiper-container {
  height: 17.5rem;
  .swiper-slide {
    position: relative;
    width: 24rem;
    background-color: #009e6d;
    border-radius: 1rem;

    > img {
      height: 100%;
      width: 100%;
    }

    > .title {
      font-size: 1.3rem;
      color: #ffffe3;
      position: absolute;
      bottom: 3rem;
      width: 100%;
      text-align: center;
      font-weight: bold;
    }
  }
}
