


































.the-tab-bar {
  position: relative;
  height: 3.7rem;
  background-color: #fafafa;
  border: 1px solid #e8e7e7;
  display: flex;
  font-size: 1.4rem;
  font-weight: bold;
  align-items: center;
  margin: 1rem;
  margin-bottom: 1.5rem;
  border-radius: 0.5rem;
  padding: 0 0.2rem;

  > li {
    position: relative;
    z-index: 99;
    flex: 1;
    text-align: center;
    height: 3.3rem;
    line-height: 3.3rem;
    border-radius: 0.5rem;
    color: #c0bdbd;
    &.active {
      color: #1a1a1a;
    }

    &.bar {
      position: absolute;
      z-index: 9;
      width: calc((100% - 0.4rem) / 4);
      background-color: #fff;
      transition: all 0.5s;
      transform: translateX(0px);
    }
  }
}
