































































.container {
  color: #1a1a1a;
  padding-top: 2.5rem;
  min-height: 100vh;
  background: linear-gradient(180deg, rgba(220, 155, 132, 0.2) 0%, rgba(223, 164, 143, 0) 50rem);

  .article-detail {
    &.fixed {
      position: fixed;
      // top: 0;
      width: 100vw;
    }
  }

  .top {
    position: fixed;
    z-index: 99;
    bottom: 10rem;
    right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: rgba(255, 255, 255, 0.9);

    > img {
      height: 1.5rem;
      width: 1.5rem;
    }
  }
}
