.team {
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 2rem;
  > .header {
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
  }

  .swiperSlide {
  }

  .img {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
  }

  .title {
    color: #7d7d7d;
    font-size: 1.2rem;
  }
}
