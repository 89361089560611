
































.the-time-line-item {
  padding: 0 3.5rem;
  display: flex;
  margin-bottom: 3.5rem;

  &:last-child {
    > .left {
      &::after {
        display: none;
      }
    }
  }
  > .left {
    position: relative;
    margin-right: 2.5rem;
    &::after {
      content: '';
      position: absolute;
      top: 3.1rem;
      left: 50%;
      transform: translateX(-50%);
      width: 1px;
      height: 3.2rem;
      background-color: #f3f3f3;
    }
    > img {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  > .right {
    > .title {
      font-weight: bold;
      font-size: 1.4rem;
      margin-bottom: 0.5rem;

      &.error {
        color: #ff2424;
      }
    }

    > .date {
      font-size: 1.1rem;
      color: #c0bdbd;
      margin-bottom: 0.5rem;
    }
  }
}
