.title {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 50%;
  color: #ffffff;
  transform: translateY(-50%);
}

.page {
  background-color: #ff88ee;
  .page-wrapper {
    // overflow: hidden;
    position: relative;
    &::before {
      position: absolute;
      content: '';
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/音乐@2x.png');
      height: 2.8rem;
      width: 2.8rem;
      background-size: 100% 100%;
      top: 2.6rem;
      right: 1.5rem;
      animation: spin 4s linear infinite;
    }
  }
}

.bottom {
  height: 2.7rem;
  width: 100%;
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/logo@3x.png');
  background-size: 13.2rem 2.7rem;
  background-repeat: no-repeat;
  bottom: -2.56rem;
  left: calc(50% - 5.35rem);
  padding-top: 2.45rem;
  padding-bottom: 4rem;
  background-position: center;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
