p {
  margin: 0;
}

.id-field {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.modal-note {
  text-align: center;
  margin-bottom: 1rem;
  padding-top: 1rem;
}

.modal-user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  padding-bottom: 1rem;

  > .nickanme {
    margin-bottom: 0.5rem;
  }

  > .avatar {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 0.5rem;
  }
}

.recharge-page {
  background-color: #fafafa;
  min-height: calc(100% - 5rem);
  background-size: 100% 26.5rem;
  background-repeat: no-repeat;
  padding-bottom: 14rem;

  .first-recharge-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(255, 123, 82, 0.2);
    font-size: 1.3rem;
    padding: 0.75rem 1.5rem;
    border-radius: 2.25rem;
    margin-bottom: 1rem;

    > .btn {
      background-color: #ff7b52;
      font-size: 1.4rem;
      color: #fff;
      height: 3rem;
      line-height: 3rem;
      width: 6.5rem;
      text-align: center;
      border-radius: 2.2rem;
    }
  }

  > .header {
    background-color: #fff;
    padding: 0 1.5rem;
    padding-top: 3rem;
    padding-bottom: 1.5rem;
    > .user-info {
      height: 5.55rem;
      padding: 1rem;
      padding-left: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: rgba(255, 211, 19, 0.4);
      border: 1px solid rgba(255, 211, 19, 1);
      border-radius: 55.5px;
      margin-bottom: 2rem;

      > input {
        background-color: transparent;
        flex: 1;
        font-size: 1.4rem;
        padding: 1rem;
        font-weight: bold;
      }

      > .btn {
        color: #fff;
        background: #dde0e8;
        width: 6.7rem;
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        padding: 0.5rem 0;
        border-radius: 2rem;

        &.enable {
          background-color: #ffd415;
          color: black;
        }
      }

      > .left {
        display: flex;
        align-items: center;
        > .avatar {
          height: 4.8rem;
          width: 4.8rem;
          border-radius: 50%;
          margin-right: 1rem;
        }

        > .nickname {
          font-size: 1.4rem;
        }
      }

      > .change-btn {
        color: black;
        background: #fff;
        width: 6.7rem;
        text-align: center;
        font-size: 1.4rem;
        font-weight: bold;
        padding: 0.5rem 0;
        border-radius: 2rem;
      }
    }

    .diamond-count {
      text-align: center;
      font-size: 4rem;
      font-weight: bold;
    }

    .unit {
      text-align: center;
      font-size: 1.4rem;
      margin-bottom: 2.5rem;
    }
  }

  > .btn-group {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    position: fixed;

    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 1.4rem 1rem;

    > .fish_coin {
      font-size: 1.4rem;
      padding-bottom: 1.4rem;
    }

    > .btn {
      width: 100%;
      font-size: 1.5rem;
      padding: 1rem;
      background-color: #ffd415;
      border-radius: 10rem;
      text-align: center;

      &:active {
        background-color: rgba(255, 212, 21, 0.5);
      }
    }
  }
}

.tab-bar {
  padding: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    font-size: 1.4rem;
    font-weight: bold;
    margin-right: 2.5rem;
    color: #c0bdbd;
    &:last-child {
      margin-right: 0;
    }

    &.active {
      color: black;
    }
  }
}

ul {
  padding: 0 1.5rem;
  display: grid;
  grid-template-columns: repeat(3, 10.8rem);
  column-gap: 1.05rem;
  row-gap: 1rem;

  > li {
    position: relative;
    padding: 1.5rem 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    height: 15rem;
    border-radius: 0.5rem;
    border: 2px solid transparent;

    > .extra {
      position: absolute;
      top: -2px;
      left: -2px;
      content: '首冲加送';
      font-size: 0.9rem;
      color: #fff;
      background-color: #ff7b52;
      padding: 0.2rem 0.5rem;
      border-radius: 0.5rem 0 0.5rem 0;
    }

    &.active {
      border: 2px solid #ffd415;
      &::after {
        content: '';
        position: absolute;
        top: -2px;
        right: -2px;
        height: 2.3rem;
        width: 2.3rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/mine/选择@3x.png');
        background-size: 100% 100%;
      }

      > .price {
        background-color: #ffd415;
      }
    }

    > .diamond {
      font-size: 1.5rem;
      font-weight: bold;
    }

    > .price {
      font-size: 1.3rem;
      font-weight: bold;
      width: 7rem;
      padding: 0.4rem 0;
      background-color: rgba(255, 211, 23, 0.1);
      border-radius: 1.5rem;
    }
    > img {
      height: 7rem;
      width: 7rem;
    }
  }
}

.ensure-btn {
  background-color: #ffd313;
  border-radius: 2.2rem;
  height: 4.4rem;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  line-height: 4.4rem;
  margin: 2rem;
}

.payMethodContainer {
  padding: 0 1.5rem;
  padding-top: 2.5rem;
  padding-bottom: 3.5rem;
  .header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 2rem;
    margin-bottom: 2.5rem;

    > .icon {
      height: 1.7rem;
      width: 1.7rem;
      background-size: 100% 100%;
      margin-right: 1rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/recharge/钻石@3x.png');

      &.fish {
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/recharge/大鱼币1@3x.png');
      }
    }

    > .price {
      font-size: 1.4rem;
      font-weight: bold;
      > span {
        margin-right: 2rem;
        &.first {
          position: relative;
          &::after {
            position: absolute;
            content: '';
            background-color: #c0bdbd;
            height: 1.3rem;
            width: 1px;
            right: -1rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }

        &.second {
          color: #ff7b52;
        }
      }
    }
  }
  .body {
    border-bottom: 1px solid #f3f3f3;
    margin-bottom: 3rem;
    > .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2.5rem;

      > .left {
        font-size: 1.4rem;
        display: flex;
        align-items: center;

        > .label {
          background-color: #ff7b52;
          font-size: 1rem;
          color: #fff;
          padding: 0 1rem;
          border-radius: 0.85rem;
          margin-left: 1rem;
        }

        > .icon {
          height: 2.8rem;
          width: 2.8rem;
          margin-right: 1rem;
        }
      }

      > .checkbox {
        height: 1.6rem;
        width: 1.6rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/recharge/no_choice@3x.png');
        background-size: 100% 100%;

        &.selected {
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/recharge/choice@3x.png');
        }
      }
    }
  }

  .btn {
    background-color: #ffd313;
    border-radius: 2.2rem;
    height: 4.4rem;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    line-height: 4.4rem;
  }
}
