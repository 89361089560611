



























.the-ost-form-item {
  display: flex;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid rgba(232, 231, 231, 0.8);
  margin-bottom: 2rem;
  > .title {
    font-size: 1.4rem;
    margin-right: 1.2rem;
  }
  > .ost-upload-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fafafa;
    height: 8rem;
    width: 8rem;
    font-size: 3rem;
  }
}
