













.the-body-container {
  min-height: calc(100% - 38.3rem);
  padding-top: 0.8rem;
  padding-bottom: 11rem;
  background-color: #b81e20;
}
