











































































































.container {

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.5rem;
    height: 6rem;
    background-color: #FAFAFA;
    font-size: 1.5rem;
    font-weight: bold;

    .tabs {
      >.item {
        color: #7D7D7D;
        margin-left: 3.05rem;
        &.active {
          color: #000;
        }
      }
    }
  }

  .body {
    padding: 1.5rem;
    height: calc(100vh - 11rem);
    overflow: scroll;
  }

  .add-btn {
    position: fixed;
    right: 2rem;
    bottom: 6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
    height: 4.3rem;
    width: 4.3rem;
    background: linear-gradient(#FFE557,#FFD416);
    border-radius: 50%;
  }

  /deep/ {
    .van-popup {
      border-radius: 1rem 1rem 0 0;
      padding: 1.5rem;
    }
  }
}
