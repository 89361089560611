
































.avatar-modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);

  > .avatar-modal-content {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.5rem;
    right: 1.5rem;
    background-color: #fff;
    border-radius: 1.5rem;
    min-height: 25rem;
    padding-bottom: 3rem;
    > .header {
      position: absolute;
      top: -1.5rem;
      left: 50%;
      transform: translateX(-50%);
      padding-top: 0.5rem;
      background-image: url('../../../../assets/activity/spring-festival/弹窗-标题@3x.png');
      height: 4rem;
      width: 19.9rem;
      background-size: 100% 100%;
      text-align: center;
      font-size: 1.5rem;
      font-weight: bold;
    }

    > .close-btn {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 3rem;
      width: 3rem;
      bottom: -5.5rem;
    }
  }
}
