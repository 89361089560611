.page {
  padding-top: 3rem;
  background-color: #362639;
  > .top {
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    padding-bottom: 1.2rem;

    > .avatar {
      height: 4.8rem;
      width: 4.8rem;
      margin-right: 1rem;
      border-radius: 50%;
    }

    > .right {
      color: #fff;
      > .nickname {
        font-size: 1.5rem;
        font-weight: bold;
      }
    }
  }

  > .extra {
    padding: 0 1.5rem;
    background-color: #3d2e40;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.5);
    height: 2.5rem;
    font-size: 1.2rem;

    > .right {
      display: flex;
      align-items: center;
      > .icon {
        height: 1.1rem;
        width: 1.1rem;
        background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/dub/grzx_go@3x.png);
        background-size: 100% 100%;
      }
    }
  }

  > .video-wrapper {
    position: relative;
    width: 100%;
    height: 22rem;
    > .video {
      position: absolute;
      width: 100%;
      height: 22rem;
      background-color: #000;
    }

    > .play-btn {
      position: absolute;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/dub/播放@3x(4).png');
      height: 5rem;
      width: 5rem;
      left: calc(50% - 2.5rem);
      top: calc(50% - 2.5rem);
      z-index: 999;
      background-size: 100% 100%;
    }
  }

  > .lyric {
    height: 25rem;
    font-size: 1.4rem;
    font-weight: bold;
    color: rgba(254, 255, 254, 0.5);
    text-align: center;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    line-height: 3.5rem;
    overflow: hidden;

    > .content {
      transition: all 0.5s;
      > .active {
        font-size: 1.8rem;
        color: #fefffe;
      }
    }
  }

  > .controls {
    position: absolute;
    top: 37rem;
    right: 1.5rem;
    font-size: 1.1rem;
    color: rgba(255, 255, 255, 0.8);
    text-align: center;
    > div {
      margin-bottom: 2rem;
    }

    i {
      display: inline-block;
      height: 2.5rem;
      width: 2.5rem;
      background-size: 100% 100%;
      &.heart {
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/dub/点赞 2@3x.png');
      }

      &.comment {
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/dub/评论@3x.png');
      }

      &.share {
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/dub/编组@3x.png');
      }
    }
  }

  > .user-group {
    padding-left: 1.5rem;
    > .user-nfo {
      display: flex;
      align-items: center;
      margin-bottom: 2rem;
      > .avatar {
        height: 4.3rem;
        width: 4.3rem;
        border-radius: 50%;
        margin-right: 1rem;
      }

      .extra {
        color: rgba(255, 255, 255, 0.5);
        font-size: 1.2rem;
      }

      .nickname {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        color: #fff;
        font-weight: bold;
        margin-bottom: 0.5rem;
        > .gender {
          height: 1.2rem;
          width: 1.2rem;
          margin-left: 0.5rem;
          background-size: 100% 100%;
          background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/dub/news_man@3x.png);

          &.female {
            background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/dub/news_woman@3x.png);
          }
        }
      }
    }
  }

  > .progress-bar {
    height: 2px;
    background-color: rgba(255, 255, 255, 0.1);
    overflow: hidden;
    margin-bottom: 2.5rem;
    > div {
      background-color: rgba(255, 255, 255, 1);
      height: 2px;
    }
  }

  > .list {
    padding: 0 1rem;
    border-radius: 1.5rem 1.5rem 0 0;
    background-color: #fafafa;
    > .header {
      padding-top: 2rem;
      padding-bottom: 1.5rem;
      color: rgba(192, 189, 189, 1);
      font-size: 1.2rem;
      text-align: center;
    }

    > .body {
      display: flex;
      flex-wrap: wrap;

      > .item {
        position: relative;
        width: 17.25rem;
        margin-right: 1rem;
        background-color: #fff;
        border-radius: 0.3rem;
        overflow: hidden;
        margin-bottom: 1rem;

        &::after {
          position: absolute;
          content: '';
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/dub/播放@3x(4).png');
          height: 2.7rem;
          width: 2.7rem;
          background-size: 100% 100%;
          top: 13.8rem;
          left: 13.5rem;
        }

        &:nth-child(2n) {
          margin-right: 0;
        }

        > .photo {
          height: 17.5rem;
          width: 100%;
        }

        > .content {
          padding: 1rem;

          > .title {
            font-size: 1.3rem;
            font-weight: bold;
            margin-bottom: 1rem;
          }

          > .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1.1rem;
            color: #7d7d7d;

            > .left {
              display: flex;
              align-items: center;
              > .avatar {
                border-radius: 50%;
                height: 1.8rem;
                width: 1.8rem;
                margin-right: 0.5rem;
              }
            }

            > .right {
              position: relative;
              &::before {
                content: '';
                position: absolute;
                border-left: 0.7rem solid #c0bdbd;
                border-top: 0.5rem solid transparent;
                border-bottom: 0.5rem solid transparent;
                left: -1rem;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
  }
}
