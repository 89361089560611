
































.modal-dialog {
  > .mask {
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }

  > .modal {
    position: fixed;
    z-index: 1001;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 30.5rem;
    min-height: 15rem;
    background-color: #fff;
    padding: 0 1.5rem;
    border-radius: 1.5rem;

    > .body {
      text-align: center;
      padding: 3.25rem;
      font-size: 1.5rem;
      border-bottom: 1px solid rgba(232, 231, 231, 0.3);
    }

    > .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2rem 1.25rem;

      &.center {
        justify-content: center;
      }

      > button {
        font-size: 1.4rem;
        height: 4rem;
        width: 11.25rem;
        border-radius: 2rem;
        background-color: #fafafa;
        border: 1px solid rgba(232, 231, 231, 0.5);

        &.primary {
          background-color: #ffd415;
          border: none;
        }
      }
    }
  }
}
