






































































.red-pocket-modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1999;

  > .content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 33.25rem;
    height: 39rem;

    > .header {
      position: absolute;
      top: 3rem;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      color: #ffe8c9;
      > .avatar {
        height: 3rem;
        width: 3rem;
        border-radius: 50%;
        border: 2px solid #ffe8c9;
        margin-right: 1rem;
      }
    }

    .closed {
      height: 100%;
      width: 100%;
      background-image: url('../../../../assets/activity/spring-festival/red_pocket.png');
      background-size: 100% 100%;
    }

    .opened {
      padding-top: 7.9rem;
      height: 100%;
      width: 100%;
      background-image: url('../../../../assets/activity/spring-festival/红包打开@3x.png');
      background-size: 100% 100%;

      > .light-bg {
        position: relative;
        height: 20.7rem;
        width: 20.7rem;
        background-image: url('../../../../assets/activity/spring-festival/light.png');
        background-size: 100% 100%;
        margin: 0 auto;

        > .reward {
          position: relative;
          width: 12.5rem;
          height: 12.5rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        > .text {
          position: absolute;
          bottom: 0.65rem;
          left: 50%;
          transform: translateX(-50%);
          font-size: 1.7rem;
          font-weight: bold;
          text-align: center;
        }
      }

      > .close-btn {
        height: 3rem;
        width: 3rem;
        position: absolute;
        bottom: -5.5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .red-pocket-bg {
      height: 100%;
      width: 100%;
    }
  }
}
