





































.list-item {
  display: block;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e8e7e7;
  margin-bottom: 2rem;
  p {margin: 0;}
  .content1 {
    .top {
      display: flex;
      align-items: center;
      padding-top: 2rem;
      margin-bottom: 1.2rem;

      .avatar {
        height: 9rem;
        width: 9rem;
        border-radius: 0.5rem;
        overflow: hidden;

        > img {
          height: 100%;
          width: 100%;
        }
      }

      .content2 {
        flex: 1;
        margin-left: 1.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 9rem;

        .title {
          font-size: 1.6rem;
          font-weight: bold;
        }

        .tag-list {
          .tag {
            display: inline-block;
            margin-right: 0.8rem;
            border: 1px solid #c0bdbd;
            border-radius: 1.2rem;
            padding: 0 0.8rem;
            line-height: 1.9rem;
            font-size: 1rem;
          }
        }
      }
    }

    .auth-name {
      font-size: 1.3rem;
      color: #7d7d7d;
    }

    .bottom {
      font-size: 1.3rem;
      color: #7d7d7d;

      .label {
        color: #1a1a1a;
      }
    }
  }

  .num {
    display: inline-block;
    color: #ffffff;
    font-size: 1.3rem;
    width: 4.35rem;
    height: 2.2rem;
    line-height: 2.2rem;
    text-align: center;
    border-radius: 0.3rem;
    background-color: #c0bdbd;

    &.num1 {
      background-color: #ff5151ff;
    }

    &.num2 {
      background-color: #ff7b52;
    }

    &.num3 {
      background-color: #ffd415;
    }

    &.num4 {
      background-color: #c0bdbd;
    }
  }
}
