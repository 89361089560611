.page {
  background-color: #fafafa;
  min-height: 100vh;
  display: flex;
  padding: 4rem;
  padding-top: 10.5rem;
  justify-content: space-between;
  > .item {
    padding-top: 2.5rem;
    font-size: 1.4rem;
    text-align: center;
    width: 12.7rem;
    height: 15rem;
    border-radius: 1rem;
    background-color: #fff;
    > img {
      height: 6rem;
      width: 6rem;
    }
  }
}
