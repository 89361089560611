.page {
  background-color: #fafafa;
  .container {
    padding-top: 0.5rem;
    height: 100%;

    > .list {
      position: relative;
      height: calc(100% - 3.3rem - 2px - 2rem);
      overflow-y: scroll;
    }
  }
}
