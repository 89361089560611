.page {
  height: 100vh;
  overflow-y: scroll;
}

.header {
  height: 27.3rem;
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/player-sheet/背景@3x.png');
  background-size: 100% 100%;
  padding: 0 2.5rem;
  padding-top: 8.6rem;

  > .title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.4rem;

    .text {
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 1.5rem;
    }

    .flag {
      background-color: #fef1e9;
      font-size: 1.3rem;
      padding: 0.5rem 1.1rem;
      border-radius: 1.5rem;
      width: 11rem;
      text-align: center;
    }

    > .avatar {
      height: 8rem;
      width: 8rem;
      border-radius: 0.5rem;
      margin-left: 4.4rem;
    }
  }

  > .extra {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .time {
      font-size: 1.2rem;
      color: #7d7d7d;
      > span:first-child {
        margin-right: 1.5rem;
      }
    }

    > .playBtn {
      height: 3.7rem;
      width: 3.7rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/player-sheet/播放@3x.png');
      background-size: 100% 100%;

      &.playing {
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/share/player-sheet/暂停@3x.png');
      }
    }
  }
}

.body {
  padding: 0 1.5rem;
  .desc {
    padding-top: 2.5rem;
    margin-bottom: 2rem;
    > .title {
      font-size: 1.6rem;
      font-weight: bold;
    }

    > .content {
      color: #7d7d7d;
      font-size: 1.5rem;
      padding: 1.5rem 0;
      border-bottom: 1px solid rgba(243, 243, 243, 0.7);
    }
  }
}
