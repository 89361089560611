.page {
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/author-center/背景@3x.png');
  background-size: 100% 50rem;
  padding-top: 9rem;
  background-repeat: no-repeat;
  background-color: #fafafa;
  padding-bottom: 6rem;

  > .levelDesc {
    position: relative;
    top: -1.5rem;
    > .btn {
      width: 17.5rem;
      height: 5rem;
      border-radius: 2.6rem;
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
      line-height: 5rem;
      margin: 0 auto;
      background: linear-gradient(180deg, #ffffff 0%, #fffdce 100%);
      margin-bottom: 2rem;
    }

    > .text {
      text-align: center;
      font-size: 1.2rem;
      color: rgba(26, 26, 26, 0.5);
    }

    .level {
      height: 6rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 1.2rem;
      margin-bottom: 2rem;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.3) 50%,
        rgba(255, 255, 255, 0.1) 100%
      );

      > .levelNum {
        font-size: 2.5rem;
        font-weight: bold;
      }
    }
  }

  > .myRights {
    margin: 0 1.5rem;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.03);
    border-radius: 1rem;
    padding: 0 1.5rem;
    margin-bottom: 1.5rem;
    > .header {
      display: flex;
      justify-content: space-between;
      font-size: 1.3rem;
      padding: 1.5rem 0;
      border-bottom: 1px solid rgbodyba(243, 243, 243, 0.5);
      > .left {
        font-weight: bold;
      }

      > .right {
        color: #7d7d7d;
        display: flex;
        align-items: center;

        > .icon {
          display: inline-block;
          height: 1.1rem;
          width: 1.1rem;
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/author-center/grzx_go@3x.png');
          background-size: 100% 100%;
        }
      }
    }

    > .body {
      display: flex;
      padding: 2.5rem 0;
      flex-wrap: wrap;
      // justify-content: space-between;

      > .item {
        position: relative;
        font-size: 1.2rem;
        text-align: center;
        width: 6rem;
        text-align: center;
        margin-right: 2.5rem;
        margin-bottom: 1.5rem;
        &:nth-child(4n) {
          margin-right: 0;
        }

        > .lock {
          position: absolute;
          height: 1.7rem;
          width: 1.7rem;
          right: 0;
          top: 3.5rem;
        }

        > img {
          height: 5.5rem;
          width: 5.5rem;
        }
      }
    }
  }

  > .levelGuide {
    margin: 0 1.5rem;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.03);
    border-radius: 1rem;
    padding: 0 1.5rem;

    > .header {
      font-size: 1.3rem;
      padding: 1.5rem 0;
      border-bottom: 1px solid rgba(243, 243, 243, 0.5);
    }

    > .body {
      padding: 2.5rem 0;
      > .item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 2.5rem;

        &:last-child {
          padding-bottom: 0;
        }

        > .left {
          > .title {
            font-size: 1.4rem;
            font-weight: bold;
          }

          > .subtitle {
            color: #7d7d7d;
            font-size: 1.3rem;
          }
        }

        > .btn {
          height: 2.6rem;
          width: 5.8rem;
          text-align: center;
          line-height: 2.6rem;
          border-radius: 1.3rem;
          font-size: 1.2rem;
          font-weight: bold;
          background: linear-gradient(#ffeb6f 0%, #ffd416 100%);
        }
      }
    }
  }

  .modal-header {
    height: 14.2rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/author-center/level-permissions/3x.png');
    background-size: 100% 100%;
    // border-radius: 1.6rem;
    text-align: center;
    font-size: 0;
    padding-top: 1.5rem;
    > .icon {
      height: 5.5rem;
      width: 5.5rem;
    }

    > .name {
      font-size: 1.3rem;
      color: #1a1a1a;
    }

    > .subTitle {
      font-size: 1.2rem;
      color: rgba(26, 26, 26, 0.4);
    }
  }

  .modal-body {
    text-align: center;
    padding-top: 2rem;
    background-color: #fff;
    > .title {
      color: rgba(125, 125, 125, 1);
      font-size: 1.2rem;
      padding-bottom: 1.5rem;
    }

    > img {
      height: 15.3rem;
      width: 25rem;
      object-fit: cover;
    }
  }

  :global {
    .modal-content {
      background-color: #fff;
      border-radius: 1.6rem 1.6rem 1.5rem 1.5rem !important;
      overflow: hidden;
      padding-bottom: 3rem;
    }
  }
}

.swiperContainer {
  padding: 2.5rem 0;
  .swiperSlide {
    width: 25rem;
    border-radius: 1rem;
    overflow: hidden;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.03);
    margin-right: 1rem;

    &:first-child {
      margin-left: 1.5rem;
    }

    .header {
      padding: 1.7rem 0;
      padding-left: 2.5rem;
      // background: #ffd415 linear-gradient(135deg, #c3cade 0%, #9a9eb3 100%);
      color: #fff;
      font-size: 1.6rem;
      font-weight: bold;

      > span {
        position: relative;
        margin-right: 2.05rem;

        &:first-child {
          &::after {
            content: '';
            position: absolute;
            height: 1rem;
            width: 1px;
            background-color: rgba(255, 255, 255, 0.2rem);
            right: -1rem;
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
    }

    .body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 1.5rem;
      height: 10rem;
      color: #7d7d7d;
      font-size: 1.2rem;
      background-color: #fff;
      > div {
        margin-bottom: 0.8rem;
      }

      .condition {
        > span {
          margin-right: 1.5rem;
          &::before {
            content: '';
            position: relative;
            // top: 50%;
            transform: translateY(-50%);
            margin-right: 0.5rem;
            display: inline-block;
            height: 0.4rem;
            width: 0.4rem;
            border-radius: 50%;
            background-color: #c0bdbd;
          }
        }
      }
    }
  }
}
