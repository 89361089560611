.user-info {
  position: relative;
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/底框@3x.png');
  background-size: 100% 100%;
  margin: 0 1.5rem;
  height: 10.2rem;
  margin-top: 19.25rem;
  padding-top: 2rem;
  margin-bottom: 3rem;

  &::after {
    position: absolute;
    left: 19.3rem;
    bottom: 8.5rem;
    content: '';
    height: 9.5rem;
    width: 7rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/烟雾@3x.png');
    background-size: 100% 100%;
  }

  > .content {
    padding-left: 2.65rem;
    padding-right: 4.25rem;
    display: flex;
    align-items: center;
    color: #2c0c02;
    > .avatar {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
      margin-right: 1.5rem;
    }

    > .right {
      flex: 1;

      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 0.5rem;

        >.nickname {
          width: 15rem;
        }

        > .question-icon {
          height: 1.5rem;
          width: 1.5rem;
        }
      }

      .sub-title {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: bold;
        > .icon {
          width: 1.3rem;
          height: 1.7rem;
          margin-right: 0.5rem;
        }

        > span {
          display: inline-block;
          margin-right: 1rem;
        }
      }
    }
  }
}