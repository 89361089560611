











































.row {
  margin-bottom: 2.4rem;
  p {
    margin: 0;
  }
  > .row-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > .card-list {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      color: #ffffff;

      &.second {
        > .card {
          margin: 0;
          &:first-child {
            margin-right: 0.5rem;
          }
          &:nth-child(2) {
            margin-left: 0.5rem;
            margin-right: 1rem;
          }

          &:nth-child(3) {
            margin-right: 0.5rem;
            margin-left: 1rem;
          }

          &:nth-child(4) {
            margin-left: 0.5rem;
          }
        }
      }

      &.third {
        > .card {
          margin: 0 0.5rem;

          &:first-child {
            margin-left: 0;
          }
        }
      }
      .card {
        width: 4rem;
        height: 5rem;
        margin-right: 1rem;
      }
    }

    > .exchange-btn {
      display: inline-block;
      height: 2.5rem;
      width: 6rem;
      text-align: center;
      line-height: 2.5rem;
      border-radius: 0.5rem;
      font-size: 1.4rem;
      color: #971b0c;
      background: linear-gradient(0deg, #f8ba7b, #fddea4);

      &.disabled {
        background: rgba(255, 232, 201, 0.3);
      }
    }
  }
  > .extra {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    font-size: 1.2rem;
    color: #ffe8c9;

    > img {
      height: 1.4rem;
      width: 1.4rem;
      margin-left: 1rem;
    }
  }
}
