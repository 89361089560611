



















































.third {
  .the-card {
    margin: 0 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
}
