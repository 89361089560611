.the-section {
  margin-bottom: 3.5rem;
  .title {
    text-align: center;
    margin-bottom: 2rem;
    > span {
      position: relative;
      font-size: 1.6rem;
      font-weight: bold;
      color: #fff;

      &::before,
      &::after {
        position: absolute;
        content: '';

        height: 1.5rem;
        width: 1.5rem;
        background-size: 100% 100%;
        top: 50%;
        transform: translateY(-50%);
      }

      &::before {
        background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/左边@3x.png);
        left: -2.5rem;
      }

      &::after {
        background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/右边@3x.png);
        right: -2.5rem;
      }
    }
  }

  > .body {
    position: relative;
    min-height: 10rem;
    margin: 0 1.5rem;
    border-radius: 0.5rem;
    background: linear-gradient(180deg, rgba(24, 155, 252, 0.4), rgba(50, 255, 144, 0.4));

    &::after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-image: url(https://static.aipiaxi.com/app/web/venus-ssr/assets/share/invite/v@3x.png);
      background-size: 2rem 2rem;
    }
  }
}
