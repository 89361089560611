.theRankList {
  position: relative;
  z-index: 99;
  >.title {
    height: 3.9rem;
    margin-bottom: 2.7rem;
  }

  .rankListWrapper {
    padding-bottom: 8.5rem;
    min-height: 20rem;
    >.rankListItem {
      display: flex;
      align-items: center;
      padding: 2rem 0;
      border-top: 1px solid rgba(252, 248, 234, 0.2);

      >.num {
        color: #FFFFFF;
        font-size: 1.3rem;
        margin-right: 1.9rem;
      }
  
      >.content {
        display: flex;
        align-items: center;
        color: #FFFFFF;
        >.avatar {
          height: 5rem;
          width: 5rem;
          margin-right: 1.5rem;
          border-radius: 50%;
        }
  
        >.right {
          >.title {
            font-size: 1.5rem;
            margin-bottom: 0.3rem;
          }
  
          >.subtitle {
            font-size: 1.2rem;
            color: rgba(255, 255, 255, 0.7);
          }
        }
      }
    }  
  }

  
  .useInfo {
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    color: #FFFFFF;
    background-color: rgba(251, 141, 190, 0.98);
    padding-top: 1rem;
    padding-bottom: 3rem;
    border-radius: 1.5rem 1.5rem 0px 0px;
    padding-left: 1.5rem;

    >.avatar {
      height: 4.5rem;
      width: 4.5rem;
      margin-right: 1.5rem;
      border-radius: 50%;
    }

    >.right {
      >.title {
        font-size: 1.5rem;
        margin-bottom: 0.3rem;
      }

      >.subtitle {
        font-size: 1.2rem;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}