












































































.helper-center {
  /deep/ {
    .van-collapse-item__content {
      padding: 0;
    }
  }

  .title {
    display: flex;
    align-items: center;
    img {
      width: 4rem;
      height: 4rem;
      margin-right: 1rem;
      object-fit: cover;
    }
    .name {
      font-size: 1.6rem;
      font-weight: bold;
    }

    .desc {
      font-size: 1.4rem;
      color: #979797;
    }
  }
  .content {
    background-color: #fafafa;
    font-size: 1.4rem;
    color: #1a1a1a;
    li {
      padding: 1rem 1.5rem;
    }
  }
}
