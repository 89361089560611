



































































































































































.the-header {
  padding: 0 1.5rem;
  > .info {
    display: flex;
    > .avatar {
      height: 12.5rem;
      width: 12.5rem;
      border-radius: 1rem;
      box-shadow: 0px 7px 20px 0px rgba(0, 0, 0, 0.3);
      object-fit: cover;
    }

    > .right {
      flex: 1;
      display: flex;
      height: 12.5rem;
      flex-direction: column;
      justify-content: space-between;
      margin-left: 1.5rem;
      > .title {
        font-size: 2.3rem;
        font-weight: bold;
      }

      > .role {
        font-size: 1.3rem;
        > span {
          margin-right: 1rem;
        }
      }

      > .tag {
        font-size: 1.2rem;
        > span {
          padding: 0 0.8rem;
          margin-right: 0.5rem;
          border: 1px solid #b7b1af;
          border-radius: 0.9rem;
        }
      }
      > .rank {
        min-width: 10rem;
        height: 2.2rem;
        line-height: 2.2rem;
        border-radius: 0.3rem;
        overflow: hidden;
        display: flex;
        font-size: 1.2rem;
        > .num {
          font-weight: bold;
          min-width: 3.9rem;
          padding: 0 0.5rem;
          text-align: center;
          background: linear-gradient(180deg, #ffec2f 0%, #ffd415 100%);
        }

        > .text {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 6.1rem;
          padding-left: 0.7rem;
          background: linear-gradient(180deg, #ffffff 0%, #fffbf1 100%);
          > .more {
            height: 0.9rem;
            margin-right: 0.2rem;
          }
        }
      }
      > p {
        margin: 0;
      }
    }
  }

  > .control {
    margin-top: 2.4rem;
    display: flex;
    > div {
      flex: 1;
      background-color: #ffffff;
      height: 5rem;
      display: flex;
      align-items: center;
      border-radius: 0.3rem;
      padding-left: 2.5rem;
      box-shadow: 0px 3px 0.3rem 0px rgba(0, 0, 0, 0.02);

      &:first-child {
        margin-right: 1.5rem;
      }

      > img {
        height: 3rem;
        width: 3rem;
      }

      > .right {
        display: flex;
        flex-direction: column;
        margin-left: 1.5rem;
        > .title {
          font-size: 1.3rem;
          font-weight: bold;
        }

        > .num {
          font-size: 1rem;
          color: #c0bdbd;
        }
      }
    }
  }

  /deep/ {
    .ablum-create-popup {
      border-radius: 1rem 1rem 0 0;
      padding: 1.5rem;
    }
    .ablum-list-modal {
      padding: 0 1.5rem;
      border-radius: 1rem 1rem 0 0;
    }
  }
}
