













































.the-container {
  // padding: 0 2rem;
  background-color: #241c34;
  min-height: 100vh;
}
