



















































.comment-list-page {
  padding: 0 1.5rem;
  padding-top: 6.6rem;
  > li {
    display: flex;
    padding-top: 2rem;
    > .avatar {
      height: 3.5rem;
      width: 3.5rem;
      border-radius: 50%;
    }

    > .right {
      flex: 1;
      padding-left: 1rem;
      padding-bottom: 2rem;
      border-bottom: 1px solid #e8e7e7;
      > .title {
        font-size: 1.3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        > .heart-count {
          display: flex;
          align-items: center;
          font-size: 1.1rem;
          color: #7d7d7d;

          > .heart {
            height: 1.3rem;
            width: 1.3rem;
            margin-right: 0.5rem;
          }
        }
      }

      > .content-wrapper {
        > .content {
          font-size: 1.5rem;
          padding-bottom: 1.2rem;
          padding-top: 1.5rem;
        }

        > .time {
          color: #979797;
          font-size: 1.1rem;
        }
      }
    }
  }
}
