.mayTwenty {
  background-color: #FF72B5;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 1.5rem;

  >.bg {
    position: absolute;
    height: 31.7rem;
    width: calc(100% - 3rem);
    z-index: 9;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    top: 0;
    height: 70rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/may-twenty/上.jpg');
    background-size: 100% 100%;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 70rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/may-twenty/下.jpg');
    background-size: 100% 100%;
  }
}

.rightIcon {
  height: 1.8rem;
  width: 1.8rem;
}