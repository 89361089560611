








































































.the-role-count-container {
  margin-bottom: 3rem;
  > .title {
    color: #7d7d7d;
    font-size: 1.4rem;
    margin-bottom: 2.7rem;
  }

  > .select {
    display: flex;
    align-items: center;
    font-size: 1.5rem;

    > .item {
      display: flex;
      align-items: center;
      &:first-child {
        margin-right: 2rem;
      }

      > .icon-wrapper {
        font-size: 0;
      }
    }
    input {
      background-color: #fafafa;
      width: 6rem;
      text-align: center;
      margin: 0 1rem;
      border-radius: 0.3rem;
    }
    img {
      height: 1.8rem;
      width: 1.8rem;
      margin-right: 1rem;
    }
  }
}
