






































































































































.content {
  word-break: break-word;
  position: relative;
  overflow: hidden;
}

.footer {
  display: flex;
  justify-content: center;
  padding: 0 0.5rem;
  padding-bottom: 3.5rem;
  padding-top: 2rem;
  border-top: 1px solid rgba(232, 231, 231, 0.5);

  > .box {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f3f3f3;
    border-radius: 1.75rem;
    height: 3.5rem;
    width: 9rem;
    font-size: 1.2rem;
    color: #7d7d7d;
    background-color: #fafafa;
    margin-right: 1.75rem;
    &:last-child {
      margin-right: 0;
    }
    > img {
      height: 1.5rem;
      width: 1.5rem;
      margin-right: 0.6rem;
    }
  }
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 2.5rem;
  &::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: #e8e7e7;
  }
  > .content {
    background-color: #fff;
    color: #7d7d7d;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    > img {
      height: 1.3rem;
      width: 1.3rem;
      margin-right: 0.5rem;
    }

    > span {
      color: 1.2rem;
    }
  }
}
