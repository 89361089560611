




























.the-section {
  p {
    margin: 0;
  }
  >.header {
    font-size: 1.4rem;
    color: #7D7D7D;
    padding-top: 2rem;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    border-bottom: 1px solid #E8E7E7;
  }

  >.list {
    padding: 0 1.5rem;
  }
}
