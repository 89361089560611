.theHeader {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 1.5rem;
  padding: 2.4rem 1.5rem;
  > .content {
    display: flex;
    align-items: center;
    > .avatar {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
      margin-right: 1.5rem;
    }

    .title {
      font-size: 1.6rem;
      font-weight: bold;
    }

    .subtitle {
      color: rgba(39, 36, 64, 0.7);
      font-size: 1.2rem;
    }
  }

  .btn {
    border-radius: 1.3rem;
    border-top-right-radius: 0.4rem;
    background-color: #509cff;
    font-size: 1.3rem;
    color: #fff;
    padding: 0.5rem 1.2rem;
  }
}

.modalBody {
  background-color: #fff;
  border-radius: 0.8rem;
  padding: 0 1rem;
  height: 40rem;
  overflow: scroll;

  > .table {
    width: 100%;
    border-collapse: collapse;

    th {
      padding: 1.4rem 0;
      font-size: 1.5rem;
      border-bottom: 1px solid #f3f3f3;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
      &:nth-child(2) {
        text-align: center;
      }
    }

    td {
      font-size: 1.3rem;
      padding-top: 2rem;
      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
      &:nth-child(2) {
        text-align: center;
      }
    }
  }
}
