






























































































































.tag-edit-form {
  padding: 0 2rem;
 
  >.header {
    font-size: 1.6rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 0;
    border-bottom: 1px solid rgba(232, 231, 231, 1);

    >span {
      &:nth-child(2) {
        font-weight: bold;
      }
    }
  }

  >.body {
    >.section {
      padding-top: 2rem;
      >.title {
        display: flex;
        justify-content: space-between;
        font-size: 1.8rem;
        font-weight: bold;
      }

      >.content {
        display: flex;
        flex-wrap: wrap;
        padding: 2rem 0;
        border-bottom: 1px solid rgba(232, 231, 231, .5);
        >.tags {
          position: relative;
          font-size: 1.4rem;
          padding: 0.5rem 2rem;
          border-radius: 1.6rem;
          margin-bottom: 1.5rem;
          margin-right: 1rem;
          &.active {
            background-color: rgba(255, 235, 111, 1);

            >img {
              position: absolute;
              height: 1.5rem;
              width: 1.5rem;
              right: -0.4rem;
              top: -0.4rem;
            }
          }
        }
      }

      .next {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        font-weight: normal;
        border: 1px solid rgba(232, 231, 231, .5);
        padding: 0 1rem;
        border-radius: 1.6rem;
        >img {
          height: 1.1rem;
          width: 1.1rem;
          margin-left: 0.5rem;
        }
      }

      .tag-add-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed rgba(232, 231, 231, 1);
        height: 3.2rem;
        width: 3.2rem;
        border-radius: 50%;

        >img {
          height: 1.2rem;
          width: 1.2rem;
        }
      }

      >.tag-list {
        
        >.tags {
          border: 1px solid rgba(232, 231, 231, 0.5);
        }
      }
    }
  }

  /deep/ {
    .tag-input-popup {
      padding: 2rem 1rem;

      .tag-input-form {
        display: flex;
        align-items: center;

        >input {
          flex: 1;
          font-size: 1.5rem;
          padding: 0.5rem 1rem;
          background-color: rgba(250, 250, 250, 1);
          border-radius: 2rem;
          margin-right: 1rem;
        }

        .btn-submit {
          font-size: 1.4rem;
          font-weight: bold;
          padding: 0.5rem 2rem;
          border-radius: 1.6rem;
          background-color: rgba(255, 235, 111, 1);
        }
      }
    }
  }
}
