

































































.the-submit-button {
  position: fixed;
  bottom: 3.5rem;
  left: 2.5rem;
  right: 2.5rem;
  height: 4.5rem;
  background-color: #ffd415;
  font-size: 1.6rem;
  font-weight: bold;
  line-height: 4.5rem;
  text-align: center;
  border-radius: 3.03rem;
  &:not(.disabled):active {
    background-color: rgba(255, 212, 21, 0.5);
  }

  &.disabled {
    background-color: #f3f3f3;
  }
}
