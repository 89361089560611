




























































































.the-team-editor-container {
  padding: 0 1.2rem;
  border-radius: 1.5rem 1.5rem 0 0;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    padding: 2.15rem 1.2rem;
    border-bottom: 1px solid rgba(235, 235, 235, 0.8);

    > .cancel {
      color: #7d7d7d;
    }

    > .title {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  > .user-info-row {
    display: flex;
    align-items: center;
    padding: 2.5rem 0;
    border-bottom: 1px solid rgba(235, 235, 235, 0.8);
    > .avatar {
      height: 5rem;
      width: 5rem;
      border-radius: 50%;
      margin-right: 1.5rem;
    }
    > .user-info {
      flex: 1;
      > .title {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      > .desc {
        color: #7d7d7d;
        font-size: 1.2rem;
      }
    }
  }

  > .tag-list-wrapper {
    display: flex;
    padding-top: 2rem;
    padding-bottom: 10rem;

    > .title {
      font-size: 1.4rem;
    }

    > .tag-list {
      display: flex;
      flex-wrap: wrap;
      margin-left: 2rem;
      flex: 1;

      > .item {
        line-height: 3.3rem;
        width: 7.9rem;
        text-align: center;
        border-radius: 1.75rem;
        font-size: 1.3rem;
        background-color: #fafafa;
        margin-right: 0.7rem;
        margin-bottom: 1rem;

        &.active {
          background-color: #ffeb6f;
        }
      }
    }
  }
}
