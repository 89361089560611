

































.the-tool {
  display: flex;
  flex-wrap: wrap;
  > .box {
    position: relative;
    background-color: #353535;
    border-radius: 0.5rem;
    width: 16.5rem;
    text-align: center;
    min-height: 12.6rem;
    margin-right: 1.5rem;
    padding: 1rem 0;
    margin-bottom: 1.5rem;
    &:nth-child(2n) {
      margin-right: 0;
    }

    &.flag::after {
      position: absolute;
      content: 'NEW';
      width: 4.1rem;
      height: 1.8rem;
      line-height: 1.8rem;
      right: 0;
      top: 0;
      font-size: 0.9rem;
      color: #fff;
      border-radius: 0 0.5rem 0 0.5rem;
      background-color: #ff6536;
    }

    > img {
      height: 6rem;
      width: 6rem;
      margin-bottom: 1.5rem;
    }

    > .name {
      font-size: 1.3rem;
      color: #fff;
      padding: 0 1rem;
    }
  }
}
