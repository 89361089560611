


































































































































































.page {
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 10.8rem;

  .input-fields {
    padding: 0 3.75rem;
  }

  .btn-wrapper {
    padding: 0 2.5rem;
    width: 100%;
    text-align: center;
    margin-top: 10rem;
    .submit-btn {
      background-color: #ffd415;
      font-size: 1.7rem;
      width: 100%;
      padding: 1.4rem 0;
      border-radius: 3.03rem;

      &:active {
        background-color: rgba(255, 212, 21, 0.5);
      }
    }
  }
}

.row {
  display: flex;
  align-items: center;
  padding: 1.95rem 1rem;
  border-bottom: 1px solid #e8e7e7;
  width: 26rem;
  input {
    border: none;
    outline: none;
    font-size: 1.8rem;
    font-weight: 500;
    /* width: 11.7rem; */

    &::placeholder {
      font-size: 1.5rem;
      color: #c0bdbd;
    }
  }

  &.verify-code {
    justify-content: space-between;
    input {
      width: 8.5rem;
    }

    .send-code-btn {
      color: #1749ff;
      font-size: 1.5rem;
    }
  }

  &.tel-input {
    input {
      padding: 0;
      margin: 0;
      width: 11.7rem;
    }

    .tel-input-wrapper {
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: space-between;
      > .left {
        display: flex;
        align-items: center;
      }
    }

    .split {
      background-color: #ffd415;
      width: 0.2rem;
      height: 1.7rem;
      border-radius: 0.18rem;
      /* margin-right: 3.5rem; */
      margin-left: 0.5rem;
    }

    .select-down-icon {
      width: 0.6rem;
      height: 0.6rem;
      margin: 0 1rem;
    }

    .country-code {
      font-size: 1.8rem;
      font-weight: 500;
    }

    .clear-btn {
      height: 1.8rem;
      width: 1.8rem;
    }
  }
}
