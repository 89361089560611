




















.the-friend-list {
  padding: 0 1.5rem;
  padding-top: 3rem;
  min-height: 100vh;
}
