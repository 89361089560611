



























.flip {
  position: relative;
  width: 4rem;
  height: 5rem;
  margin-right: 1rem;
  perspective: 500;
  -webkit-perspective: 500;

  &.reverse {
    > .front {
      opacity: 0;
      transform: rotateY(-180deg);
      -webkit-transform: rotateY(-180deg);
    }

    > .back {
      opacity: 1;
      transform: rotateY(-360deg);
      -webkit-transform: rotateY(-360deg);
    }
  }

  .card {
    width: 4rem;
    height: 5rem;
  }

  .back {
    opacity: 0;
    position: relative;
    transform: rotateY(-180deg);
    -webkit-transform: rotateY(-180deg);
  }

  .front,
  .back {
    width: 4rem;
    height: 5rem;
    position: absolute;
    top: 0px;
    left: 0px;
    perspective: 1000;
    -webkit-perspective: 1000;
    // backface-visibility: hidden;
    // -webkit-backface-visibility: hidden; /* Chrome 、Safari */
    // -moz-backface-visibility: hidden; /* Firefox */
    // -ms-backface-visibility: hidden; /* Internet Explorer */
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    -ms-transition: all 1s;
    -o-transition: all 1s;
    // overflow: hidden;
  }
}
