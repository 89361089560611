























.container {
  width: 10.8rem;
  margin-right: 1.05rem;
  margin-bottom: 2rem;
  &:nth-child(3n) {
    margin-right: 0;
  }
  > p {
    margin: 0;
  }

  > .img {
    object-fit: cover;
    border-radius: 0.5rem;
    margin-bottom: 1.2rem;
  }

  > .name {
    width: 10rem;
    font-size: 1.4rem;
  }
}
