












































































.lucky-draw {
  min-height: 42.1rem;
  background-color: #ffffe3;
  padding-top: 3.1rem;
  overflow: hidden;

  > .remain-count,
  > .sub-title {
    color: #2e4024;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
  }

  > .remain-count {
    margin-bottom: 1rem;
  }

  > .sub-title {
    margin-bottom: 2rem;
  }

  > .btn {
    height: 5rem;
    width: 25.1rem;
    margin: 0 auto;
    color: #fff;
    font-size: 2rem;
    text-align: center;
    line-height: 4.5rem;
    background-image: url('../../../../assets/activity/spring-voice/抽奖@3x.png');
    background-size: 100% 100%;
    font-weight: bold;
    margin-bottom: 2rem;
  }

  > .link {
    color: #f25b00;
    font-weight: 1.5rem;
    text-decoration: underline;
    font-weight: bold;
    text-align: center;
    margin-bottom: 3rem;
  }

  > .reward {
    position: relative;
    background-color: #009e6d;
    margin: 0 2.5rem;
    margin-bottom: 3.05rem;
    border-radius: 1rem;
    > img {
      height: 100%;
      width: 100%;
    }

    > .title {
      position: absolute;
      bottom: 3rem;
      width: 100%;
      text-align: center;
      color: #ffffe3;
      font-weight: bold;
      font-size: 1.3rem;
    }
  }

  .lucky-draw-modal {
    padding-top: 2rem;
    background-color: #ffffe3;
    padding-bottom: 3.5rem;
    > .avatar-wrapper {
      background-color: #e4f7b7;
      height: 9.35rem;
      width: 9.35rem;
      border-radius: 1rem;
      margin: 0 auto;
      margin-bottom: 1.45rem;
      > img {
        height: 100%;
        width: 100%;
      }
    }

    > .text {
      font-size: 1.4rem;
      color: #2e4024;
      text-align: center;
      padding: 0 3rem;
    }

    > .btn {
      height: 4rem;
      width: 20rem;
      margin: 0 auto;
      background: linear-gradient(90deg, #fceb43, #fdca24);
      border-radius: 2rem;
      font-size: 1.6rem;
      font-weight: bold;
      color: #00563c;
      font-weight: bold;
      text-align: center;
      line-height: 4rem;
      margin-top: 2.5rem;
    }
  }

  .rule-modal {
    padding: 2rem;
    background-color: #ffffe3;
    font-size: 1.4rem;
    font-weight: bold;
    color: #2e4024;

    .title {
      font-size: 2rem;
      text-align: center;
      margin-bottom: 1rem;
    }

    .row {
      margin-bottom: 1rem;
    }
  }
}
