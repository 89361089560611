



































































































































#gift-list-swiper {
  height: calc(100vh - 4.7rem);
}
