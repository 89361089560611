













.section-container {
  padding: 2rem 1.2rem;
  background-color: RGBA(146, 19, 19, 1);
  border-radius: 1rem;
  margin: 0 1rem;
}
