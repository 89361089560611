



































.collection-detail {
  position: relative;
}
