













.divider-container {
  position: relative;
  font-size: 1.6rem;
  font-weight: bold;
  color: rgba(255, 232, 201, 1);
  text-align: center;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 1px;
    width: 100%;
    background-color: rgba(255, 247, 236, 0.2);
  }

  > span {
    position: relative;
    z-index: 99;
    padding: 0 1.5rem;
    display: inline-block;
    background-color: RGBA(146, 19, 19, 1);
  }
}
