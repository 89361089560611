.list-item {
  padding: 1.5rem 0;
  border-bottom: 0.5px solid rgba(232, 231, 231, 0.8);

  &:first-child {
    border-top: 0.5px solid rgba(232, 231, 231, 0.8);
  }
  > .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.1rem;

    > .left {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      font-weight: bold;
      > .name {
        flex: 1;
        width: 20rem;
      }

      > .icon {
        display: inline-block;
        height: 2.2rem;
        width: 2.2rem;
        background-size: 100% 100%;
        margin-right: 1rem;
        text-align: center;
        color: #c0bdbd;
        font-size: 1.3rem;
        line-height: 2.2rem;

        &.level0 {
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/article-rank/1@3x.png');
        }

        &.level1 {
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/article-rank/2@3x.png');
        }

        &.level2 {
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/article-rank/3@3x.png');
        }
      }
    }

    > .right {
      display: flex;
      align-items: center;
      font-size: 1.1rem;

      &.up {
        color: #ff6536;
        > .icon {
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/article-rank/向上.png');
        }
      }

      &.down {
        color: #2fb2ff;
        > .icon {
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/article-rank/向下png.png');
        }
      }

      &.normal {
        > .icon {
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/article-rank/向上灰.png');
        }
      }

      > .icon {
        display: inline-block;
        height: 1rem;
        width: 1rem;
        background-size: 100% 100%;
        margin-left: 0.3rem;
      }
    }
  }

  > .content {
    display: flex;
    > .avatar {
      height: 6rem;
      width: 6rem;
      border-radius: 0.5rem;
      margin-right: 1rem;
    }

    > .info {
      flex: 1;
      > .nickname {
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        color: #7d7d7d;
        margin-bottom: 1rem;

        > .naming {
          margin-right: 0.8rem;
          position: relative;
          font-size: 1.1rem;
          display: flex;
          align-items: center;
          z-index: 0;
          font-weight: normal;
          width: 7rem;
    
          &::after {
            position: absolute;
            content: "";
            height: 0.6rem;
            left: -0.3rem;
            right: -0.3rem;
            bottom: 0.5rem;
            background-color: var(--bg-color);
            z-index: -1;
          }
    
          > .icon {
            background-size: 100%;
            height: 1.4rem;
            width: 1.4rem;
          }
        }
      }

      > .desc {
        color: #c0bdbd;
        font-size: 1.1rem;
      }
    }
  }
}
