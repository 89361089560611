
















.app-empty {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translateX(-50%);

  .message {
    color: #bfbdbd;
    font-size: 1.3rem;
  }
}
.empty-icon {
  height: 15rem;
  width: 15rem;
}
