











































.gift-record-list {
  padding: 0 1.5rem;
  height: 100%;
  overflow: scroll;
  > li {
    display: flex;
    align-items: center;
    > .avatar {
      height: 4.8rem;
      width: 4.8rem;
      border-radius: 50%;
    }
    > .right {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-left: 1.7rem;
      border-bottom: 1px solid rgba(243, 243, 243, 1);
      padding: 2rem 0;
      > .info {
        > .name {
          font-size: 1.6rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }

        > .desc {
          font-size: 1.2rem;
          color: rgba(125, 125, 125, 0.8);
        }
      }

      > .date {
        font-size: 1.1rem;
        color: rgba(192, 189, 189, 0.8);
      }
    }
  }
}
