















.desc {
  padding: 2.5rem 0;
  background-color: #ffffe3;
  color: #2e4024;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  > div {
    margin-bottom: 0.5rem;
  }
}
