
















.medal-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 23rem;
  width: 23rem;
  margin: 0 auto;
  background-image: url('../../../../assets/webview/medal/光效@3x.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;

  > img {
    height: 16rem;
    width: 16rem;
  }
}
