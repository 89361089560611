




























.team-list-container {
  margin-bottom: 3rem;
  > .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    margin-bottom: 1.5rem;

    > .add-btn {
      font-size: 1.2rem;
      font-weight: bold;
      display: inline-block;
      background-color: #ffeb6f;
      height: 2.8rem;
      line-height: 2.8rem;
      width: 6rem;
      text-align: center;
      border-radius: 1.6rem;

      &:active {
        background-color: rgba(255, 235, 111, 0.8);
      }
    }
  }
}
