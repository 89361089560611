









































.wrapper {
  display: flex;
  align-items: center;
}

.icon {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
  margin-left: 10px;
  background-image: url('https://static.aipiaxi.com/image/2022/11/FvFn3sOamkCzbiQQBdoGx6hLAAGb.png');
  background-size: 100% 100%;
  margin-right: 1rem;
}

.title-input {
  font-size: 1.4rem;
  width: 100%;
  padding: 0;
  flex: 1;

  &::placeholder {
    color: rgba(192, 189, 189, 1);
  }
}
