.the-rank-tab {
  .tabs {
    background-color: #511b06;
    display: flex;
    color: rgba(253, 249, 238, 0.7);
    font-size: 1.5rem;
    padding: 0.75rem 1.5rem;

    > .tab-item {
      flex: 1;
      position: relative;

      &.active {
        > div {
          background-color: #b05424;
          color: #fdf9ee;
        }
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &::after {
        content: '';
        position: absolute;
        height: 1rem;
        width: 1px;
        background-color: rgba(214, 209, 199, 0.1);
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }

      > div {
        height: 2.85rem;
        width: 8.85rem;
        text-align: center;
        line-height: 2.85rem;
        border-radius: 1.43rem;
        margin: 0 auto;
      }
    }
  }

  > .body {
    padding: 0 1.5rem;
  }

  .nomore {
    color: rgba(253, 249, 238, 0.2);
    font-size: 1.2rem;
    padding: 3.5rem 0;
    text-align: center;
  }
}

.the-rank-list {
  padding: 0 1.5rem;
  min-height: 20rem;
}

.the-rank-item {
  padding: 2rem 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(252, 248, 234, 0.1);

  >.number {
    display: inline-block;
    min-width: 1.7rem;
    margin-right: 2rem;
    text-align: center;
    color: #D6D1C7;
    font-size: 1.3rem;
    font-weight: bold;
  }

  > .icon {
    width: 1.7rem;
    height: 2.1rem;
    margin-right: 2rem;
  }
  > .content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > .left {
      display: flex;
      align-items: center;
      > .avatar {
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        margin-right: 1.5rem;
      }

      > .info {
        > .title {
          font-size: 1.5rem;
          color: #fdf9ee;
          margin-bottom: 0.3rem;
        }

        > .sub-title {
          display: flex;
          align-items: center;
          font-size: 1.2rem;
          color: rgba(253, 249, 238, 0.7);
          > img {
            height: 1.7rem;
            width: 1.3rem;
            margin-right: 0.5rem;
          }
        }
      }
    }

    > .btn {
      background-color: #b05424;
      color: #fdf9ee;
      height: 2.6rem;
      width: 6rem;
      border-radius: 1.3rem;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.the-burn-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1rem;
  padding-top: 2rem;
  min-height: 20rem;
}

.the-burn-list-item {
  position: relative;
  height: 11.5rem;
  width: 11.5rem;
  // background-image: url('https://img2.woyaogexing.com/2021/04/12/508fc8e5eed64c6c8f169f85dcd7a54a!400x400.jpeg');
  background-size: 100% 100%;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  border-radius: 0.2rem;
  &:nth-child(3n) {
    margin-right: 0;
  }

  > .info {
    padding-top: 0.2rem;
    text-align: center;
    position: absolute;
    height: 4.5rem;
    width: 100%;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);

    > .name {
      font-size: 1.4rem;
      color: #988123;
      font-weight: bold;
    }

    > .date {
      font-size: 1.1rem;
      color: #fff;
    }
  }
}

.rankWelfare {
  >.desc {
    color: #fdf9ee;
    font-size: 1.2rem;
    padding: 0 1.25rem;
    padding-bottom: 2rem;
  }

  >.the-rank {
    display: flex;
    padding: 0 1.25rem;

    > .item {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 1.35rem;
      width: 12rem;
      height: 12.5rem;
      background-color: #3e1304;
      border-radius: 0.5rem;
      color: rgba(253, 249, 238, 0.7);
      font-size: 1.2rem;
      margin-right: 4rem;
      margin-bottom: 2.5rem;
      &:nth-child(2n) {
        margin-right: 0;
      }
  
      >img {
        height: 6.5rem;
        width: 6.5rem;
        margin-bottom: 1.5rem;
      }
    }
  }
  
}



.the-section {
  position: relative;
  min-height: 10rem;
  background-color: #ffc68e;
  border-radius: 0.5rem;
  margin: 0 1.5rem;
  padding: 0 2rem;
  margin-bottom: 3.3rem;
  > .header {
    position: relative;
    height: 5.35rem;

    &.task {
      &::before {
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/燃将任务@3x.png');
      }
    }

    &.welfare {
      &::before {
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/燃将福利@3x.png');
      }
    }

    &.rank {
      &::before {
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/榜单福利@3x.png');
      }
    }
    &::before {
      content: '';
      position: absolute;
      top: -2.2rem;
      z-index: 99;
      left: 50%;
      transform: translateX(-50%);
      height: 7.2rem;
      width: 17.4rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/燃将任务@3x.png');
      background-size: 100% 100%;
      font-size: 2.1rem;
      font-weight: bold;
      color: #511b06;
    }

    // &::after {
    //   content: '';
    //   position: absolute;
    //   right: 2.2rem;
    //   height: 1.5rem;
    //   width: 1.5rem;
    //   background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/question.png');
    //   background-size: 100% 100%;
    //   top: 50%;
    //   transform: translateY(-50%);
    // }
  }

  > .body {
    position: relative;
    z-index: 99;
  }
  > .bg_1 {
    position: absolute;
    top: 0.5rem;
    bottom: 0.5rem;
    left: 1rem;
    right: 1rem;
    border-radius: 0.8rem;
    background-color: #511b06;
    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 0.7rem;
      width: 0.7rem;
      background-size: 100% 100%;
      top: -0.2rem;
    }

    &::after {
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/右边@3x.png');
      right: -0.8rem;
    }

    &::before {
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/左边@3x.png');
      left: -0.8rem;
    }
  }

  > .bg_2 {
    position: absolute;
    top: 1rem;
    bottom: 1rem;
    left: 0.5rem;
    right: 0.5rem;
    border-radius: 0.8rem;
    background-color: #511b06;
    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 0.7rem;
      width: 0.7rem;
      background-size: 100% 100%;
      bottom: -0.8rem;
    }

    &::after {
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/左边@3x.png');
      right: -0.2rem;
    }

    &::before {
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/burn/右边@3x.png');
      left: -0.2rem;
    }
  }
}

.the-task-list {
  > .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fdf9ee;
    padding: 2rem 0;
    border-top: 1px solid rgba(252, 248, 234, 0.1);
    > .left {
      > .title {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
      }
      > .sub-title {
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        img {
          height: 1.7rem;
          width: 1.3rem;
          margin-right: 0.55rem;
        }
      }
    }

    > .btn {
      font-size: 1.3rem;
      height: 2.6rem;
      width: 6rem;
      border-radius: 1.3rem;
      background-color: #b05424;
      text-align: center;
      line-height: 2.6rem;
    }
  }
}