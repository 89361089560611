.page {
  background-color: rgba(163, 113, 204, 1);

  .body {
    padding: 0 1.4rem;
    padding-top: 2rem;
    >.title {
      text-align: center;
      font-size: 1.4rem;
      color: #fff;
      margin-bottom: 1.45rem;
    }

    >.imgList {
      font-size: 0;
      >img {
        height: 4.5rem;
        width: 4.5rem;
        border-radius: 0.1rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        &:nth-child(7n) {
          margin-right: 0;
        }
      }
    }
  }
}