







































































































.container {
  padding: 0 1.5rem;
  padding-top: 2rem;
}
.row {
  padding-bottom: 2rem;
  border-bottom: 1px solid #e8e7e7;

  &.avatar-filed {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .avatar {
      height: 6.4rem;
      width: 6.4rem;
      border-radius: 50%;
    }

    > .title {
      font-size: 1.7rem;
      color: #1a1a1a;
    }
  }

  .title {
    font-size: 1.2rem;
    color: #7d7d7d;
  }
  .cell {
    display: flex;
    align-items: center;

    > input,
    > .input {
      font-size: 1.7rem;
      flex: 1;
      border: none;
      color: #1a1a1a;
    }

    > img {
      height: 1.1rem;
      width: 1.1rem;
    }
  }
}

.btn {
  margin-top: 6rem;
  text-align: center;
  background-color: #ffd415;
  height: 4.5rem;
  line-height: 4.5rem;
  font-size: 1.7rem;
  border-radius: 3.03rem;

  &:active {
    background-color: rgba(255, 212, 21, 0.5);
  }
}
