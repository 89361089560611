.workersDay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, #AC5AFE, #9D37EE, #6123DC);

  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 40.25rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/workers-day/放射@2x.png');
    background-size: 100% 100%;
    top: 0;
  }

  &::before {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0.75rem;
    height: 11.4rem;
    width: 7.75rem;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/workers-day/爱心2@2x.png');
    background-size: 155% 100%;
    z-index: 0;
  }

  >.bottom {
    padding-top: 2.9rem;
    padding-bottom: 4.9rem;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 1.4rem;
  }
}

.rightIcon {
  height: 2.5rem;
}