















































































































































































.the-rank-list {
  padding-top: 2rem;
  color: #ffe8c9;
  .date-list {
    // display: flex;
    // justify-content: space-between;
    color: rgba(255, 232, 201, 0.5);
    font-size: 1.4rem;
    margin-bottom: 1.5rem;

    .swiper-slide.active {
      color: #ffe8c9;
      font-weight: bold;
    }
  }

  > .title {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  > .user-list {
    > li {
      display: flex;
      align-items: center;
      padding-top: 2.7rem;
      padding-bottom: 1.5rem;
      border-top: 1px solid rgba(255, 247, 236, 0.2);

      > .avatar-wrapper {
        position: relative;
        margin-right: 1rem;
        &::after {
          content: '';
          position: absolute;
          left: 50%;
          transform: translate(-50%);
          bottom: 3.6rem;
          height: 2.1rem;
          width: 2.5rem;
          background-size: 100% 100%;
        }

        &.first {
          &::after {
            background-image: url('../../../../assets/activity/spring-festival/第一@3x.png');
          }
        }

        &.second {
          &::after {
            background-image: url('../../../../assets/activity/spring-festival/第二@3x.png');
          }
        }

        &.third {
          &::after {
            background-image: url('../../../../assets/activity/spring-festival/第三@3x.png');
          }
        }

        > .avatar {
          width: 4.5rem;
          height: 4.5rem;
          font-size: 0;
          position: relative;
          border-radius: 50%;
          overflow: hidden;
          > img {
            width: 4.5rem;
            height: 4.5rem;
            border-radius: 50%;
          }

          > .rank {
            position: absolute;
            display: inline-block;
            height: 1.3rem;
            line-height: 1.3rem;
            width: 3.5rem;
            border-radius: 50%;
            background-color: #fc6327;
            font-size: 1rem;
            color: #fff;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            bottom: 0;
          }
        }
      }

      > .content {
        display: flex;
        flex: 1;
        justify-content: space-between;
        .title {
          font-size: 1.4rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
          width: 11rem;
        }

        .subtitle {
          font-optical-sizing: 1.2rem;
        }

        > .score {
          font-size: 1.4rem;
          font-weight: bold;
        }

        > .btn-wrapper {
          width: 5rem;
          > .btn {
            display: inline-block;
            background-color: rgba(255, 232, 201, 0.8);
            font-size: 1.4rem;
            color: #971b0c;
            height: 2.5rem;
            line-height: 2.5rem;
            text-align: center;
            width: 5rem;
            border-radius: 0.5rem;

            &.unrecv {
              background: linear-gradient(0deg, #f8ba7b, #fddea4);
            }
          }
        }
      }
    }
  }

  > .current-user {
    padding: 0.9rem 2.5rem;
    padding-bottom: 3rem;
    position: fixed;
    z-index: 999;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 1rem 1rem 0 0;
    color: #1a1a1a;
    display: flex;
    align-items: center;
    background-color: #fff8f2;
    > img {
      height: 4.5rem;
      width: 4.5rem;
      border-radius: 50%;
      margin-right: 0.9rem;
    }

    > .right {
      flex: 1;
      display: flex;
      justify-content: space-between;

      .title {
        font-size: 1.4rem;
        font-weight: bold;
        margin-bottom: 0.5rem;
      }

      .subtitle {
        font-size: 1.2rem;
        color: rgba(26, 26, 26, 0.8);
      }

      > .score {
        font-size: 1.4rem;
        font-weight: bold;
      }
    }
  }
}
