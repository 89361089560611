






























































































































.container {
  padding: 6rem 2rem;
  padding-bottom: 0;

  > .tab {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    color: #7d7d7d;
    > .item {
      margin-right: 2.5rem;
      &.active {
        font-size: 2rem;
        color: #1a1a1a;
        font-weight: bold;
      }
    }
  }

  > .login-btn,
  > .wx-btn {
    height: 4.5rem;
    line-height: 4.5rem;
    text-align: center;
    border-radius: 2.1rem;
    color: #1a1a1a;
    font-size: 1.6rem;
  }

  > .login-btn {
    background-color: #ffd300;
    margin-bottom: 2rem;
  }

  > .wx-btn {
    background-color: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      height: 2.2rem;
      width: 2.2rem;
      margin-right: 1rem;
    }
  }

  .argreement {
    display: flex;
    align-items: center;
    font-size: 1.4rem;
    color: #000000;
    margin-top: 20rem;

    > .icon {
      height: 1.4rem;
      width: 1.4rem;
      margin-right: 1.2rem;
    }
    p {
      margin: 0;
    }
    span {
      color: #ff7b52;
    }
  }
}
