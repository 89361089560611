






































.audio-upload-container {
  padding: 5rem 2.5rem;
  > .desc {
    font-size: 1.5rem;
    margin-bottom: 4rem;
  }

  > .btn {
    height: 4.5rem;
    line-height: 4.5rem;
    border-radius: 3.03rem;
    font-size: 1.7rem;
    font-weight: bold;
    text-align: center;
    background-color: #ffd415;

    &:active {
      background-color: rgba(255, 212, 21, 0.8);
    }
  }
}
