.wrapper {
  width: 100%;
  overflow-x: hidden;
}

.container {
  height: 25rem;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
