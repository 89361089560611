.theRankTab {
  >.tabHeader {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
  
    >.tabItem {
      position: relative;
      &.active {
        >.content {
          position: relative;
          top: -0.5rem;
          border: 0.15rem solid #fff;
          background: #FF87BC;
          box-shadow: 0rem 1rem 2rem #FC5299;
        }

        &::before {
          content: '';
          position: absolute;
          left: 50%;
          z-index: 9;
          transform: translateX(-50%);
          bottom: -0.55rem;
          border-left: 0.8rem solid transparent;
          border-right: 0.8rem solid transparent;
          border-top: 1rem solid #fff;
        }

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          z-index: 10;
          transform: translateX(-50%);
          bottom: -0.35rem;
          border-left: 0.8rem solid transparent;
          border-right: 0.8rem solid transparent;
          border-top: 1rem solid #FF87BC;
        }
      }

      >.header {
        font-size: 1.3rem;
        color: #fff;
        text-align: center;
        margin-bottom: 1.2rem;
      }
      
      >.content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 0.5rem;
        position: relative;
        width: 11rem;
        height: 16.5rem;
        border-radius: 0.8rem;
        background: linear-gradient(-40deg, rgba(255, 191, 232, 0.4), rgba(255, 191, 232, 0.4));
        border: 1px solid rgba(255, 213, 130, 0.4);
        color: #fff;
        padding-bottom: 1.6rem;

       
  
        &::before {
          content: '';
          position: absolute;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          background-color: #FF9DC9;
          height: 6.7rem;
          width: 7.5rem;
          border-radius: 0px 0px 1rem 1rem;
        }
  
        >.img {
          position: relative;
          z-index: 99;
          height: 5.3rem;
          width: 5.3rem;
        }
  
        >.avatar {
          position: relative;
          z-index: 99;
          height: 5rem;
          width: 5rem;
          border-radius: 50%;
          border: 1px solid #fff;
          overflow: hidden;
          >img {
            width: 100%;
            height: 100%;
          }
        }
  
        >.name {
          font-size: 1.3rem;
        }
  
        >.title {
          font-size: 1rem;
        }
      }
      
    }
  }
}