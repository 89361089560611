





























































.the-calendar-container {
  background-color: #fff;
  border-radius: 1.5rem;
  overflow: hidden;
  box-shadow: 0px 0.2rem 1.3rem 0.25rem rgba(0, 0, 0, 0.05);
}
