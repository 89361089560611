
















































































































.first-recharge-modal {
  height: 100vh;
  overflow-y: scroll;
  font-size: calc((100vw / 325) * 10);
  &::-webkit-scrollbar {
    display: none;
    opacity: 0;
  }

  > .header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 9.3em;
    background-image: url('../../assets/webview/fitsr-recharge/编组2@3x.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;

    > .title {
      font-size: 2.8em;
      font-weight: bold;
    }

    > .sub-title {
      font-size: 1.3em;
    }
  }
  .bgm {
    width: 100%;
  }

  > .btn {
    // font-size: 1.7em;
    background-color: #ffd415;
    width: 25em;
    height: 4em;
    border-radius: 3em;
    line-height: 4em;
    margin: 1.5em auto;
    text-align: center;
    font-weight: bold;
    > span {
      font-size: 1.7em;
    }
  }

  > .desc {
    padding: 0 1.5em;
    > .title {
      font-size: 1.4em;
      font-weight: bold;
      margin-bottom: 1.5em;
    }

    > .list {
      font-size: 1.2em;
      color: #7d7d7d;

      > .item {
        margin-bottom: 0.5em;
      }
    }
  }

  > .title {
    margin-top: 1.45rem;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  > .section {
    margin: 0 1em;

    &.active {
      border: 1px solid #ff7b52;
      > .header {
        background-color: #ff7b52;
        > .cost {
          color: #fff;
        }
      }
    }
    > .header {
      background-color: #1a1a1a;
      color: #fff;

      font-weight: bold;
      height: 3.5em;
      line-height: 3.5em;
      text-align: center;
      > div {
        font-size: 1.2em;
        > .cost {
          display: inline-block;
          margin: 0 0.7em;
          font-size: 1.5em;
          color: #ffd415;
        }
      }
    }

    > .body {
      background-color: #f3f3f3;
      word-wrap: normal;
      white-space: nowrap;
      overflow-x: scroll;
      padding: 1.5em 0.7em;
      display: flex;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        height: 21.538vw;
        width: 21.538vw;
        background-color: #fff;
        border-radius: 0.5em;
        margin-right: 0.5em;

        &:last-child {
          margin-right: 0;
        }

        > .name {
          font-size: 0.9em;
        }
        > img {
          height: 5em;
          width: 5em;
        }
      }
    }
  }
}
