









































































































.header {
  padding: 1.6rem;
  padding-bottom: 3.3rem;
  position: relative;

  >.bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .mask {
    // position: absolute;
    // z-index: 999;
    // top: 0;
    // right: 0;
    // width: 100px; height: 100px;
    // -webkit-filter: blur(5px); -moz-filter: blur(5px); filter: blur(5px); 
    // cursor: -webkit-grab; cursor: -moz-grab; cursor: grab;
    
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    -webkit-filter: blur(10px);
    filter: blur(10px);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
  }
  >.info {
    position: relative;
    z-index: 9;
    display: flex;
    >.avatar-wrapper {
      height: 12rem;
      width: 12rem;
      border-radius: 0.2rem;
      overflow: hidden;
      margin-right: 1.5rem;

      >img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }


    >.right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 1;

      >.tags {
        color: #FFFFFF;
        font-size: 1.2rem;
        margin-bottom: 2.8rem;

        >span {
          display: inline-block;
          padding: 0 0.75rem;
          border-radius: 1rem;
          margin-right: 0.5rem;
          background-color: rgba(255, 255, 255, 0.15);
        }
      }

      >.collect-imgs {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 1.1rem;
        color: #FFFFFF;
        >img {
          position: relative;
         
          height: 2rem;
          width: 2rem;
          border-radius: 50%;
          border: 1px solid rgba(255, 255, 255, 0.8);
          object-fit: cover;

          &:nth-child(2) {
            z-index: 9;
            left: -3px;
          }

          &:nth-child(3) {
            z-index: 9;
            left: -6px;
          }
        }
      }

      >.date {
        font-size: 1.1rem;
        color: rgba(255, 255, 255, .5);
      }
    }

    .title-field {
      display: flex;
      justify-content: space-between;
      >.title {
        font-size: 1.8rem;
        color: #FFFFFF;
        font-weight: bold;
      }

      >.follow-btn {
        display: inline-block;
        font-size: 1.2rem;
        font-weight: bold;
        background-color: #FFD415;
        padding: 0.8rem 1.3rem;
        border-radius: 1.6rem;
      }
    }
  }

  >.desc {
    margin-top: 2rem;
    position: relative;
    z-index: 9;
    font-size: 1.3rem;
    color: rgba(255, 255, 255, 0.5);
  }

  >.control {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 9;
    margin-top: 2rem;

    >.box {
      color: #FFFFFF;
      font-size: 1.2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 4rem;

      &:last-child {
        margin-right: 0;
      }
      >img {
        height: 2.5rem;
        width: 2.5rem;
        margin-bottom: 1rem;
      }
    }
  }
}
