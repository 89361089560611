















































































































































































































































































































































































































































































.the-user-agreement {
  padding: 0 1.5rem;
  height: 60vh;
  overflow: scroll;
  font-size: 1.6rem;

  p {
    margin: 0;
  }
}
