

















































.the-blind-box {
  display: flex;
  justify-content: space-between;
  .box {
    position: relative;
    text-align: center;
    height: 8rem;
    width: 8rem;
    margin-right: 1rem;
    border-radius: 0.5rem;
    background-color: #353535;
    > img {
      height: 5rem;
      width: 5rem;
      object-fit: contain;
    }

    > .name {
      font-size: 1.2rem;
      color: #fff;
    }

    > .day {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 1rem;
      padding: 0 0.5rem;
      background-color: #ffd415;
      border-bottom-left-radius: 0.5rem;
    }
  }
}
