




























































































































































































/deep/ {
  .app-layout-body {
    display: flex;
  }

  #effect-swiper-list {
    height: calc(100% - 5rem);
  }
}
