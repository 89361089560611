









































































































































































































.detail-page {
  /deep/ {
    .app-layout-body {
      padding-bottom: 6.8rem;
    }
  }

  .modal-body {
    text-align: center;
    padding: 1.4rem 0;
    > .title {
      font-size: 1.4rem;
      color: #999;
      margin-bottom: 1rem;
      text-align: center;
    }

    .input {
      border: 1px solid #ddd;
      border-radius: 2px;
    }
  }
}

.control-container {
  > .control-item {
    font-size: 1.4rem;
    text-align: center;
    padding: 1.5rem;
  }

  > .cancel {
    border-top: 1px solid #ddd;
    margin-top: 1rem;
  }
}

.about-icon {
  position: relative;
  height: 2.5rem;
  width: 2.5rem;
  background-image: url('../../../assets/intimate/more.png');
  background-size: 100% 100%;

  > .action-list {
    font-size: 1.4rem;
    position: absolute;
    /* height: 8.9rem; */
    width: 10rem;
    background-color: #fff;
    border-radius: 0.5rem;
    border-top-right-radius: 0;
    right: 0;
    top: 4.3rem;
    text-align: center;
    padding: 0 1rem;
    transition: all 1s;
    /* overflow: hidden; */
    &::after {
      position: absolute;
      content: '';
      border-bottom: 0.5rem solid #fff;
      border-right: 0.6rem solid #fff;
      border-left: 0.6rem solid transparent;
      border-top: 0.5rem solid transparent;
      top: -1rem;
      right: 0;
    }

    > .item {
      padding: 1.3rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid rgba(232, 231, 231, 1);
      &:last-child {
        border: none;
      }
    }
  }
}

.section {
  background-color: #fafafa;
  border-radius: 0.5rem;
  border: 1px solid #f3f3f3;
  margin: 0 1.5rem;
  padding: 2rem 1.5rem;
  font-size: 1.4rem;
  > .title {
    margin-bottom: 1rem;
  }

  > .desc {
    color: #838383;
  }

  > .request-btn {
    display: inline-block;
    position: relative;
    margin-top: 1rem;
    &::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -1.5rem;
      width: 1rem;
      height: 1rem;
      content: '';
      background-image: url('../../../assets/intimate/更多@3x.png');
      background-size: 100% 100%;
    }
  }
}
