















































































































































































































.the-blessing-panel {
  .section-container {
    &:first-child {
      margin-bottom: 1rem;
    }
  }
  .user-list-wrapper {
    height: 35rem;
    overflow: hidden;
  }
  .user-list {
    > li {
      color: #ffe8c9;
      display: flex;
      align-items: center;
      padding: 1.5rem 0;
      border-bottom: 1px solid rgba(255, 247, 236, 0.2);
      &:last-child {
        border-bottom: none;
      }
      > img {
        height: 4.5rem;
        width: 4.5rem;
        border-radius: 50%;
        margin-right: 0.9rem;
      }

      .title {
        font-size: 1.2rem;
        margin-bottom: 0.5rem;
      }

      .subtitle {
        font-size: 1.4rem;
        font-weight: bold;
      }
    }
  }

  .current-comment-total {
    padding-top: 1rem;
    font-size: 1.6rem;
    text-align: center;
    color: #ffe8c9;
    margin-bottom: 1rem;
  }

  .reward-count {
    font-size: 1.4rem;
    text-align: center;
    color: #ffe8c9;
  }

  .reward-rule {
    padding-left: 1.5rem;
    font-size: 1.2rem;
    color: #ffe8c9;
    padding-top: 2rem;
    > li {
      margin-bottom: 0.5rem;
    }
  }

  .blessing-btn {
    position: fixed;
    height: 5rem;
    width: 5rem;
    right: 0.6rem;
    bottom: 10rem;
  }

  .modal-mask {
    position: fixed;
    z-index: 1999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);

    > .modal-content {
      position: absolute;
      top: 50%;
      left: 2.5rem;
      right: 2.5rem;
      transform: translateY(-50%);
      padding: 2.5rem 1.5rem;
      background-color: #fff;
      border-radius: 1.5rem;

      > .header {
        font-size: 1.6rem;
        font-weight: bold;
        color: #1a1a1a;
        text-align: center;
        padding-bottom: 2rem;
      }

      > .body {
        margin-bottom: 2rem;
        > textarea {
          width: 100%;
          background-color: #fafafa;
          border: none;
          font-size: 1.5rem;
          resize: none;
          height: 15rem;
          padding: 1.5rem;
          &::placeholder {
            color: #c0bdbd;
          }
        }
      }

      > .footer {
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/spring-festival/收下@3x.png');
        height: 4.5rem;
        width: 15rem;
        background-size: 100% 100%;
        color: #ffe8c9;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 4.5rem;
        text-align: center;
        margin: 0 auto;
      }

      > .close-btn {
        position: absolute;
        height: 3rem;
        width: 3rem;
        bottom: -5rem;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
