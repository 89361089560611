

















































































































































.the-display-field {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.85rem;
  height: 35.7rem;
  background-size: 100% 100%;

  > .calendar-btn {
    display: flex;
    align-items: center;
    position: absolute;
    padding: 0 1rem;
    font-size: 1.1rem;
    height: 2.8rem;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 5rem 0 0 5rem;
    top: 1rem;
    right: 0;
    font-weight: bold;
    line-height: 2.8rem;

    > i {
      height: 1.8rem;
      width: 1.8rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/img/intimate/calendar.png');
      background-size: 100%;
      margin-right: 0.5rem;
    }
  }

  p {
    margin: 0;
  }

  > .content {
    height: 25rem;
    width: 25rem;
    /* background-image: url('../../../../assets/intimate/亲密度@2x.png'); */
    background-size: 100% 100%;
    padding: 0 4.5rem;
    padding-top: 7.6rem;

    &.content_sea {
      width: 29rem;
      height: 24.5rem;
      padding-top: 12.75rem;
      padding-left: 7.3rem;
      padding-right: 5.75rem;
      .avatar-wrapper {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          height: 7rem;
          width: 7rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          background-size: 100% 100%;
          background-image: url('https://static.aipiaxi.com/image/2022/06/FivgeOOJtonyLTPV52RX2pWbhWp1.png');
        }
      }

      > .info {
        // justify-content: unset;
      }
    }

    > .info {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      > .middle {
        font-size: 1.7rem;
        font-weight: bold;
        color: #fff;
      }
      > .left,
      > .right {
        width: 6rem;
        > .avatar-wrapper {
          border-radius: 50%;
          border: 2px solid #fff;
          width: 6rem;
          height: 6rem;
          overflow: hidden;
          margin-bottom: 1rem;
          > img {
            height: 100%;
            width: 100%;
          }
        }

        > .title {
          text-align: center;
          color: #ffffff;
          font-size: 1.3rem;
          font-weight: bold;
        }
      }
    }
  }

  > .title {
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    > div {
      display: flex;
      align-items: center;
    }

    > .level {
      word-break: keep-all;
      white-space: nowrap;
    }
    .icon {
      height: 3rem;
      widows: 3rem;
    }
  }

  > .sub-title {
    display: inline-block;
    padding: 0 2rem;
    text-align: center;
    font-size: 1.3rem;
    /* background-color: #ffebf3; */
    height: 3rem;
    line-height: 3rem;
    border-radius: 1.5rem;
  }
}
