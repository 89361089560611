.body {
  height: 100vh;
  overflow-y: scroll;
  padding: 0 3.75rem;
  padding-top: 7.5rem;
  .ablum {
    position: relative;
    height: 30rem;
    width: 30rem;
    // background-color: greenyellow;
    border-radius: 1rem;
    margin-bottom: 1.8rem;
    overflow: hidden;

    > .ablumPhoto {
      height: 100%;
      width: 100%;
    }

    > .playBtn {
      position: absolute;
      height: 6rem;
      width: 6rem;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
    font-size: 1.9rem;
    font-weight: bold;
    margin-bottom: 2.1rem;

    > .text {
      flex: 1;
    }

    > .flag {
      background-color: #ffeb6f;
      height: 2.1rem;
      width: 2.1rem;
      border-radius: 0.5rem;
      font-size: 1.3rem;
      font-weight: bold;
      margin-left: 5rem;
      line-height: 2.1rem;
      text-align: center;
    }
  }

  .subtitle {
    font-size: 1.5rem;
    color: #7d7d7d;
    margin-bottom: 4.5rem;

    > .collection {
      font-size: 1.2rem;
      color: #1a1a1a;
      background-color: #fafafa;
      border: 1px solid #f3f3f3;
      display: inline-block;
      height: 2.4rem;
      width: 5rem;
      border-radius: 1.5rem;
      line-height: 2.4rem;
      text-align: center;
      margin-left: 1.2rem;
    }
  }

  > .timer {
    font-size: 1rem;
    color: #7d7d7d;
    display: flex;
    justify-content: space-between;
    margin-top: 1.4rem;
    padding-bottom: 14rem;
  }

  .custom-button {
    height: 1.1rem;
    width: 1.1rem;
    border-radius: 50%;
    background-color: #ffd415;
    border: 2px solid #fff;
  }
}
