




























































































.rank-tab {
  /deep/ {
    .the-tab-bar {
      background-color: #fcffe2;
      height: 5.4rem;
    }
  }
}
