

































































.the-record-list-item {
  position: relative;
  display: flex;
  border-radius: 0.5rem;
  border: 1px solid #e8e7e7;
  padding: 1rem;
  margin-bottom: 1rem;

  > .more-icon {
    position: absolute;
    height: 1.8rem;
    width: 1.8rem;
    top: 1.5rem;
    right: 1.5rem;
  }

  > img {
    height: 9rem;
    width: 9rem;
    border-radius: 0.3rem;
  }

  > .right {
    padding: 0.8rem 0;
    flex: 1;
    padding-left: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    p {
      margin: 0;
    }

    > .title {
      font-size: 1.6rem;
      font-weight: 500;
    }
    > .status {
      font-size: 1.3rem;
      color: #7d7d7d;
      > .reason {
        color: #ff7b52;
      }
    }
  }
}
