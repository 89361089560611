





























































































.count-wrapper {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
}
.count-field {
  font-size: 1.2rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  border-radius: 1.75rem;
  border: 1px solid #e8e7e7;
  background-color: #fff;

  > .icon {
    height: 2.1rem;
    width: 2.1rem;
    background-image: url('/venus-ssr/img/intimate/添加@2x.png');
    background-size: 100% 100%;
    margin-left: 2rem;
  }
}
