
























































































.the-identity-mark {
  display: flex;
  justify-content: space-between;
  > .item {
    flex: 1;
    text-align: center;
    > img {
      height: 5.5rem;
      width: 5.5rem;
    }

    > .title {
      color: #fff;
      font-size: 1.3rem;
    }
    > .sub-title {
      color: rgba(254, 255, 254, 0.35);
      font-size: 1.2rem;
    }
  }

  /deep/ {
    .modal-content {
      background-color: #fff;
      border-radius: 1.6rem 1.6rem 1.5rem 1.5rem;
      overflow: hidden;
      padding-bottom: 3rem;
      .modal-header {
        height: 14.2rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/vip/黑色@3x.png');
        background-size: 100% 100%;
        // border-radius: 1.6rem;
        text-align: center;
        font-size: 0;
        padding-top: 1.5rem;
        > .icon {
          height: 5.5rem;
          width: 5.5rem;
        }

        > .name {
          font-size: 1.3rem;
          color: #fff;
        }

        > .subTitle {
          font-size: 1.2rem;
          color: rgba(254, 255, 254, 0.4);
        }
      }

      .modal-body {
        text-align: center;
        padding-top: 2rem;
        background-color: #fff;
        > .title {
          color: rgba(125, 125, 125, 1);
          font-size: 1.2rem;
          padding-bottom: 1.5rem;
        }

        > img {
          height: 15.3rem;
          width: 25rem;
        }
      }
    }
  }
}
