


























































































































































.vip-page {
  /deep/ {
    .app-layout-body {
      background-color: #292929;
    }
  }

  .vip-bar {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 21.8rem;
    height: 2rem;
    margin: 2.8rem auto;
  }
  .the-body {
    padding: 0 1.5rem;
    padding-bottom: 10rem;
  }

  .footer {
    height: 10rem;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #353535;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.2);
    padding-top: 0.9rem;

    > .desc {
      font-size: 1.1rem;
      color: #fff;
      text-align: center;
      margin-bottom: 1rem;

      > span {
        color: #dfa179;
      }
    }

    .submit-btn {
      margin: 0 1.5rem;
      height: 4.5rem;
      line-height: 4.5rem;
      text-align: center;
      border-radius: 3rem;
      font-size: 1.7rem;
      color: #1a1a1a;
      font-weight: bold;
      background: linear-gradient(134deg, #fecbaa 0%, #f6ac7c 100%);
      bottom: 4rem;
    }
  }
}
