



























@import url('../../styles/article.scss');

.container {
  /deep/ {
    * {
      word-break: break-all;
    }
    .content {
      background-color: #fff;
      font-size: 1.5rem !important;
      padding: 1rem;
      line-height: 2.75;
      overflow-wrap: break-word;
      hr {
        border: none;
        height: 1px;
        background-color: #f3f6f9;
      }

      h3,
      h4,
      h5,
      h6 {
        font-size: 1.5rem !important;
      }

      h1 {
        * {
          font-size: 2.8rem !important;
        }
      }

      h2 {
        * {
          font-size: 2rem !important;
        }
      }

      p {
        font-size: 1.5rem !important;
        line-height: 28px;

        span {
          font-size: 1.5rem !important;
        }
      }

      table {
        max-width: calc(100vw - 2rem);
      }

      td {
        padding: 0;
        margin: 0;
        font-size: 1.5rem;
        span {
          font-size: 1.5rem !important;
        }
      }

      img {
        max-width: 100%;
        object-fit: contain;
      }
    }
  }
}

/* .content {
  padding: 1rem;
} */
