


































.spring-voice {
  /deep/ {
    .app-layout-body {
      background-color: #00a16d;
    }
  }

  .right-btn {
    position: absolute;
    top: 30rem;
    right: 0rem;
    display: inline-block;
    height: 2.5rem;
    width: 6.5rem;
    line-height: 2.5rem;
    color: #377ea6;
    font-size: 1.1rem;
    font-weight: bold;
    background-color: #a2e7f9;
    text-align: center;
    border-radius: 2rem 0 0rem 2rem;
  }

  .bg-img {
    width: 100%;
    height: 47rem;
  }

  .container {
    width: 100%;
    position: absolute;
    top: 41rem;
    padding-bottom: 4.5rem;
  }
}
