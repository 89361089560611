




















.sign-btn {
  position: relative;
  // z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  // bottom: 3.5rem;
  color: #ffe8c9;
  font-size: 1.9rem;
  font-weight: bold;
  text-align: center;
  height: 5.7rem;
  line-height: 5.7rem;
  width: 25rem;
  background-image: url('../../../../assets/activity/spring-festival/sign-btn.png');
  background-size: 100% 100%;

  &.disabled {
    // -webkit-filter: grayscale(100%);
    // -moz-filter: grayscale(100%);
    // -ms-filter: grayscale(100%);
    // -o-filter: grayscale(100%);
    // filter: grayscale(100%);
    // filter: gray;
    background-image: url('../../../../assets/activity/spring-festival/btn-disbaled.png');
    color: rgba(248, 186, 123, 0.5);
  }
}
