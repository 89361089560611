.result-page {
  padding-top: 7rem;
  width: 100%;
  animation: fadeIn 1s both;

  &::before {
    content: '';
    position: absolute;
    height: 6.5rem;
    width: 17.1rem;
    top: 3rem;
    left: calc(50% - 8.55rem);
    background-size: 100% 100%;
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/狗头@3x.png');
  }

  > .content {
    position: relative;
    z-index: 999;
    border: 2px solid #000;
    border-radius: 0.5rem;
    min-height: 10rem;
    margin: 0 1.5rem;
    background-color: #fff;

    > .top {
      background-color: #8d42e9;
      height: 2.4rem;
      border-bottom: 2px solid #000;
      background-size: 4.3rem 0.6rem;
      background-repeat: no-repeat;
      background-position: center;
      text-align: center;
      font-size: 1.2rem;
      color: #fff;
      line-height: 2.4rem;
    }

    > .title {
      position: relative;
      font-size: 1.7rem;
      font-weight: bold;
      text-align: center;
      margin: 2rem 0;
      &::after {
        content: '';
        position: absolute;
        height: 0.6rem;
        width: 13rem;
        bottom: 0;
        background-color: #ffea3e;
        left: calc(50% - 6.5rem);
      }
      > span {
        position: relative;
        z-index: 99;
      }
    }

    > .result-type {
      position: relative;
      height: 11.4rem;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/底框@3x.png');
      background-size: 100% 100%;

      > .title {
        font-size: 5rem;
        color: #fff;
        text-align: center;
        font-family: zihun182hao-xinchaokakuhei;
      }

      > .star-list {
        position: absolute;
        left: 16.7rem;
        bottom: 0;
        padding-bottom: 1.5rem;
        display: flex;
        > i {
          height: 1.7rem;
          width: 1.7rem;
          background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/2@3x.png');
          background-size: 100% 100%;
          margin-right: 1.1rem;

          &.active {
            background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/1@3x.png');
          }
        }
      }
    }

    > .text {
      font-size: 1.3rem;
      line-height: 2.5rem;
      padding: 3.5rem;
    }

    > .footer {
      margin: 0 2.5rem;
      padding-bottom: 2.5rem;
      display: flex;
      align-items: flex-end;
      border-top: 2px dotted #ebe5f9;
      padding-top: 1.7rem;
      > .left {
        text-align: center;
        font-size: 1.2rem;
        margin-right: 1.5rem;
        letter-spacing: 0.3rem;
        > .qrcode {
          height: 6.5rem;
          width: 6.5rem;
          margin-bottom: 0.7rem;
        }
      }

      > .right {
        text-align: center;
        flex: 1;
        > img {
          width: 5.7rem;
          height: 1.2rem;
          margin-bottom: 1.8rem;
        }

        > .btn-group {
          flex: 1;
          display: flex;
          align-items: center;
          > .btn {
            flex: 1;
            box-sizing: content-box;
            position: relative;
            z-index: 999;
            height: 4rem;
            line-height: 4rem;
            margin: 0 auto;
            text-align: center;
            border-radius: 0.7rem;
            background-color: #ffea3e;
            border: 2px solid #000;
            font-size: 1.6rem;
            font-weight: bold;
            border-bottom-width: 0.4rem;

            &:first-child {
              background-color: #fff;
            }

            // &::before {
            //   position: absolute;
            //   top: -3rem;
            //   content: '';
            //   background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/single-dog/箭头@3x.png');
            //   height: 1.2rem;
            //   width: 5.7rem;
            //   background-size: 100% 100%;
            // }

            &:first-child {
              margin-right: 1rem;
            }
          }
        }
      }
    }

    > .bottom {
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.5);
      height: 4.85rem;
      line-height: 4.85rem;
      letter-spacing: 0.5rem;
      text-align: center;
      background-color: #8d42e9;
    }
  }
}

@font-face {
  font-family: 'zihun182hao-xinchaokakuhei';
  src: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/fonts/字魂182号-新潮卡酷黑.ttf');
}

@keyframes fadeIn {
  from {
    min-height: 100vh;
    opacity: 0;
  }

  99% {
    opacity: 0.9;
  }
  100% {
    min-height: auto;
    position: relative;
    opacity: 1;
  }
}
