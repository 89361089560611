.page {
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank-palace/周星名人堂@3x.png');
  background-size: 100% 35rem;
  padding-top: 21rem;
  background-repeat: no-repeat;
  background-color: #7740fd;
  padding-bottom: 9.5rem;

  .section {
    margin: 0 8.75rem;
    margin-bottom: 4.5rem;
    .body {
      color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 21rem;
      background-size: 100% 100%;
      background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank-palace/底纹@3x.png');

      > .avatarWrapper {
        position: relative;

        height: 10.7rem;
        width: 10.7rem;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank/头像框@3x.png');
        background-size: 100% 100%;
        margin-bottom: 1rem;

        > img {
          position: absolute;
          height: 8.5rem;
          width: 8.5rem;
          border-radius: 50%;
          bottom: 0.75rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      > .nickname {
        font-size: 1.5rem;
        font-weight: bold;
      }

      > .subtitle {
        font-size: 1.2rem;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }

  > .list {
    color: #fff;
    margin: 0 3rem;
    > .header {
      font-size: 1.6rem;
      font-weight: bold;
      text-align: center;
      padding-bottom: 2.5rem;
      > span {
        position: relative;
        &::after,
        &::before {
          position: absolute;
          content: '';
          height: 0.6rem;
          width: 0.6rem;
          border-radius: 0.1rem;
          background-color: #fff;
          transform: rotate(45deg) translateY(-50%);
          top: 50%;
        }

        &::before {
          left: -1.7rem;
        }

        &::after {
          right: -1.7rem;
        }
      }
    }

    > .body {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      padding-top: 2.5rem;

      .row {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.3rem;
        margin-bottom: 2.5rem;

        > .nickname {
          width: 7.8rem;
        }

        > img {
          height: 4.5rem;
          width: 4.5rem;
          border-radius: 50%;
          margin: 0 2.5rem;
        }
      }
    }

    > .nomore {
      text-align: center;
      font-size: 1.2rem;
      color: rgba(255, 255, 255, 0.8);
      padding-top: 2.5rem;
    }
  }
}
