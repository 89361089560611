.page {
  min-height: 100vh;
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/activity/summer/背景@2x.png');
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-color: #3386f2;
  padding: 0 1.5rem;
  padding-top: 38.1rem;
}

.rightIcon {
  height: 2rem;
  width: 2rem;
}
