


























































.rank-list {
  > .list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.95rem;
    padding-top: 2rem;
    min-height: 24.3rem;
  }

  .no-more {
    font-size: 1.3rem;
    font-weight: bold;
    color: #00563c;
    text-align: center;
    margin-top: 2rem;
  }

  > .ticket-btn {
    position: fixed;
    z-index: 1999;
    bottom: 7.5rem;
    right: 0.5rem;
    height: 5.5rem;
    width: 5.5rem;
    border: 0.25rem solid #fcffe2;
    border-radius: 50%;
    background: linear-gradient(90deg, #fdca24, #fceb43);
    color: #00563c;
    font-size: 1.7rem;
    font-weight: bold;
    line-height: 5rem;
    text-align: center;
  }
}
