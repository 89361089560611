








































.the-team-list-item {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1.7rem 1.5rem;
  background-color: #fafafa;
  border-radius: 0.5rem;

  > .avatar {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    margin-right: 1.5rem;
  }

  .title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .desc {
    color: #7d7d7d;
    font-size: 1.2rem;
  }

  .more-btn {
    position: absolute;
    height: 1.8rem;
    width: 1.8rem;
    top: 2.25rem;
    right: 1.5rem;
  }
}
