





































.the-category-select-container {
  align-items: flex-start;
  /deep/ {
    .content {
      font-size: 0;
      > .tag {
        display: inline-block;
        width: 8rem;
        height: 3.3rem;
        line-height: 3.3rem;
        text-align: center;
        background-color: #fafafa;
        font-size: 1.3rem;
        border-radius: 1.75rem;
        margin-right: 0.7rem;
        margin-bottom: 1rem;

        &.active {
          background-color: #ffeb6f;
        }
      }
    }
  }
}
