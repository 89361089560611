
































































.the-body {
  > .tabs {
    display: flex;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1.4rem;
    align-items: center;
    margin-top: 3.05rem;
    margin-bottom: 1.5rem;
    padding: 0 2rem;
    > li {
      margin-right: 2.5rem;
    }
    > .active {
      font-size: 1.8rem;
      font-weight: bold;
      color: #fff;
    }
  }

  #medal-list-swiper {
    height: calc(100% - 19.75rem);
    overflow-y: scroll;
  }
}
