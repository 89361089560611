.list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 4.5rem;
  > .leftBtn,
  > .rightBtn {
    height: 2rem;
    width: 2rem;
    background-size: 100%;
  }

  > .leftBtn {
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank-palace/向左@3x.png');
  }

  > .rightBtn {
    background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/room/week-rank-palace/向右@3x.png');
  }

  .swiper-container {
    height: 7rem;
    width: 21rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    .swiper-slide {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 7rem;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 0.5rem;
      transition: 300ms;
      transform: scale(0.8);
      font-size: 1rem;
      color: #fff;

      img {
        height: 2.5rem;
        width: 2.5rem;
        font-size: 0;
      }
    }

    :global {
      .swiper-slide-active {
        transform: scale(1);
      }
    }
  }
}
