html {
  font-size: calc((100vw / 375) * 10);
}

html,
body {
  margin: 0;
  // height: 100%;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
}


* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

a {
  text-decoration: none;
  color: currentColor;

  &:focus {
    -webkit-tap-highlight-color: unset
  }
}

input{
  border: none;
  -webkit-appearance: none;
  outline: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.display-block {
  display: block;
}

.marquee-container {
  position: relative; 
  flex: 1;
  overflow: hidden;
  height: 2.5rem;

  .marquee-content {
    display: flex;
    position: absolute; 
    top: 0; 
    left: 0;
    /* 宽度 大于容器的两倍即可 */
    // width: 200%; 
    animation:5s move infinite linear;

    >.text {
      display: inline-block; 
      padding: 0 0.5rem;
      /* 宽度 等于容器的宽度 */
      width: 50%; 
      text-align: center; 
      margin-top: 5px; 
      white-space: nowrap;
    }
  }
}

@keyframes move{
  /* 原理 left值的变化 移动一个容器的宽度 */
  0%{
    transform: translateX(0);
  }
  100%{
    transform: translateX(-50%);
  }
}