









































































































































.form {
  margin-top: 5rem;
  margin-bottom: 3.5rem;
  input {
    outline: none;
    border: none;
  }

  > .phone-input {
    display: flex;
    border: 1px solid #dcdfe3;
    background-color: #fafafa;
    border-radius: 2.25rem;
    overflow: hidden;
    height: 4.5rem;
    line-height: 4.5rem;
    font-size: 1.4rem;
    margin-bottom: 2rem;

    > .prefix {
      position: relative;
      color: #585b61;
      border-right: 1px solid #d8d8d8;
      padding: 0 1.5rem;
      width: 7.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &::after {
        content: '';
        border-top: 0.6rem solid #585b61;
        border-left: 0.6rem solid transparent;
        border-right: 0.6rem solid transparent;
      }
    }

    > input {
      border: none;
      background-color: #fafafa;
      flex: 1;
      padding-left: 1.5rem;
    }
  }

  > .verify-input {
    font-size: 1.4rem;
    > .code-input {
      border: 1px solid #dcdfe3;
      height: 4.5rem;
      width: 11.6rem;
      border-radius: 2.25rem;
      padding: 0 2rem;
    }

    > .send-btn {
      display: inline-block;
      width: 11rem;
      height: 4.5rem;
      line-height: 4.5rem;
      border-radius: 2.25rem;
      text-align: center;
      background-color: rgba(255, 211, 0, 0.16);
      margin-left: 1rem;
    }
  }
}
