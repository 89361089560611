

















































































































.open-app-btn {
  position: fixed;
  z-index: 99;
  right: 1rem;
  bottom: 0.5rem;
  height: 4rem;
  line-height: 4rem;
  width: 11.5rem;
  text-align: center;
  background-color: #ffd300;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 40px 6px 40px 40px;
  overflow: hidden;
}
