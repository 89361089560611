.page {
  background-color: #f3f3f3;

  .nav-bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  /deep/ {
    .nav-bar {
      // background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/article-rank/nav.png');
      background-size: 100% 100%;
    }
  }
}

.header {
  position: relative;
  background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/article-rank/背景@3x.png');
  height: 24rem;
  background-size: 100% 100%;

  /deep/ {
    > .tab-bar {
      position: absolute;
      bottom: 0;
      left: 0;
      color: rgba(255, 255, 255, 0.4);
      font-size: 1.4rem;
      width: 100%;

      &.fixed {
        position: fixed;
        bottom: unset;
        z-index: 999;
        background-image: url('https://static.aipiaxi.com/app/web/venus-ssr/assets/webview/article-rank/nav.png');
      }

      .swiper-slide {
        width: auto;
        margin-right: 2rem;
        padding: 1.3rem 0;
        &:first-child {
          padding-left: 1.5rem;
        }

        &.active {
          font-weight: bold;
          color: #fff;
        }
      }
    }
  }
}

.bg-img {
  width: 100%;
}
